import { generateCsv, generateCsvDevices, generateCsvGroups, generateHtmlTable } from "components/utils/accessManagement";

export const handleDownloadCsv = (rows, columns, isDevice=false, isGroup=false) => {
  const csvContent = isDevice ? generateCsvDevices(rows, columns) : isGroup ? generateCsvGroups(rows, columns) : generateCsv(rows, columns);
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", "data.csv");
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const handlePrintCsv = (rows, columns, isDevice=false, isGroup=false) => {
  const csvContent = isDevice ? generateCsvDevices(rows, columns) : isGroup ? generateCsvGroups(rows, columns) : generateCsv(rows, columns);
  const tableHtml = generateHtmlTable(csvContent);
  const printWindow = window.open("", "", "height=600,width=800");
  printWindow.document.write("<html><head><title>Print CSV</title>");
  printWindow.document.write(
    "<style>body { font-family: Arial, sans-serif; } table { width: 100%; border-collapse: collapse; } th, td { border: 1px solid #ddd; padding: 8px; text-align: left; } th { background-color: #f2f2f2; }</style>"
  );
  printWindow.document.write("</head><body>");
  printWindow.document.write(tableHtml);
  printWindow.document.write("</body></html>");
  printWindow.document.close();
  printWindow.print();
};
