import React from 'react'

const LockIcon = () => {
    return (
        <svg width="19" height="23" viewBox="0 0 19 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.1306 8.06288H15.4838V5.98372C15.4838 2.6828 12.801 0 9.50012 0C6.1992 0 3.5164 2.6828 3.5164 5.98372V8.06288H2.86965C1.28873 8.06288 0 9.35157 0 10.9325V19.5031C0 21.084 1.2887 22.3727 2.86965 22.3727H16.1304C17.7113 22.3727 19 21.084 19 19.5031V10.9325C18.9952 9.3516 17.7067 8.06288 16.1306 8.06288ZM5.32274 8.06288V5.98372C5.32274 3.67939 7.19592 1.80622 9.50025 1.80622C11.8046 1.80622 13.6778 3.67939 13.6778 5.98372V8.06288H5.31784H5.32274ZM1.80628 10.9325C1.80628 10.3433 2.28535 9.86897 2.86983 9.86897H16.1305C16.7198 9.86897 17.1941 10.348 17.1941 10.9325V19.4985C17.1941 20.0877 16.7102 20.562 16.1258 20.562H2.86995C2.28069 20.562 1.8064 20.083 1.8064 19.4985L1.80628 10.9325Z" fill="#666666" />
            <path d="M8.59928 15.4979V16.8153C8.59928 17.3136 9.0017 17.716 9.49994 17.716C9.99817 17.716 10.4054 17.3136 10.4054 16.8153V15.4979C10.8317 15.2008 11.0857 14.717 11.0857 14.1996C11.0857 13.3276 10.3766 12.6187 9.50475 12.6187C8.63285 12.6187 7.92383 13.3277 7.92383 14.1996C7.92383 14.717 8.17773 15.2008 8.60413 15.4979H8.59928Z" fill="#666666" />
        </svg>

    )
}
export default LockIcon
