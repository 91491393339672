import { Button } from "@mui/material";
import DeleteIcon2 from "../../../static/svgs/Delete2";
import tables from '../../shared/Tables/table.module.scss';
import { formatFileSize, splitFileName } from "../../utils";
import dayjs from "dayjs";
import { DELETE_FILE } from "../../../redux/types/shared.types";
export const getFileColumns = (dispatch) => {
    return (
        [
            {
                field: 'orignalFileName',
                headerName: 'File Name',
                minWidth: 100,
                flex: 1,
                renderCell: (params) => {
                    return (
                        <p>{splitFileName(params.row.orignalFileName)?.[0]}</p>
                    )
                },
            },
            {
                field: 'uploadDate',
                headerName: 'Upload Date & Time',
                minWidth: 150,
                flex: 1,
                renderCell: (params) => dayjs(params?.row?.createdAt).format('DD-MM-YYYY'),

            },
            {
                field: 'fsize',
                headerName: 'File Size',
                minWidth: 100,
                flex: 1,
                renderCell: (params) => formatFileSize(params.row.size),
            },
            {
                field: 'fileType',
                headerName: 'File Type',
                minWidth: 100,
                flex: 1,
                renderCell: (params) => {
                    return (
                        <p>{splitFileName(params.row.orignalFileName)?.[1]}</p>
                    )
                },
            },
            {
                field: 'link',
                headerName: 'View File',
                minWidth: 100,
                flex: 1,
                renderCell: (params) => {
                    return (
                        <Button variant="contained"
                            onClick={() => openFile(params?.row?.link)}
                            className={tables.deleteBtn} size="small">View</Button>
                    )
                },
            },
            {
                field: 'actions',
                headerName: 'Actions',
                minWidth: 100,
                flex: 1,
                renderCell: (params) => {
                    return (
                        <Button variant="contained"
                            endIcon={<DeleteIcon2 className={tables.icon} />}
                            onClick={() => deleteFile(dispatch, params?.row?.id)}
                            className={tables.deleteBtn} size="small">Delete File
                        </Button>
                    )
                },
            },
        ]
    )
}

const openFile = (url) => {
    window.open(url, '_blank');
};

const deleteFile = (dispatch, id) => {
    const payload = {
        fileId: id
    }
    dispatch({ type: DELETE_FILE, payload })
}

