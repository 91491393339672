import React from 'react'

const RegisterSettingsIcon = () => {
    return (
        <svg width="29" height="27" viewBox="0 0 29 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.6562 14.7251C9.32083 14.7251 8.23438 15.7905 8.23438 17.1001C8.23438 18.4097 9.32083 19.4751 10.6562 19.4751C11.9917 19.4751 13.0781 18.4097 13.0781 17.1001C13.0781 15.7905 11.9917 14.7251 10.6562 14.7251ZM10.6562 18.5251C9.85509 18.5251 9.20312 17.8857 9.20312 17.1001C9.20312 16.3144 9.85509 15.6751 10.6562 15.6751C11.4574 15.6751 12.1094 16.3144 12.1094 17.1001C12.1094 17.8857 11.4574 18.5251 10.6562 18.5251Z" fill="white" />
            <path d="M14.556 15.1758L15.1837 14.1507L13.6637 12.6602L12.6185 13.2758L12.0265 13.0373L11.7311 11.875H9.58142L9.28547 13.0368L8.69356 13.2753L7.64828 12.6597L6.12831 14.1503L6.75606 15.1753L6.51291 15.7557L5.32812 16.046V18.154L6.51436 18.4447L6.75655 19.0237L6.1288 20.0497L7.42305 21.3189L8.76573 20.9556L9.28595 21.1636L9.58142 22.325H11.7311L12.027 21.1631L12.5472 20.9551L13.8899 21.3185L15.1842 20.0493L14.5564 19.0233L14.8001 18.4438L15.9844 18.154V16.046L14.7996 15.7557L14.556 15.1758ZM10.6562 20.425C8.78656 20.425 7.26562 18.9335 7.26562 17.1C7.26562 15.2665 8.78656 13.775 10.6562 13.775C12.5259 13.775 14.0469 15.2665 14.0469 17.1C14.0469 18.9335 12.5259 20.425 10.6562 20.425Z" fill="white" />
            <path d="M10.6562 9.9751C6.64998 9.9751 3.39062 13.1714 3.39062 17.1001C3.39062 21.0288 6.64998 24.2251 10.6562 24.2251C14.6625 24.2251 17.9219 21.0288 17.9219 17.1001C17.9219 13.1714 14.6625 9.9751 10.6562 9.9751ZM16.4038 20.1966L14.1757 22.3821L12.8093 22.0121L12.4877 23.2751H8.82483L8.5032 22.0116L7.13678 22.3816L4.90866 20.1961L5.52914 19.1825L4.35938 18.8961V15.3041L5.52963 15.0177L4.90914 14.004L7.49909 11.4642L8.53275 12.0727L8.82483 10.9251H12.4877L12.7798 12.0727L13.8134 11.4642L16.4034 14.004L15.7829 15.0177L16.9531 15.3041V18.8961L15.7829 19.1825L16.4038 20.1966Z" fill="white" />
            <path d="M10.6562 17.575C10.9238 17.575 11.1406 17.3623 11.1406 17.1C11.1406 16.8377 10.9238 16.625 10.6562 16.625C10.3887 16.625 10.1719 16.8377 10.1719 17.1C10.1719 17.3623 10.3887 17.575 10.6562 17.575Z" fill="white" />
            <path d="M24.7031 0.671631V3.79998H27.8932L24.7031 0.671631Z" fill="white" />
            <path d="M0 25.175V26.125H6.35209C5.7723 25.8581 5.22253 25.5403 4.70861 25.175H0Z" fill="white" />
            <path d="M26.1562 25.175H10.6562C6.11572 25.175 2.42188 21.5526 2.42188 17.1C2.42188 12.6473 6.11572 9.02495 10.6562 9.02495C15.1968 9.02495 18.8906 12.6473 18.8906 17.1C18.8906 20.1822 17.1188 22.8646 14.523 24.225H16.2823C18.5564 22.4979 19.8594 19.911 19.8594 17.1C19.8594 12.1234 15.731 8.07495 10.6562 8.07495C5.58145 8.07495 1.45312 12.1234 1.45312 17.1C1.45312 22.0765 5.58145 26.125 10.6562 26.125H26.1562V26.5615L27.3183 25.65L26.1562 24.7384V25.175Z" fill="white" />
            <path d="M15.9844 1.8999V6.6499H26.6406V4.7499H23.7344V1.8999H15.9844Z" fill="white" />
            <path d="M27.6094 4.75V7.6H15.0156V0.95H23.7344V0H14.0469V7.70688C17.9911 9.08058 20.8281 12.7671 20.8281 17.1C20.8281 18.0823 20.6843 19.0394 20.4087 19.95H28.5781V4.75H27.6094ZM21.7969 16.15H25.6719V17.1H21.7969V16.15ZM27.6094 19H21.7969V18.05H27.6094V19ZM27.6094 17.1H26.6406V16.15H27.6094V17.1ZM27.6094 15.2H21.7969V14.25H27.6094V15.2ZM27.6094 13.3H21.7969V12.35H27.6094V13.3ZM27.6094 11.4H21.7969V10.45H27.6094V11.4ZM27.6094 9.5H21.7969V8.55H27.6094V9.5Z" fill="white" />
        </svg>

    )
}
export default RegisterSettingsIcon
