export const inputTextFieldStyles = {
    borderRadius: '50px',
    backgroundColor: '#f0f0f0',
    padding: '0 20px',
    input: {
        '::-ms-reveal': {
            display: 'none',
        },
    },
}

export const inputSelectFieldStyles = {
    borderRadius: '20px',
    backgroundColor: '#f0f0f0',
    padding: '0 20px'
}
