import React from 'react'

const DeviceActiveIcon = ({ fill = "#FFF", width = "22", height = "22" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 16.7738C0.210198 18.3293 1.55547 19.5484 3.15298 19.5484H22.3651C23.9626 19.5484 25.3079 18.3293 25.5181 16.7738H0ZM14.2935 18.1611H11.2666C11.0144 18.1611 10.8462 17.9509 10.8462 17.7407C10.8462 17.5305 11.0144 17.3203 11.2666 17.3203H14.2935C14.5457 17.3203 14.7139 17.5305 14.7139 17.7407C14.7139 17.9509 14.5457 18.1611 14.2935 18.1611ZM24.7193 1.63955C24.7193 0.756714 24.0047 0 23.0798 0H2.48034C1.59751 0 0.840793 0.714674 0.840793 1.63955V15.933H24.7193V1.63955ZM4.16193 8.78629H3.11094C2.8587 8.78629 2.69054 8.61813 2.69054 8.36589C2.69054 8.15569 2.8587 7.9455 3.11094 7.9455H4.16193C4.41416 7.9455 4.58232 8.15569 4.58232 8.36589C4.58232 8.61813 4.37213 8.78629 4.16193 8.78629ZM20.137 8.78629H16.7318C16.5216 10.7622 14.84 12.3176 12.7801 12.3176C10.7201 12.3176 9.08057 10.7622 8.87037 8.78629H5.59128C5.33904 8.78629 5.17088 8.61813 5.17088 8.36589C5.17088 8.15569 5.33904 7.9455 5.59128 7.9455H8.87037C9.08057 5.96963 10.7622 4.41417 12.7801 4.41417C14.798 4.41417 16.5216 5.96963 16.7318 7.9455H20.137C20.3892 7.9455 20.5574 8.15569 20.5574 8.36589C20.5574 8.61813 20.3472 8.78629 20.137 8.78629ZM22.6173 8.78629H21.5663C21.3562 8.78629 21.146 8.61813 21.146 8.36589C21.146 8.15569 21.3562 7.9455 21.5663 7.9455H22.6173C22.8696 7.9455 23.0377 8.15569 23.0377 8.36589C23.0377 8.61813 22.8275 8.78629 22.6173 8.78629Z" fill={fill} />
            <path d="M12.7801 5.25497C11.0565 5.25497 9.66919 6.64228 9.66919 8.3659C9.66919 10.0895 11.0565 11.4768 12.7801 11.4768C14.5038 11.4768 15.8911 10.0895 15.8911 8.3659C15.8911 6.68432 14.5038 5.25497 12.7801 5.25497ZM14.4617 9.50097C14.6299 9.66913 14.6299 9.92137 14.4617 10.0895C14.2936 10.2577 14.0413 10.2577 13.8732 10.0895L12.7801 8.9965L11.6871 10.0895C11.5189 10.2577 11.2667 10.2577 11.0985 10.0895C10.9304 9.92137 10.9304 9.66913 11.0985 9.50097L12.1916 8.40794L11.0985 7.27287C10.9304 7.10471 10.9304 6.85247 11.0985 6.68432C11.2667 6.51616 11.5189 6.51616 11.6871 6.68432L12.7801 7.77735L13.8732 6.68432C14.0413 6.51616 14.2936 6.51616 14.4617 6.68432C14.6299 6.85247 14.6299 7.10471 14.4617 7.27287L13.3687 8.3659L14.4617 9.50097Z" fill="white" />
        </svg>
    )
}

export default DeviceActiveIcon
