import { Add } from "@mui/icons-material";
import {
  Box,
  Checkbox
} from "@mui/material";
import AddDeviceDialogue from "components/modules/Device/AddDeviceDialogue";
import { prepareWindowsCommand } from "components/modules/Device/helper";
import MyTableToolbar from "components/shared/Tables/MyTable/MyTableToolbar";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  DELETE_NETWORK,
  GET_NETWORK_DEVICE_TOKEN
} from "../../../../../redux/types/networkDevice.types";
import MapAccordion from "./MapAccordion";
import styles from "./MapSummary.module.scss";
import { mapHeaderLabels, networkCSVColums } from "./helper";
import { handleDownloadCsv, handlePrintCsv } from "components/utils/handlers";

const MapSummaryTable = ({ deviceNetworks, isSingle = false }) => {
  const [selectedRows, setSelectedRows] = useState([]);

  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelectedRows = deviceNetworks?.map((device) => device._id);
      setSelectedRows(newSelectedRows);
    } else {
      setSelectedRows([]);
    }
    setSelectAll(event.target.checked);
  };

  const handleRowSelect = (event, _id) => {
    event.stopPropagation();
    const selectedIndex = selectedRows.indexOf(_id);
    let newSelectedRows = [];

    if (selectedIndex === -1) {
      newSelectedRows = newSelectedRows.concat(selectedRows, _id);
    } else if (selectedIndex === 0) {
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedRows = newSelectedRows.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }
    setSelectedRows(newSelectedRows);
  };

  const [curlCommand, setCurlCommand] = useState("");
  const [isDeviceOpen, setIsDeviceOpen] = useState(false);
  const [selectedDeviceToken, setSelectedDeviceToken] = useState("");
  const [copied, setCopied] = useState(false);
  const [selectedOs, setSelectedOs] = useState("windows");
  const [selectedVersion, setSelectedVersion] = useState("windows11");



  const handleInstallDevice = (token) => {
    setIsDeviceOpen(!isDeviceOpen);
    setSelectedDeviceToken(token);
    if (!isDeviceOpen) {
      setCurlCommand(prepareWindowsCommand(token));
    } else {
      setCurlCommand("");
    }
  };
  const dispatch = useDispatch();

  const handleAddDeviceNetwork = () => {
    const payload = {
      orgId: process.env.REACT_APP_ORG_ID,
    };
    dispatch({ type: GET_NETWORK_DEVICE_TOKEN, payload });
  };

  const handleDeleteDeviceNetwork = async () => {
    try {
      const payload = {
        networkIds: selectedRows
      }
      dispatch({ type: DELETE_NETWORK, payload });
      setSelectedRows([]);
      setSelectAll(false);
    } catch (error) {
      console.error("Failed to delete device networks: ", error);
    }
  };

  const handleSearch = () => {

  }

  return (
    <div className={styles.container}>
      <div className={styles.staticHead}>
        <MyTableToolbar
          placeHolder={"Search For Apps"}
          btn1Txt={false}
          btn2Txt={isSingle ? "" : "Discover Devices"}
          btn2Icon={Add}
          onSearch={handleSearch}
          handleBtn2Click={handleAddDeviceNetwork}
          handleDeleteClick={handleDeleteDeviceNetwork}
          onDownload={() => handleDownloadCsv(deviceNetworks, networkCSVColums)}
          onPrint={() => handlePrintCsv(deviceNetworks, networkCSVColums)}

          isUninstall={selectedRows?.length}
        />

        <div className={styles.tableHeader}>
          <Checkbox
            size="small"
            checked={selectAll}
            onChange={handleSelectAll}
          />
          {mapHeaderLabels.map((item, index) => (
            <div key={index} style={item?.style} className={styles.headerItem}>
              {item?.label}
            </div>
          ))}
        </div>
      </div>
      <Box className={styles.scrollBody}>
        <MapAccordion
          handleInstallDevice={handleInstallDevice}
          selectedRows={selectedRows}
          handleRowSelect={handleRowSelect}
          deviceNetworks={deviceNetworks}
          isSingle={isSingle}
        />
      </Box>
      <AddDeviceDialogue
        curlCommand={curlCommand}
        setCurlCommand={setCurlCommand}
        handleDeviceDialog={handleInstallDevice}
        isDeviceOpen={isDeviceOpen}
        copied={copied}
        setCopied={setCopied}
        selectedOs={selectedOs}
        setSelectedOs={setSelectedOs}
        selectedVersion={selectedVersion}
        setSelectedVersion={setSelectedVersion}
        selectedDeviceToken={selectedDeviceToken}
      />
    </div>
  );
};

export default MapSummaryTable;
