import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
    consoleResponse: '',
    consoleType: 'single'
}

const name = 'console'
const parentSelector = state => state?.[name]

const consoleSlice = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        resetConsole: () => {
            return initialState;
        },
        setConsoleResponse: (state, action) => {
            return {
                ...state,
                consoleResponse: action.payload,
            };
        },
        setConsoleType: (state, action) => {
            return {
                ...state,
                consoleType: action.payload,
            };
        },
    },
});


const getConsoleResponse = createSelector(parentSelector, state => {
    return state?.consoleResponse
})

const getConsoleType = createSelector(parentSelector, state => {
    return state?.consoleType
})


const actions = {
    ...consoleSlice.actions
}

const selectors = {
    getConsoleResponse,
    getConsoleType
}

export const consoleActions = { actions, selectors }


export default consoleSlice;