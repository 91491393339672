import React from "react";
import { Box, Grid } from "@mui/material";
import styles from "./NetworkStreamlined.module.scss";
import MapComponent from "./MapComponent/MapComponent";
import HardwareHealthOverview from "./HardwareHealthOverview/HardwareHealthOverview";
import LiveDevicesMap from "../NetworkKeyFeatures/LiveDevicesMap/LiveDevicesMap";
import CustomTitle from "components/shared/CustomTitle";
import NetworkDeviceDetails from "./NetworkDeviceDetails/NetworkDeviceDetails";

const NetworkStreamlined = () => {
  return (
    <Box>
      <Box style={{marginBottom: "20px"}}>
        <CustomTitle title={"Network Streamlined Monitoring"} isCount={false} />
      </Box>
      <Box className={styles.container}>
        <Grid container spacing={2} className={styles.section}>
          <Grid item xs={12} md={4} className={styles.sectionItem}>
            <MapComponent />
          </Grid>
          <Grid item xs={12} md={4} className={styles.sectionItem}>
            <HardwareHealthOverview />
          </Grid>
          <Grid item xs={12} md={4} className={styles.sectionItem}>
            <LiveDevicesMap />
          </Grid>
        </Grid>
        <Grid container xs={12} md={12} sm={12}>
          <CustomTitle
            title={"List Network Device Details"}
            isCount={true}
            count={2}
          />
          <NetworkDeviceDetails/>
        </Grid>
      </Box>
    </Box>
  );
};

export default NetworkStreamlined;
