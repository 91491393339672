import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
    system: {},
}

const name = 'system'
const parentSelector = state => state?.[name]

const systemSlice = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        resetSystem: () => {
            return  initialState;
        },
        setSystem: (state, action) => {
            return {
                ...state,
                system: action.payload,
            };
        },
    },
});


const getSystem = createSelector(parentSelector, state => {
    return state?.system  
})

const actions = {
    ...systemSlice.actions
}

const selectors = {
    getSystem
}

export const systemActions = { actions, selectors }


export default systemSlice;