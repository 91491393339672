import theme from 'components/utils/theme';
import React from 'react';
import Chart from 'react-apexcharts';

const ThreatDetectionChart = () => {
    const options = {
        chart: {
            type: 'area',
            toolbar: {
                show: false,
            },
        },
        colors: [theme.palette.error.main],
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'straight',
            width: 2,
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0,
                stops: [0, 100],
            },
        },
        xaxis: {
            type: 'datetime',
            categories: [
                '2023-08-05T00:00:00.000Z',
                '2023-08-06T00:00:00.000Z',
                '2023-08-07T00:00:00.000Z',
                '2023-08-08T00:00:00.000Z',
                '2023-08-09T00:00:00.000Z',
                '2023-08-10T00:00:00.000Z',
                '2023-08-11T00:00:00.000Z',
                '2023-08-12T00:00:00.000Z',
                '2023-08-13T00:00:00.000Z',
            ],
        },
        yaxis: {
            min: 0,
            max: 800,
            labels: {
                formatter: (value) => Math.round(value),
            },
        },
        grid: {
            borderColor: theme.palette.gray.light,
            strokeDashArray: 4,
            xaxis: {
                lines: {
                    show: true, // Show vertical lines
                },
            },
            yaxis: {
                lines: {
                    show: true, // Show horizontal lines
                },
            },
        },
        tooltip: {
            x: {
                format: 'dd MMM',
            },
        },
    };

    const series = [
        {
            name: 'No of Threats',
            data: [700, 150, 200, 250, 600, 400, 300, 10, 500],
        },
    ];

    return (
        <div>
            <Chart options={options} series={series} type="area" height={375} />
        </div>
    );
};

export default ThreatDetectionChart;
