import React from 'react'

const UsersIcon = ({ fill = "white", width = "19", height = "19" }) => {
    return (
        <svg width="30" height="18" viewBox="0 0 30 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.937 16.1053L23.8739 15.9158C22.1686 12.6632 18.7581 10.4526 14.8423 10.4526C10.9265 10.4526 7.51599 12.6632 5.81072 15.8842L5.74757 16.0737C5.71599 16.1368 5.71599 16.2316 5.71599 16.3263V17.1158C5.71599 17.5895 6.12651 18 6.6002 18H14.8423H23.116C23.5897 18 24.0002 17.5895 24.0002 17.1158V16.3263C24.0002 16.2632 23.9686 16.1684 23.937 16.1053Z" fill={fill} />
            <path d="M5.21053 15.1895L5.24211 15.0948C6.22105 13.2632 7.61053 11.779 9.25263 10.7053C8.36842 10.2948 7.35789 10.0737 6.31579 10.0737C3.63158 10.0737 1.26316 11.6211 0.0947368 13.8632L0.0315789 13.9895C0 14.0527 0 14.0843 0 14.1474V14.6843C0 15.0316 0.284211 15.2843 0.6 15.2843H5.14737L5.21053 15.1895Z" fill={fill} />
            <path d="M6.31598 8.14761C7.93795 8.14761 9.25282 6.83274 9.25282 5.21077C9.25282 3.58879 7.93795 2.27393 6.31598 2.27393C4.694 2.27393 3.37914 3.58879 3.37914 5.21077C3.37914 6.83274 4.694 8.14761 6.31598 8.14761Z" fill={fill} />
            <path d="M24.4736 15.1897L24.442 15.1265C23.4631 13.2949 22.0736 11.8107 20.4315 10.737C21.3157 10.3265 22.3262 10.1055 23.3683 10.1055C26.0841 10.1055 28.4209 11.6213 29.6209 13.8634L29.6841 13.9897C29.7157 14.0528 29.7157 14.116 29.7157 14.1476V14.6844C29.7157 15.0318 29.4315 15.2844 29.1157 15.2844H24.5367L24.4736 15.1897Z" fill={fill} />
            <path d="M23.3683 8.14761C24.9903 8.14761 26.3052 6.83274 26.3052 5.21077C26.3052 3.58879 24.9903 2.27393 23.3683 2.27393C21.7463 2.27393 20.4315 3.58879 20.4315 5.21077C20.4315 6.83274 21.7463 8.14761 23.3683 8.14761Z" fill={fill} />
            <path d="M14.8101 9.09474C17.3215 9.09474 19.3574 7.05881 19.3574 4.54737C19.3574 2.03593 17.3215 0 14.8101 0C12.2986 0 10.2627 2.03593 10.2627 4.54737C10.2627 7.05881 12.2986 9.09474 14.8101 9.09474Z" fill={fill} />
        </svg>

    )
}
export default UsersIcon
