import React from 'react'
import { Card } from '@mui/material';
import cards from './cards.module.scss';

const MyCard = ({ children, padding = 0, height = '326px' }) => {
  return (
    <Card className={cards.myCard} variant="outlined" sx={{ padding: padding, height: height }}>
      {children}
    </Card>
  )
}

export default MyCard
