import React from 'react'

const ActiveIcon = ({ fill = "#FFF", width = "22", height = "22" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.5301 0C5.16219 0 0 5.16219 0 11.5301C0 17.898 5.16219 23.0602 11.5301 23.0602C17.898 23.0602 23.0602 17.898 23.0602 11.5301C23.0602 5.16219 17.898 0 11.5301 0ZM19.4711 7.07516L11.8774 14.6688C11.579 14.9672 11.0751 14.9506 10.797 14.6333L7.5883 10.9726L8.70194 9.99642L11.3894 13.0624L18.4239 6.02794L19.4711 7.07516ZM11.5301 19.5695C7.09723 19.5695 3.49077 15.963 3.49077 11.5301C3.49077 7.09723 7.09723 3.49077 11.5301 3.49077C13.3832 3.49077 15.1929 4.13902 16.6259 5.31608L15.6859 6.46046C14.5171 5.50041 13.0412 4.9717 11.5301 4.9717C7.91375 4.9717 4.97165 7.9138 4.97165 11.5301C4.97165 15.1464 7.9138 18.0885 11.5301 18.0885C15.1464 18.0885 18.0885 15.1464 18.0885 11.5301C18.0885 11.0163 18.0287 10.5046 17.9106 10.0092L19.3512 9.66589C19.496 10.2736 19.5694 10.9008 19.5694 11.5301C19.5695 15.963 15.963 19.5695 11.5301 19.5695Z" fill={fill} />
        </svg>
    )
}

export default ActiveIcon
