import { createSlice } from "@reduxjs/toolkit";
import toast from 'react-hot-toast';

const initialState = {
  policies: [],
  status: "idle",
  error: null,
};

const policySlice = createSlice({
  name: "policy",
  initialState,
  reducers: {
    fetchPoliciesRequest: (state) => {
      state.status = "loading";
    },
    fetchPoliciesSuccess: (state, action) => {
      state.status = "succeeded";
      state.policies = action.payload;
    },
    fetchPoliciesFailure: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    createPolicyRequest: (state) => {
      state.status = "loading";
    },
    createPolicySuccess: (state, action) => {
      state.status = "succeeded";
      toast.success('Policy Created')
      state.policies.push(action.payload);
    },
    createPolicyFailure: (state, action) => {
      state.status = "failed";
      toast.error("Something went wrong")
      state.error = action.payload;
    },
    updatePolicyRequest: (state) => {
      state.status = "loading";
    },
    updatePolicySuccess: (state, action) => {
      state.status = "succeeded";
      toast.success('Policy Updated')
      const index = state.policies.findIndex(
        (policy) => policy._id === action.payload._id
      );
      if (index !== -1) {
        state.policies[index] = action.payload;
      }
    },
    updatePolicyFailure: (state, action) => {
      state.status = "failed";
      toast.error("Something went wrong")
      state.error = action.payload;
    },
    deletePolicyRequest: (state) => {
      state.status = "loading";
    },
    deletePolicySuccess: (state, action) => {
      state.status = "succeeded";
      toast.success('Policy Deleted')
      state.policies = state.policies.filter(
        (policy) => policy._id !== action.payload
      );
    },
    deletePolicyFailure: (state, action) => {
      state.status = "failed";
      toast.error("Something went wrong")
      state.error = action.payload;
    },
  },
});

export const policyActions = policySlice.actions;

export default policySlice;
