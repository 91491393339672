import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
    networks: [],
    multiNetworks: []
}

const name = 'networks'
const parentSelector = state => state?.[name]

const networksSlice = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        resetNetworks: () => {
            return initialState;
        },
        setNetworks: (state, action) => {
            return {
                ...state,
                networks: action.payload,
            };
        },
        setMultiNetworks: (state, action) => {
            return {
                ...state,
                multiNetworks: action.payload,
            };
        },
    },
});


const getNetworks = createSelector(parentSelector, state => {
    return state?.networks
})

const getMultiNetworks = createSelector(parentSelector, state => {
    return state?.multiNetworks
})
const actions = {
    ...networksSlice.actions
}

const selectors = {
    getNetworks,
    getMultiNetworks
}

export const networksActions = { actions, selectors }


export default networksSlice;