import React from 'react'

const PackagesIcon = ({ fill = "white", width = "19", height = "19" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.65027 11.959V23.571C3.65027 24.4 4.32476 25.0745 5.15377 25.0745H7.51598V19.777C7.51598 19.4255 7.8008 19.1403 8.15269 19.1403C8.50459 19.1403 8.78941 19.4255 8.78941 19.777V25.0745H9.90026V21.8769C9.90026 21.5254 10.1851 21.2402 10.537 21.2402C10.8889 21.2402 11.1737 21.5254 11.1737 21.8769V25.0745H21.8463C22.6753 25.0745 23.3502 24.4 23.3502 23.571V11.959H3.65027Z" fill="white" />
            <path d="M26.7036 9.41188L23.054 7.09933C23.0421 7.09169 23.0285 7.08702 23.0162 7.08065C22.9941 7.06877 22.9721 7.05603 22.9487 7.04669C22.9305 7.03948 22.9114 7.03481 22.8927 7.02929C22.8715 7.02292 22.8511 7.01613 22.8294 7.01189C22.8069 7.00764 22.7844 7.00679 22.7619 7.00509C22.7454 7.00382 22.7301 7 22.7135 7H4.28701C4.27215 7 4.25857 7.0034 4.24371 7.00424C4.21909 7.00594 4.19447 7.00722 4.17028 7.01189C4.14948 7.01571 4.12995 7.02207 4.10958 7.02844C4.09005 7.03396 4.07053 7.03863 4.051 7.04627C4.02723 7.05561 4.00558 7.06792 3.98308 7.08023C3.97077 7.08702 3.95762 7.09126 3.94573 7.0989L0.296079 9.41145C-0.00105469 9.5995 -0.0893459 9.99299 0.0991218 10.2901C0.220522 10.4816 0.426393 10.586 0.637783 10.586C0.754089 10.586 0.872094 10.5541 0.977789 10.4871L3.64987 8.79384V10.6849H23.3498V8.79384L26.0219 10.4871C26.1276 10.5541 26.2452 10.586 26.3623 10.586C26.5729 10.586 26.7792 10.4811 26.9006 10.2901C27.0895 9.99341 27.0012 9.59992 26.7036 9.41188Z" fill="white" />
            <line x1="19.4" y1="2.3" x2="16.4" y2="6.3" stroke="white" />
            <line y1="-0.5" x2="5" y2="-0.5" transform="matrix(0.6 0.8 0.8 -0.6 8 2)" stroke="white" />
            <line y1="-0.5" x2="5" y2="-0.5" transform="matrix(-4.37114e-08 1 1 4.37114e-08 14 0)" stroke="white" />
        </svg>

    )
}
export default PackagesIcon
