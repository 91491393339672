import React from 'react'

const SearchIcon = ({ fill = "black", width = "16", height = "16" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.8364 18.0285L14.2176 13.4131C15.4554 11.9901 16.2074 10.1319 16.2074 8.10024C16.2074 3.62989 12.5704 0 8.10473 0C3.63302 0 0 3.63594 0 8.10024C0 12.5645 3.63705 16.2005 8.10271 16.2005C10.1329 16.2005 11.9918 15.4507 13.4172 14.2112L18.036 18.8307C18.1449 18.9395 18.2921 19 18.4373 19C18.5804 19 18.7276 18.9456 18.8385 18.8307C19.0542 18.611 19.0542 18.2482 18.8364 18.0285ZM8.09868 15.0678C4.26002 15.0678 1.13305 11.9418 1.13305 8.10024C1.13305 4.25873 4.26002 1.13674 8.09868 1.13674C11.9414 1.13674 15.0643 4.26276 15.0643 8.10024C15.0643 11.9377 11.9414 15.0678 8.09868 15.0678Z" fill={fill} />
        </svg>

    )
}
export default SearchIcon
