import React, { useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Add, Search } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { useForm, Controller } from "react-hook-form";
import styles from "./Users.module.scss"; // Import the styles using CSS modules
import { customTableStyles } from "../../../shared/Tables/helper";
import DownloadIcon from "static/svgs/Download";
import PrintIcon from "static/svgs/Print";
import BarsIcon from "static/svgs/Bars";
import toast from "react-hot-toast";
import { handleDownloadCsv, handlePrintCsv } from "components/utils/handlers";
import { useDispatch } from "react-redux";
import { INVITE_USER } from "../../../../redux/types/policy.types";
import { ADD_USER_TO_GROUP } from "../../../../redux/types/group.types";

const columns = [
  { field: "username", headerName: "User Name", flex: 1 },
  { field: "email", headerName: "Email", flex: 1 },
  { field: "role", headerName: "Role", flex: 1 },
  { field: "gender", headerName: "Gender", flex: 1 },
  { field: "country", headerName: "Country", flex: 1 },
];

const Users = ({ user, groups, refreshGroups, currentUser }) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();
  const [users, setUsers] = useState(user || []);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [open, setOpen] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);

  const handleOpen = () => {
    if (selectedUsers.length === 0) {
      toast.error("Please choose user first");
      return;
    }
    setOpen(true);
  };

  const handleInviteOpen = () => {
    setInviteDialogOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedGroup("");
  };

  const handleInviteClose = () => {
    setInviteDialogOpen(false);
    reset();
  };

  const handleAddUserToGroup = async () => {
    dispatch({ type: ADD_USER_TO_GROUP, payload: {groupId: selectedGroup, userIds: selectedUsers}})
    handleClose();
    refreshGroups();
  };

  const handleInviteUser = async (data) => {
    dispatch({ type: INVITE_USER, payload: { email: data.email } });
    handleInviteClose();
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredUsers = users?.filter(
    (user) =>
      user?.username?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
      user?.email?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
      user?.role?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
      user?.gender?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
      user?.country?.toLowerCase()?.includes(searchQuery?.toLowerCase())
  );

  return (
    <Box className={styles.container}>
      <Box className={styles.headerContainer}>
        <Box className={styles.textContainer}>
          <Typography variant="h6" className={styles.header}>
            Users <span className={styles.badge}></span>
          </Typography>
          <Box className={styles.subheaderContainer}>
            <Typography className={styles.subheader}>
              A Policy is an Object in Bdata that Defines Permissions.
            </Typography>
          </Box>
        </Box>

        <Box className={styles.controls}>
          <Box className={styles.iconButtons}>
            <IconButton className={styles.iconBtn} onClick={handleDownloadCsv}>
              <DownloadIcon />
            </IconButton>
            <IconButton className={styles.iconBtn} onClick={handlePrintCsv}>
              <PrintIcon />
            </IconButton>
            <IconButton className={styles.iconBtn}>
              <BarsIcon />
            </IconButton>
          </Box>
          <Box className={styles.actionButtons}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              className={styles.createButton}
              onClick={handleOpen}
            >
              Add User to Group
              <Add className={styles.icon} />
            </Button>
            {currentUser?.role === "admin" ? (
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={styles.createButton}
                onClick={handleInviteOpen}
              >
                Invite User
                <Add className={styles.icon} />
              </Button>
            ) : null}
          </Box>
        </Box>
      </Box>
      <Box className={styles.tableControls}>
        <TextField
          variant="outlined"
          placeholder="Search for users"
          size="small"
          value={searchQuery}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <IconButton size="small">
                <Search />
              </IconButton>
            ),
          }}
          className={styles.search}
        />
      </Box>
      <Box sx={{ width: "100%", height: "450px" }}>
        <DataGrid
          rows={filteredUsers}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 30]}
          pagination
          sx={customTableStyles}
          checkboxSelection
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setSelectedUsers(newRowSelectionModel);
          }}
          rowSelectionModel={selectedUsers}
          disableColumnMenu
          getRowId={(row) => row?.userId}
        />
      </Box>

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Select Group</DialogTitle>
        <DialogContent>
          <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel>Group</InputLabel>
            <Select
              value={selectedGroup}
              onChange={(e) => setSelectedGroup(e.target.value)}
              label="Group"
            >
              {Object.keys(groups).map((groupType) =>
                groups[groupType].map((group) => (
                  <MenuItem key={group._id} value={group._id}>
                    {group.name}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleAddUserToGroup}
            color="primary"
            disabled={!selectedGroup}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={inviteDialogOpen}
        onClose={handleInviteClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Invite User</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(handleInviteUser)}>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{
                required: "Email is required",
                pattern: {
                  value: /^\S+@\S+$/i,
                  message: "Invalid email address",
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  variant="outlined"
                  label="Email"
                  margin="normal"
                  error={!!errors.email}
                  helperText={errors.email ? errors.email.message : ""}
                />
              )}
            />
            <DialogActions>
              <Button onClick={handleInviteClose} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Invite
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Users;
