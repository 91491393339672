import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { executeCommand, setupConsoleSocket, unSubscribeSocketTopics } from '../../../redux/saga/socket';
import { authActions } from '../../../redux/slice/auth.slice';
import { consoleActions } from '../../../redux/slice/console.slice';
import { devicesActions } from '../../../redux/slice/devices.slice';
import { commandTypes } from '../../utils';
import { DeviceStatus, TOPICS } from '../../utils/enums';
import styles from './console.module.scss';
import { networkDeviceActions } from '../../../redux/slice/networkDevice.slice';
import { findDeviceById } from './helper';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const MyConsole = () => {
    const query = useQuery();
    const id = query.get('id');
    const dispatch = useDispatch();
    const [input, setInput] = useState('');
    const [history, setHistory] = useState([]);
    const [currentDirectory, setCurrentDirectory] = useState('')
    const endOfConsoleRef = useRef(null);

    const devices = useSelector(devicesActions.selectors.getDevices);
    const deviceNetworks = useSelector(networkDeviceActions.selectors.getNetworkDevices);
    const consoleResponse = useSelector(consoleActions.selectors.getConsoleResponse)
    const selectedDevice = useSelector(devicesActions.selectors.getSelectedDevice)
    const currentUser = useSelector(authActions.selectors.getCurrentUser)
    const selectedDevicesAll = useSelector(devicesActions.selectors.getSelectedDevicesAll);

    useEffect(() => {
        if (id === 'multi') {
            dispatch(consoleActions.actions.setConsoleType('multi'))
        }
        else {
            dispatch(consoleActions.actions.setConsoleType('single'))
        }
    }, [dispatch, id])

    useEffect(() => {
        if (currentUser && selectedDevice) {
            if (id === 'multi') {
                setCurrentDirectory(`${currentUser?.username}@${id}`)
            } else {
                setCurrentDirectory(`${currentUser?.username}@${selectedDevice?.deviceId}`)
            }
        }
    }, [currentUser, selectedDevice, id])
    const handleInputChange = (e) => {
        setInput(e.target.value);
    };

    useEffect(() => {
        if (Object.keys(consoleResponse)?.length) {
            const response = {
                ...consoleResponse,
                type: 'result'
            }
            setHistory(prevHistory => {
                return [...prevHistory, response]
            })
        }
    }, [consoleResponse]);

    const closeTerminal = () => {
        setHistory([]);
        setInput('');
        window.close();
    };

    const handleKeyPress = (e) => {
        if (e.ctrlKey && e.key === 'c') {
            closeTerminal();
            return;
        }
        if (e.key === 'Enter') {
            if (input !== '') {
                const newHistory = [...history, {
                    status: true,
                    result: `${currentDirectory}> ${input}`,
                    type: 'command'
                }
                ];
                setHistory(newHistory);
                processCommand(input, newHistory);
                setInput('');
            }
        }
    };

    const processCommand = (command) => {

        if (id === 'multi') {
            const connectedDevices = selectedDevicesAll?.filter(device => device?.status === DeviceStatus.connected);
            connectedDevices.forEach(dev => {
                executeCommand(command, commandTypes.CONSOLE, dev?.uid, dev?.systemDetails?.os);
            });
        } else {
            const currentDevice = devices?.find(dev => dev?._id === id);
            if (!currentDevice) {
                const dev = findDeviceById(deviceNetworks, id);
                console.log("dev", dev);
                executeCommand(command, commandTypes.CONSOLE, dev?.uid, dev?.systemDetails?.os);
            } else {
                executeCommand(command, commandTypes.CONSOLE, currentDevice?.uid, currentDevice?.systemDetails?.os);
            }
        }
        if (command === 'clear') {
            setHistory([]);
            return;
        }
    };

    useEffect(() => {
        endOfConsoleRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [history]);

    useEffect(() => {
        setupConsoleSocket(dispatch)
        return () => {
            unSubscribeSocketTopics([TOPICS.EXECUTE_RESPONSE]);
        };
    }, [dispatch]);
    console.log("history", history)

    const getDeviceId = (devices, uid) => {
        const device = devices?.find((dev) => dev?.uid === uid)
        return device?.deviceId;
    }

    const inputRef = useRef(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    return (
        <div className={styles.console}>
            <div className={styles.consoleTop}>
                <p>{id === 'multi' && `Devices : ${selectedDevicesAll?.length}`}</p>
                <p>OS : {id === 'multi' ? selectedDevicesAll?.[0]?.systemDetails?.os : selectedDevice?.systemDetails?.os}</p>
            </div>
            <div className={styles.history}>
                {id === 'multi' ?
                    <>
                        {history.map((line, index) => (
                            <pre style={{ color: line?.status ? 'white' : 'red' }} key={index}>
                                {line?.type === 'result' ? (
                                    <>
                                        {`Device Id: ${getDeviceId(devices, line?.uid)}`}
                                        <br />
                                        {line?.result}
                                    </>
                                ) : (
                                    line?.result
                                )}
                            </pre>
                        ))}

                    </> :
                    <>
                        {history.map((line, index) => (
                            <pre style={{ color: line?.status ? 'white' : 'red' }} key={index}>{line?.result}</pre >
                        ))}
                    </>
                }
                <div ref={endOfConsoleRef}></div>
                <span className={styles.prompt}>{`${currentDirectory}>`}</span>
                <input
                    type="text"
                    value={input}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyPress}
                    className={styles.input}
                    ref={inputRef}
                />
            </div>
        </div>
    );
};

export default MyConsole;
