import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

const PrivateNoLayoutRoutes = ({ auth }) => {

    return (
        auth ?
            <>
                <Outlet />
            </>
            : <Navigate to='/login' />
    )
}

export default PrivateNoLayoutRoutes;