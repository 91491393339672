import { Box, Divider, Grid, Typography } from '@mui/material';
import React, { Fragment } from 'react';
import MyCard from '../../shared/Card/Card';
import ColoredIcon from '../../shared/ColoredIcon';
import text from '../../shared/css/text.module.scss';
import shared from '../../shared/shared.module.scss';
import styles from './device.module.scss';
import { statItems } from './helper';
const DeviceStats = () => {
   
    return (
        <MyCard>
            <Grid xs={12} container >
                {statItems.map((item, index) => (
                    <Fragment key={index}>
                        <Grid item xs={6} display={'flex'} alignItems={'center'}>
                            <ColoredIcon bgColor={item?.color} icon={item?.icon}/>
                            <Box display={'flex'} flexDirection={'column'}  gap={1}>
                                <Typography className={text.heading2} >
                                    {item.label}
                                </Typography>
                                <Box display={'flex'} alignItems={'center'} gap={2}>
                                    <Typography className={text.heading2} sx={{ color: item?.color }}>
                                        {item.count}
                                    </Typography>
                                    <div style={{ backgroundColor: item?.color }} className={shared.statsBadge}></div>
                                </Box>     
                            </Box>
                        </Grid>
                        {index % 2 !== 0 && index !== statItems.length - 1 && (
                            <Grid item xs={12}>
                                <Divider className={styles.statsDivider} /> 
                            </Grid>
                        )}
                    </Fragment>
                ))}

            </Grid>
        </MyCard>
    );
};

export default DeviceStats;
