import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { authActions } from '../../../redux/slice/auth.slice';
import text from 'components/shared/css/text.module.scss';
import EmailForm from './EmailForm';
import TwoFaForm from './TwoFaForm';
import styles from './forgotpass.module.scss';
import LogoIcon from 'static/svgs/logo';
import LoginSlider from '../Login/LoginSlider';
const ForgotPasswordContainer = () => {
  const loginTab = useSelector(authActions.selectors.getLoginTab);

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <LogoIcon/>
        <div className={styles.loginBox}>
          {loginTab === 0 &&
            <EmailForm />
          }

          {loginTab === 1 &&
            <TwoFaForm />
          }

        </div>
      </div>
      <div className={styles.right}>
        <div>
          <LoginSlider />
        </div>
        <p className={`${text.normal2} ${text.textWhite} ${text.textCenter}`}>© 2022 BDATA. All rights reserved</p>
      </div>
    </div>
  )
}

export default ForgotPasswordContainer
