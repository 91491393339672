import { Add, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import BarsIcon from "static/svgs/Bars";
import DownloadIcon from "static/svgs/Download";
import PrintIcon from "static/svgs/Print";
import { setupDashboardSocket, setupMultiAppsSocket, setupSingleAppsSocket, unSubscribeSocketTopics } from "../../../../redux/saga/socket";
import { authActions } from "../../../../redux/slice/auth.slice";
import { customTableStyles } from "../../../shared/Tables/helper";
import styles from "./Apps.module.scss";
import { handleDownloadCsv, handlePrintCsv } from "components/utils/handlers";
import { packageColumns } from "components/modules/SingleDashboard/helper";
import { applicationsActions } from "../../../../redux/slice/applications.slice";
import { TOPICS } from "components/utils/enums";
import { packagesActions } from "../../../../redux/slice/packages.slice";
import { ADD_APP_TO_GROUP } from "../../../../redux/types/group.types"; // Add this line

const Apps = ({ groups, refreshGroups }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(authActions.selectors.getCurrentUser);
  const [selectedApps, setSelectedApps] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const handleOpen = () => {
    if (selectedApps.length === 0) {
      toast.error("Please choose application first");
      return;
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedGroup("");
  };

  useEffect(() => {
    setupSingleAppsSocket(dispatch);
    return () => {
      unSubscribeSocketTopics([TOPICS.SYSTEM_PACKAGES, TOPICS.APPLICATIONS]);
    };
  }, [dispatch]);

  const handleAddAppToGroup = async () => {
    try {
      dispatch({
        type: ADD_APP_TO_GROUP,
        payload: {
          groupId: selectedGroup,
          appIds: selectedApps,
        },
      });
      handleClose();
      refreshGroups();
    } catch (error) {
      console.error("Failed to add apps to group", error);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const applications = useSelector(applicationsActions.selectors.getMultiApps);
  const packages = useSelector(packagesActions.selectors.getMultiPackages);

  const combineApps = [...applications, ...packages];
  const [packageSearchTerm, setPackageSearchTerm] = useState("");
  const filteredApps = useMemo(() => {
    if (!packageSearchTerm) return combineApps;
    const regex = new RegExp(packageSearchTerm, "i");
    return combineApps.filter((pkg) =>
      Object.values(pkg).some((value) => regex.test(value))
    );
  }, [combineApps, packageSearchTerm]);

  const displayedApps = useMemo(() => {
    if (!searchQuery) return filteredApps;
    const regex = new RegExp(searchQuery, "i");
    return filteredApps.filter((app) =>
      Object.values(app).some((value) => regex.test(value))
    );
  }, [filteredApps, searchQuery]);

  return (
    <Box className={styles.container}>
      <Box className={styles.headerContainer}>
        <Typography variant="h6" className={styles.header}>
          All Applications <span className={styles.badge}>{displayedApps?.length || 0}</span>
        </Typography>
        <Typography className={styles.subheader}></Typography>
        <Box className={styles.controls}>
          <Box className={styles.iconButtons}>
            <IconButton className={styles.iconBtn} onClick={() => handleDownloadCsv(displayedApps, packageColumns, true, false)}>
              <DownloadIcon />
            </IconButton>
            <IconButton className={styles.iconBtn} onClick={() => handlePrintCsv(displayedApps, packageColumns, true, false)}>
              <PrintIcon />
            </IconButton>
            <IconButton className={styles.iconBtn}>
              <BarsIcon />
            </IconButton>
          </Box>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={styles.createButton}
            onClick={handleOpen}
          >
            Add App to Group
            <Add className={styles.icon} />
          </Button>
        </Box>
      </Box>
      <Box className={styles.tableControls}>
        <TextField
          variant="outlined"
          placeholder="Search for Applications"
          size="small"
          value={searchQuery}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <IconButton size="small">
                <Search />
              </IconButton>
            ),
          }}
          className={styles.search}
        />
      </Box>
      <Box sx={{ width: "100%", height: "450px" }}>
        <DataGrid
          rows={displayedApps}
          columns={packageColumns}
          pageSize={10}
          rowsPerPageOptions={[10, 20, 30]}
          pagination
          sx={customTableStyles}
          checkboxSelection
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setSelectedApps(newRowSelectionModel);
          }}
          rowSelectionModel={selectedApps}
          disableColumnMenu
        />
      </Box>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Select Group</DialogTitle>
        <DialogContent>
          <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel>Group</InputLabel>
            <Select
              value={selectedGroup}
              onChange={(e) => setSelectedGroup(e.target.value)}
              label="Group"
            >
              {Object.keys(groups).map((groupType) =>
                groups[groupType].map((group) => (
                  <MenuItem key={group._id} value={group._id}>
                    {group.name}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleAddAppToGroup}
            color="primary"
            disabled={!selectedGroup}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Apps;
