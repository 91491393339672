import { Add, SettingsRemote } from "@mui/icons-material";
import { Card } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { devicesActions } from "../../../redux/slice/devices.slice.js";
import { DELETE_DEVICES } from "../../../redux/types/devices.types";
import { openRemoteConsole } from "../../modules/SingleDashboard/helper.js";
import { filterItemsById } from "../../utils/index.js";
import CustomTable from "./CustomTable";
import MyTableToolbar from "./MyTable/MyTableToolbar";
import tables from "./table.module.scss";
import toast from "react-hot-toast";
import { DeviceStatus } from "../../utils/enums.js";

const CustomTableContainer = ({
  devices,
  toggleDrawer,
  isDrawerOpen,
  selectedRow,
  setSelectedRow,
  handleBtn1Click,
  deviceCols,
  onSearch,
  onDownload,
  onPrint,
  isUninstall,
  permissions
}) => {
  const dispatch = useDispatch();

  const [selectedRows, setSelectedRows] = useState([]);
  const handleDeleteClick = () => {
    if (!selectedRows?.length) {
      toast.error("Please Select Device");
      return
    }
    const payload = {
      deviceIds: selectedRows,
    };
    dispatch({ type: DELETE_DEVICES, payload });
  };
  const handleSelectedRows = (ids) => {
    setSelectedRows(ids);
  };

  const handleRemoteConsole = () => {
    const filteredDevices = filterItemsById(devices, selectedRows);
    const isAnyDisconnected = filteredDevices?.some(dev => dev.status !== DeviceStatus.connected);
    if (isAnyDisconnected) {
      toast.error("Cannot open remote console: One or more selected devices are disconnected.");
      return
    }
    // console.log("filteredDevices", filteredDevices)
    const isOperatingSystemSame = filteredDevices?.every(dev => dev?.systemDetails?.os === filteredDevices[0]?.systemDetails?.os);
    if (!isOperatingSystemSame) {
      toast.error("Cannot open remote console: Selected devices have different operating systems.");
      return;
    } dispatch(devicesActions.actions.setSelectedDevicesAll(filteredDevices))
    openRemoteConsole('multi')
  }

  return (
    <Card className={tables.tableContainer}>
      <MyTableToolbar
        handleDeleteClick={handleDeleteClick}
        handleBtn1Click={handleBtn1Click}
        handleBtn2Click={handleRemoteConsole}
        placeHolder={"Search for Devices"}
        btn1Txt={"Add Single Device"}
        btn1Icon={Add}
        btn2Txt={selectedRows?.length ? "Remote Console" : ''}
        btn2Icon={SettingsRemote}
        onSearch={onSearch}
        onDownload={onDownload}
        onPrint={onPrint}
        isUninstall={isUninstall}
      />
      <CustomTable
        handleSelectedRows={handleSelectedRows}
        deviceCols={deviceCols}
        devices={devices}
        isDrawerOpen={isDrawerOpen}
        toggleDrawer={toggleDrawer}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        permissions={permissions}
      />
    </Card>
  );
};

export default CustomTableContainer;
