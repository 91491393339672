import React from 'react'

const EmailIcon = () => {
    return (
        <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.68 1.45L9.38 7.51C9.55655 7.64951 9.77499 7.7254 10 7.7254C10.225 7.7254 10.4435 7.64951 10.62 7.51L18.32 1.45M18 15H2C1.73478 15 1.48043 14.8946 1.29289 14.7071C1.10536 14.5196 1 14.2652 1 14V2C1 1.73478 1.10536 1.48043 1.29289 1.29289C1.48043 1.10536 1.73478 1 2 1H18C18.2652 1 18.5196 1.10536 18.7071 1.29289C18.8946 1.48043 19 1.73478 19 2V14C19 14.2652 18.8946 14.5196 18.7071 14.7071C18.5196 14.8946 18.2652 15 18 15Z" stroke="#666666" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}
export default EmailIcon
