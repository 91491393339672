export const ArrowExport = ({ fill }) => {
    return <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.96223 6.5541C6.98374 4.2127 6.97181 2.05872 7 0C4.66019 0.0237434 2.50195 0.00903593 0.445926 0.0377846C0.438518 0.573275 0.436892 0.981249 0.430026 1.49294C1.83363 1.48608 3.28517 1.48012 4.50275 1.47392L0.101503 5.87519C-0.0332052 6.00988 -0.0338557 6.25427 0.0996962 6.38827L0.611426 6.89996C0.745375 7.03391 0.989802 7.03328 1.12453 6.89815L5.52576 2.49687C5.51383 3.85312 5.52124 5.20945 5.50274 6.5656C5.99662 6.56562 6.52965 6.55494 6.96189 6.55368L6.96223 6.5541Z" fill={fill} />
    </svg>
}


export const NetworkTopology = () => {
    return <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.5625 8.5625C9.285 8.5625 9.02625 8.63937 8.80125 8.7725L7.69687 7.66813C7.92562 7.33625 8.06063 6.93313 8.06063 6.5C8.06063 6.06687 7.92562 5.66375 7.69687 5.33188L8.80125 4.2275C9.02437 4.35875 9.28312 4.4375 9.5625 4.4375C10.3894 4.4375 11.0625 3.76437 11.0625 2.9375C11.0625 2.11063 10.3894 1.4375 9.5625 1.4375C8.73563 1.4375 8.0625 2.11063 8.0625 2.9375C8.0625 3.215 8.13937 3.47375 8.2725 3.69875L7.16813 4.80313C6.83625 4.57438 6.43313 4.43937 6 4.43937C5.56687 4.43937 5.16375 4.57438 4.83188 4.80313L3.7275 3.69875C3.85875 3.47562 3.9375 3.21687 3.9375 2.9375C3.9375 2.11063 3.26437 1.4375 2.4375 1.4375C1.61063 1.4375 0.9375 2.11063 0.9375 2.9375C0.9375 3.76437 1.61063 4.4375 2.4375 4.4375C2.715 4.4375 2.97375 4.36062 3.19875 4.2275L4.30313 5.33188C4.07438 5.66375 3.93937 6.06687 3.93937 6.5C3.93937 6.93313 4.07438 7.33625 4.30313 7.66813L3.19875 8.7725C2.97562 8.64125 2.71687 8.5625 2.4375 8.5625C1.61063 8.5625 0.9375 9.23563 0.9375 10.0625C0.9375 10.8894 1.61063 11.5625 2.4375 11.5625C3.26437 11.5625 3.9375 10.8894 3.9375 10.0625C3.9375 9.785 3.86062 9.52625 3.7275 9.30125L4.83188 8.19687C5.16375 8.42562 5.56687 8.56063 6 8.56063C6.43313 8.56063 6.83625 8.42562 7.16813 8.19687L8.2725 9.30125C8.14125 9.52437 8.0625 9.78312 8.0625 10.0625C8.0625 10.8894 8.73563 11.5625 9.5625 11.5625C10.3894 11.5625 11.0625 10.8894 11.0625 10.0625C11.0625 9.23563 10.3894 8.5625 9.5625 8.5625Z" fill="#0C5E9B" />
    </svg>
}

export const DeviceTopology = () => {
    return <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.7 8.9375H7.15388C7.14 9.36311 6.87806 9.625 6.54 9.625H5.4C5.04956 9.625 4.78088 9.24967 4.78556 8.9375H0.3C0.135 8.9375 0 9.09219 0 9.28125V9.625C0 10.3813 0.54 11 1.2 11H10.8C11.46 11 12 10.3813 12 9.625V9.28125C12 9.09219 11.865 8.9375 11.7 8.9375ZM10.8 1.03125C10.8 0.464063 10.395 0 9.9 0H2.1C1.605 0 1.2 0.464063 1.2 1.03125V8.25H10.8V1.03125ZM9.6 6.875H2.4V1.375H9.6V6.875Z" fill="#0B5D9B" />
    </svg>

}