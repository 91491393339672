import styles from './multiDashboard.module.scss';
const CustomLegend = ({ arr }) => {
    return (
        <div className={styles.customLegend}>
            {arr?.map((item, idx) => {
                return (
                    <div key={idx} className={styles.item} >
                        <div className={styles.box} style={{ backgroundColor: item?.color }}></div>
                        <p className={styles.text}>{item.label}</p>
                    </div>
                )
            })}

        </div >
    )
}

export default CustomLegend
