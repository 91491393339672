import React from 'react'

const LogoutIcon = ({ fill = "#FFF", width = "22", height = "22" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.2728 23.7209H3.16827C1.55009 23.7209 0.25 22.4172 0.25 20.7907V3.18023C0.25 1.55364 1.55009 0.25 3.16827 0.25H12.2728C13.8938 0.25 15.2182 1.55642 15.2182 3.18023V5.55689C15.2182 6.05984 14.8137 6.46457 14.3149 6.46457C13.8161 6.46457 13.4116 6.05984 13.4116 5.55689V3.18023C13.4116 2.54269 12.89 2.06537 12.2728 2.06537H3.16827C2.54084 2.06537 2.05667 2.55273 2.05667 3.18023V20.7907C2.05667 21.4182 2.54084 21.9055 3.16827 21.9055H12.2728C12.89 21.9055 13.4116 21.4282 13.4116 20.7907V18.3663C13.4116 17.8634 13.8161 17.4586 14.3149 17.4586C14.8137 17.4586 15.2182 17.8634 15.2182 18.3663V20.7907C15.2182 22.4145 13.8938 23.7209 12.2728 23.7209Z" fill={fill} stroke={fill} strokeWidth="0.5" />
            <path d="M19.86 17.8244L19.8583 17.8229C19.4816 17.498 19.4386 16.921 19.7683 16.5419L19.7689 16.5413L22.564 13.3066L22.9213 12.8931H22.3749H7.21193C6.71316 12.8931 6.30859 12.4884 6.30859 11.9854C6.30859 11.4825 6.71316 11.0778 7.21193 11.0778H22.1171H22.719L22.2942 10.6513L19.8043 8.15208C19.4537 7.80018 19.4537 7.22249 19.8043 6.87059C20.1544 6.51921 20.7283 6.51921 21.0783 6.87059L25.4474 11.2562L25.4586 11.2674L25.471 11.2771C25.683 11.442 25.8155 11.6967 25.8155 11.9854C25.8155 12.2516 25.6989 12.4924 25.5168 12.6591L25.506 12.6689L25.4965 12.68L21.1274 17.7329L21.1274 17.7329L21.1254 17.7353C20.9513 17.9418 20.7037 18.0482 20.4481 18.0482C20.2428 18.0482 20.0304 17.9741 19.86 17.8244Z" fill={fill} stroke={fill} strokeWidth="0.5" />
        </svg>
 )
}
export default LogoutIcon
