export const computeTotalGroupsCount = (groups) => {
  return Object.values(groups).reduce(
    (total, groupArray) => total + groupArray.length,
    0
  );
};

export const countUserRoles = (users) => {
  const rolesSet = new Set(users.map(user => user.role));
  return Array.from(rolesSet);
};