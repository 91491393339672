import { Add } from "@mui/icons-material";
import { Card, Grid } from "@mui/material";
import CustomDialog from "components/shared/Dialog/CustomDialog";
import { DeviceStatus, TOPICS } from "components/utils/enums";
import { handleDownloadCsv, handlePrintCsv } from "components/utils/handlers";
import { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { sendToSocket, setUpDeviceUsersSocket, unSubscribeSocketTopics } from "../../../redux/saga/socket";
import { deviceUsersActions } from "../../../redux/slice/deviceUsers.slice";
import { devicesActions } from "../../../redux/slice/devices.slice";
import { GET_DEVICE_USERS } from "../../../redux/types/shared.types";
import CustomTitle from "../../shared/CustomTitle";
import MyTable from "../../shared/Tables/MyTable/MyTable";
import MyTableToolbar from "../../shared/Tables/MyTable/MyTableToolbar";
import table from "../../shared/Tables/table.module.scss";
import AddDeviceForm from "./AddDeviceForm";
import { alertColumns, getDeviceUserColumns } from "./helper";

const DeviceUsers = ({userPermissions}) => {
  const deviceUsers = useSelector(deviceUsersActions.selectors.getdeviceUsers);
  const currentDevice = useSelector(devicesActions.selectors.getSelectedDevice);
  const [userSearchTerm, setUserSearchTerm] = useState("");
  const { id } = useParams();
  const dispatch = useDispatch();
  const [addUserPopUs, setAddUserPopUp] = useState(false);

  const handleAddUserClick = () => {
    if(userPermissions.ADD_DEVICE_USER){
      if (currentDevice?.status === DeviceStatus.disconnected) {
        return toast.error("Device is Disconnected")
      }
      handleAddUserPopup();
    }else{
      toast.error("Permissions not allowed")
    }
  }
  const handleAddUserPopup = () => {
    setAddUserPopUp(!addUserPopUs)
  }

  useEffect(() => {
    if (id) {
      const payload = {
        deviceId: id,
      };
      dispatch({ type: GET_DEVICE_USERS, payload });
    }
  }, [dispatch, id]);

  const handleUserSearch = (term) => {
    setUserSearchTerm(term);
  };

  useEffect(() => {
    setUpDeviceUsersSocket(dispatch);
    return () => {
      unSubscribeSocketTopics([
        TOPICS.USERS
      ]);
    };
  }, [dispatch]);

  const filteredUsers = useMemo(() => {
    if (!userSearchTerm) return deviceUsers;
    const regex = new RegExp(userSearchTerm, "i");
    return deviceUsers.filter((alert) =>
      Object.values(alert).some((value) => regex.test(value))
    );
  }, [deviceUsers, userSearchTerm]);

  const addDeviceUser = (data) => {
    const payload = {
      os: currentDevice?.systemDetails?.os,
      uid: currentDevice?.uid,
      user: data
    }
    sendToSocket("CREATE_DEVICE_USER", payload);
    handleAddUserPopup()
  }

  const deviceUserColumns = useMemo(() => {
    return getDeviceUserColumns(currentDevice, userPermissions.DELETE_DEVICE_USER);
  }, [currentDevice])

  return (
    <div>
      <CustomDialog borderRadius={'10px'} maxWidth={'lg'} title={'Add User Inside Device'} handleToggle={handleAddUserPopup} isOpen={addUserPopUs} >
        <AddDeviceForm addDeviceUser={addDeviceUser} />
      </CustomDialog>
      <Grid
        my={"15px"}
        container
        sx={{ justifyContent: { xl: "space-between", md: "center" } }}
        spacing={2}
      ></Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} xl={12} className={table.tableContainer}>
          <CustomTitle title={"Users"} count={deviceUsers?.length} />
          <Card>
            <MyTableToolbar
              placeHolder={"Search For Users"}
              onSearch={handleUserSearch}
              onDownload={() =>
                handleDownloadCsv(filteredUsers, alertColumns)
              }
              onPrint={() => handlePrintCsv(filteredUsers, alertColumns)}
              isAlerts={false}
              btn2Txt={"Add Users"}
              btn2Icon={Add}
              handleBtn2Click={handleAddUserClick}

            />
            <MyTable
              checkboxSelection={false}
              columns={deviceUserColumns}
              rows={filteredUsers}
            />
          </Card>
        </Grid>
      </Grid>
      <br />

    </div>
  );
};

export default DeviceUsers;
