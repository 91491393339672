import { call, put, takeEvery } from "redux-saga/effects";
import {
  fetchGroupsService,
  createGroupService,
  addUserToGroupService,
  addAppToGroupService,
} from "../services/group.service";
import {
  groupActions,
} from "../slice/group.slice";
import { ADD_APP_TO_GROUP, ADD_USER_TO_GROUP, CREATE_GROUP, FETCH_GROUPS } from "../types/group.types";
import toast from "react-hot-toast";

function* fetchGroupsSaga() {
  try {
    yield put(groupActions.fetchGroupsRequest());
    const groups = yield call(fetchGroupsService);
    yield put(groupActions.fetchGroupsSuccess(groups));
  } catch (error) {
    yield put(groupActions.fetchGroupsFailure(error.message));
  }
}

function* createGroupSaga(action) {
  try {
    yield put(groupActions.createGroupRequest());
    const group = yield call(createGroupService, action.payload);
    yield put(groupActions.createGroupSuccess(group));
  } catch (error) {
    yield put(groupActions.createGroupFailure(error.message));
  }
}

function* addUserToGroupSaga(action) {
  try {
    const response = yield call(addUserToGroupService, action.payload)
    toast.success("User added to group successfully");
  } catch (error) {
    toast.error(error?.response?.data?.message)
  }
}

function* addAppToGroupSaga(action) { // Add this function
  try {
    const response = yield call(addAppToGroupService, action.payload.groupId, action.payload.appIds);
    yield put(groupActions.addAppToGroupSuccess(response));
    toast.success("App added to group successfully");
  } catch (error) {
    yield put(groupActions.addAppToGroupFailure(error.message));
    toast.error(error?.response?.data?.message)
  }
}

export function* watchGroupAsync() {
  yield takeEvery(FETCH_GROUPS, fetchGroupsSaga);
  yield takeEvery(CREATE_GROUP, createGroupSaga);
  yield takeEvery(ADD_USER_TO_GROUP, addUserToGroupSaga);
  yield takeEvery(ADD_APP_TO_GROUP, addAppToGroupSaga);
}
