import React from "react";
import {
  Box,
  Grid,
} from "@mui/material";
import styles from "./NetworkCustomizable.module.scss";
import DeviceNetworkDetails from "./DeviceNetworkDetails/DeviceNetworkDetails";
import HighAvailabilityCard from "./HighAvailabilityCard/HighAvailabilityCard";
import RamAndCpuCard from "./RamAndCpuCard/RamAndCpuCard";
import ConnectionsCard from "./ConnectionsCard/ConnectionsCard";
import CustomTitle from "components/shared/CustomTitle";
import ConnectionRates from "./ConnectionRates/ConnectionRates";

const NetworkCustomizable = () => {
  return (
    <Box>
      <CustomTitle title={"Network Customizable Analysis"} isCount={false} />
      <Box className={styles.container}>
        <Grid container spacing={2} className={styles.section}>
          <Grid item xs={12} sm={6} md={4} lg={4} className={styles.sectionItem}>
            <DeviceNetworkDetails />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} className={styles.sectionItem}>
            <HighAvailabilityCard />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} className={styles.sectionItem}>
            <RamAndCpuCard />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} className={styles.sectionItem}>
            <ConnectionsCard />
          </Grid>
        </Grid>
        <Box>
          <CustomTitle title={"Connection Rates"} isCount={true} count={2} />
          <ConnectionRates/>
        </Box>
      </Box>
    </Box>
  );
};

export default NetworkCustomizable;
