import { call, put, select, takeEvery } from "redux-saga/effects";
import { deleteFileServ, fetchAlertsService, getDashboardAnalyticsServ, getDeviceUsersServ, getFilesServ, getFimServ } from "../services/single.service";
import { applicationsActions } from "../slice/applications.slice";
import { networksActions } from "../slice/networks.slice";
import { packagesActions } from "../slice/packages.slice";
import { portsActions } from "../slice/ports.slice";
import { processesActions } from "../slice/processes.slice";
import { systemActions } from "../slice/system.slice";
import { DELETE_FILE, GET_DASHBOARD_ANALYTICS, GET_DEVICE_USERS, GET_FILES, GET_FIM } from "../types/shared.types";
import { insertIdToArray } from "components/utils";
import { filesActions } from "../slice/files.slice";
import { fimActions } from "../slice/fim.slice";
import { deviceUsersActions } from "../slice/deviceUsers.slice";
import { FETCH_ALERTS } from "../types/devices.types";
import { alertsActions } from "../slice/alerts.slice";


export function* getDashboardAnalytics(action) {
    try {
        const response = yield getDashboardAnalyticsServ(action.payload)
        if (response) {
            yield put(applicationsActions.actions.setApplications(insertIdToArray(response?.applications?.result)));
            yield put(applicationsActions.actions.setCombineApps(insertIdToArray(response?.combinedApps?.result)));
            yield put(networksActions.actions.setNetworks(insertIdToArray(response?.networks?.result)));
            yield put(packagesActions.actions.setPackages(insertIdToArray(response?.packages?.result)));
            yield put(portsActions.actions.setPorts(insertIdToArray(response?.ports?.result)));
            yield put(processesActions.actions.setProcesses(insertIdToArray(response?.processes?.result)));
            yield put(systemActions.actions.setSystem(response?.system?.result));
        }
        else {
            yield put(applicationsActions.actions.setApplications([]));
            yield put(applicationsActions.actions.setCombineApps([]));
            yield put(networksActions.actions.setNetworks([]));
            yield put(packagesActions.actions.setPackages([]));
            yield put(portsActions.actions.setPorts([]));
            yield put(processesActions.actions.setProcesses([]));
            yield put(systemActions.actions.setSystem({}));
        }
    } catch (err) {
        console.log("err", err);
    }
}

export function* getFiles(action) {
    try {
        const response = yield getFilesServ(action.payload)
        if (response) {
            yield put(filesActions.actions.setFiles(response));
        }
        else {

        }
    } catch (err) {
        console.log("err", err);
    }
}

export function* getFim(action) {
    try {
        const response = yield getFimServ(action.payload)
        if (response) {
            yield put(fimActions.actions.setFim(insertIdToArray(response?.["Events:"])));
        }
        else {
            yield put(fimActions.actions.setFim([]));
        }
    } catch (err) {
        console.log("err", err);
    }
}

export function* deleteFile(action) {
    try {
        const response = yield deleteFileServ(action.payload)
        if (response) {
            const files = yield select(filesActions.selectors.getFiles);
            const updatedFiles = files?.filter((file) => file?._id !== action?.payload?.fileId);
            yield put(filesActions.actions.setFiles(updatedFiles));
        }
        else {

        }
    } catch (err) {
        console.log("err", err);
    }
}

export function* getDeviceUsers(action) {
    try {
        const response = yield getDeviceUsersServ(action.payload);
        if (response) {
            yield put(deviceUsersActions.actions.setdeviceUsers(insertIdToArray(response?.data?.result)));
        }
        else {
            yield put(deviceUsersActions.actions.setdeviceUsers([]));
        }
    } catch (err) {
        console.log("err", err);
    }
}

function* fetchAlertsSaga(action) {
    try {
        const response = yield call(fetchAlertsService, action.payload);
        yield put(alertsActions.actions.setAlerts(insertIdToArray(response?.result)))
    } catch (error) {
    }
}

export function* watchSingleAsync() {
    yield takeEvery(GET_DASHBOARD_ANALYTICS, getDashboardAnalytics);
    yield takeEvery(GET_FILES, getFiles);
    yield takeEvery(DELETE_FILE, deleteFile);
    yield takeEvery(GET_FIM, getFim);
    yield takeEvery(GET_DEVICE_USERS, getDeviceUsers);
    yield takeEvery(FETCH_ALERTS, fetchAlertsSaga);

}