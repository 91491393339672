import { call, put, select, takeEvery } from "redux-saga/effects";
import { fetchAlertSettingsService, updateAlertSettingsService } from "../services/alertSettings.service";
import { alertSettingsActions } from "../slice/alertSettings.slice";
import { FETCH_ALERT_SETTINGS, UPDATE_ALERT_SETTINGS } from "../types/alertSettings.types";
import { authActions } from "../slice/auth.slice";

function* fetchAlertSettingsSaga() {
    try {
      const currentUser = yield select(authActions.selectors.getCurrentUser);
      const userId = currentUser._id;
      const alertSettings = yield call(fetchAlertSettingsService, userId);
      yield put(alertSettingsActions.fetchAlertSettingsSuccess(alertSettings));
    } catch (error) {
      yield put(alertSettingsActions.fetchAlertSettingsFailure(error.message));
    }
  }

function* updateAlertSettingsSaga(action) {
  try {
    const updatedSettings = yield call(updateAlertSettingsService, action.payload);
    yield put(alertSettingsActions.updateAlertSettingsSuccess(updatedSettings));
  } catch (error) {
    yield put(alertSettingsActions.updateAlertSettingsFailure(error.message));
  }
}

export function* watchAlertSettingsAsync() {
  yield takeEvery(FETCH_ALERT_SETTINGS, fetchAlertSettingsSaga);
  yield takeEvery(UPDATE_ALERT_SETTINGS, updateAlertSettingsSaga);
}
