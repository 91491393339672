import React from 'react'

const DeleteIcon2 = ({ fill = "#0C5E9B", width = "16", height = "19", className }) => {
    return (
        <svg className={className} width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.11914 5.74636H12.6549C13.3006 5.57418 13.774 4.99309 13.774 4.28287V3.80938C13.774 2.97003 13.1069 2.30285 12.2675 2.30285H10.0292C10.0292 1.03305 8.99617 0 7.72638 0H6.06919C4.79939 0 3.76634 1.03305 3.76634 2.30285H1.50654C0.66718 2.30285 0 2.97003 0 3.80938V4.28287C0 4.99309 0.473483 5.57418 1.11914 5.74636ZM6.04766 1.31284H7.70485C8.26442 1.31284 8.71638 1.7648 8.71638 2.32437H5.03613C5.05766 1.7648 5.50962 1.31284 6.04766 1.31284Z" fill={''} />
            <path d="M4.04596 17.7564H9.72775C11.2773 17.7564 12.5256 16.5081 12.5256 14.9585V6.82324H1.24811V15.389C1.24811 16.4866 2.5179 17.7564 4.04596 17.7564ZM8.67318 9.29827H9.96449V14.894H8.67318V9.29827ZM6.2412 9.29827H7.53251V14.894H6.2412V9.29827ZM3.80922 9.29827H5.10054V14.894H3.80922V9.29827Z" fill={''} />
        </svg>

    )
}
export default DeleteIcon2
