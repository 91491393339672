import { insertIdToArray } from ".";

export const parseAndMergeData = (data, stateArray, dispatch, dispatchAction) => {
    const parsedMessage = JSON.parse(data?.message);
    let result;

    try {
        result = JSON.parse(parsedMessage?.result);
    } catch (error) {
        result = parsedMessage?.result;
    }

    const oldArray = stateArray?.filter(item => item?.uid !== parsedMessage?.uid)?.map(({ id, ...rest }) => rest);
    const newArray = Array.isArray(result) ? result.map((res) => (
        {
            uid: parsedMessage?.uid,
            ...res
        }
    )) : [];

    const newMergedArray = [...newArray, ...oldArray];
    dispatch(dispatchAction(insertIdToArray(newMergedArray)));
};