import React from 'react'

const NoneIcon = ({ fill = "#0C5E9B", width = "18", height = "18" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.75 0C3.925 0 0 3.925 0 8.75C0 13.575 3.925 17.5 8.75 17.5C13.575 17.5 17.5 13.575 17.5 8.75C17.5 3.925 13.575 0 8.75 0ZM1.5 8.75C1.5 4.752 4.752 1.5 8.75 1.5C10.479 1.5 12.068 2.11 13.315 3.124L3.124 13.315C2.11 12.068 1.5 10.479 1.5 8.75ZM8.75 16C7.021 16 5.432 15.39 4.185 14.376L14.376 4.185C15.39 5.432 16 7.021 16 8.75C16 12.748 12.748 16 8.75 16Z" fill={fill} />
        </svg>

 )
}
export default NoneIcon
