import apiInterceptor from "./Interceptors/ApiInterceptor";

export const fetchAlertSettingsService = async (userId) => {
  try {
    const response = await apiInterceptor.get(`/get-alert-settings?userId=${userId}`);

    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const updateAlertSettingsService = async (payload) => {
  try {
    const response = await apiInterceptor.post("/update-alert-settings", payload);

    return response?.data;
  } catch (error) {
    throw error;
  }
};
