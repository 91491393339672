import apiInterceptor from "./Interceptors/ApiInterceptor";

export const getDeviceTokenServ = async (payload) => {
  try {
    const response = await apiInterceptor.post(`/get-device-token`, payload);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const getDevicesServ = async (payload) => {
  try {
    const response = await apiInterceptor.get(`/get-registered-devices`, {
      params: payload,
    });
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const getSingleDeviceServ = async (payload) => {
  try {
    const response = await apiInterceptor.get(`/get-device`, {
      params: payload,
    });
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const getDeviceLocationsServ = async (payload) => {
  try {
    const response = await apiInterceptor.get(`/get-devices-location`, {
      params: payload,
    });
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const getSingleDeviceLocationsServ = async (payload) => {
  try {
    const response = await apiInterceptor.get(`/get-single-device-location`, {
      params: payload,
    });
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const deleteDevicesServ = async (payload) => {
  try {
    const response = await apiInterceptor.post(
      `/delete-multi-devices`,
      payload
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
};
