import React from 'react'

const CpuIcon = ({ fill = "white", width = "19", height = "19" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24.3972 14.3078H20.8405V10.6922H24.3972C24.7298 10.6922 25 10.4218 25 10.0894C25 9.7571 24.7296 9.48668 24.3972 9.48668H20.8405V6.67956C20.8405 5.29033 19.7097 4.16206 18.3204 4.16206H15.5136V0.602767C15.5136 0.270427 15.2429 0 14.9106 0C14.5782 0 14.3078 0.270427 14.3078 0.602767V4.16206H10.6925V0.602767C10.6925 0.270427 10.422 0 10.0897 0C9.75736 0 9.48668 0.270427 9.48668 0.602767V4.16206H6.67956C5.29033 4.16206 4.1595 5.29033 4.1595 6.67956V9.48642H0.602767C0.270171 9.48642 0 9.75685 0 10.0892C0 10.4215 0.270427 10.692 0.602767 10.692H4.1595V14.3075H0.602767C0.270171 14.3075 0 14.578 0 14.9103C0 15.2426 0.270427 15.5131 0.602767 15.5131H4.1595V18.3204C4.1595 19.7097 5.29033 20.8379 6.67956 20.8379H9.48642V24.3972C9.48642 24.7296 9.7571 25 10.0894 25C10.4218 25 10.6922 24.7296 10.6922 24.3972V20.8379H14.3075V24.3972C14.3075 24.7296 14.578 25 14.9103 25C15.2426 25 15.5133 24.7296 15.5133 24.3972V20.8379H18.3204C19.7097 20.8379 20.8405 19.7097 20.8405 18.3204V15.5136H24.3972C24.7298 15.5136 25 15.2432 25 14.9108C25 14.5785 24.7298 14.3078 24.3972 14.3078ZM15.2094 9.12798C15.5752 9.12798 15.872 9.42732 15.872 9.79318V15.2094C15.872 15.5752 15.5752 15.872 15.2094 15.872H9.79318C9.42732 15.872 9.12798 15.5752 9.12798 15.2094V9.79318C9.12798 9.42732 9.42732 9.12798 9.79318 9.12798H15.2094Z" fill={fill} />
        </svg>
    )
}
export default CpuIcon
