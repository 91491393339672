import { Add, Close, Search } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MyTable from "components/shared/Tables/MyTable/MyTable";
import { alertOptions } from "components/utils/accessManagement";
import { handleDownloadCsv, handlePrintCsv } from "components/utils/handlers";
import { ErrorMessage, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import BarsIcon from "static/svgs/Bars";
import DownloadIcon from "static/svgs/Download";
import PrintIcon from "static/svgs/Print";
import * as Yup from "yup";
import { removeAlertPolicyService } from "../../../../redux/services/alertPolicy.service";
import {
  ASSIGN_ALERT_POLICY_TO_GROUP,
  CREATE_ALERT_POLICY,
} from "../../../../redux/types/alertPolicy.types";
import { getAlertColumns } from "./helper";
import styles from "./Policies.module.scss"; // Import the styles using CSS modules

const PolicyAlerts = ({ alertPolicies, groups, refreshGroups }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [alertOpen, setAlertOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("");
  const [assignOpen, setAssignOpen] = useState(false);
  const [selectedPolicies, setSelectedPolicies] = useState([]);
  const [selectedOS, setSelectedOS] = useState("windows");
  const [selectedType, setSelectedType] = useState("FIM");
  const [alertLevel, setAlertLevel] = useState("Low");

  const [deleteAlertPolicyOpen, setDeleteAlertPolicyOpen] = useState(false);
  const [selectedAlertPolicy, setSelectedAlertPolicy] = useState(null);
  const [filteredAlerts, setFilteredAlerts] = useState([]);

  useEffect(() => {
    if (alertPolicies?.length) {
      setFilteredAlerts(alertPolicies)
    }
  }, [alertPolicies])

  const handleAlertOpen = () => {
    setAlertOpen(true);
  };

  const handleAssignOpen = () => {
    if (selectedPolicies.length === 0) {
      toast.error("Please choose policy first");
      return;
    }
    setAssignOpen(true);
  };

  const handleAssignClose = () => {
    setAssignOpen(false);
    setSelectedGroup("");
    setSelectedPolicies([]);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleCreatePolicy = (values) => {
    dispatch({ type: CREATE_ALERT_POLICY, payload: values });
    setAlertOpen(false);
  };

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    if (query === "") {
      setFilteredAlerts(alertPolicies);
    } else {
      // Otherwise, filter the alerts based on the search query
      setFilteredAlerts(
        alertPolicies.filter(
          (alert) =>
            alert.policyName.toLowerCase().includes(query.toLowerCase()) ||
            alert.type.toLowerCase().includes(query.toLowerCase()) ||
            alert.osPlatform.toLowerCase().includes(query.toLowerCase()) ||
            alert.details
              .join(", ")
              .toLowerCase()
              .includes(query.toLowerCase())
        )
      );
    }
  };


  const handleAssignPolicyToGroup = async () => {
    dispatch({
      type: ASSIGN_ALERT_POLICY_TO_GROUP,
      payload: { groupId: selectedGroup, policyIds: selectedPolicies },
    });
    handleAssignClose();
    refreshGroups();
  };

  const validationSchema = Yup.object({
    policyName: Yup.string().required("Policy Name is required"),
    type: Yup.string().required("Type is required"),
    osPlatform: Yup.string().required("OS Platform is required"),
    details: Yup.array().min(1, "At least one detail is required"),
  });

  const handleDeleteAlertPolicyOpen = (id) => {
    setSelectedAlertPolicy(id);
    setDeleteAlertPolicyOpen(true);
  };

  const alertColumns = getAlertColumns(handleDeleteAlertPolicyOpen)

  const handleDeleteAlertPolicyClose = () => {
    setDeleteAlertPolicyOpen(false);
  };

  const handleDeleteAlertPolicy = async () => {
    toast
      .promise(removeAlertPolicyService(selectedAlertPolicy), {
        loading: "Deleting alert policy...",
        success: "Alert policy deleted successfully",
        error: "Failed to delete alert policy",
      })
      .then(() => {
        setFilteredAlerts(
          filteredAlerts.filter((alert) => alert._id !== selectedAlertPolicy)
        );
        setDeleteAlertPolicyOpen(false);
        refreshGroups();
        setSelectedAlertPolicy(null);
      })
      .catch((error) => {
        console.error("Failed to delete alert policy", error);
      });
  };

  return (
    <Box mt={4} className={styles.container}>
      <Box className={styles.headerContainer}>
        <Typography variant="h6" className={styles.header}>
          Policy Alerts
        </Typography>
        <Typography className={styles.subheader}>
          A Policy Alert is a Notification in Bdata that Defines an Alert.
        </Typography>
        <Box className={styles.controls}>
          <Box className={styles.iconButtons}>
            <IconButton className={styles.iconBtn} onClick={handleDownloadCsv}>
              <DownloadIcon />
            </IconButton>
            <IconButton className={styles.iconBtn} onClick={handlePrintCsv}>
              <PrintIcon />
            </IconButton>
            <IconButton className={styles.iconBtn}>
              <BarsIcon />
            </IconButton>
          </Box>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={styles.createButton}
            onClick={handleAssignOpen}
          >
            Assign Alert Policies to Group
            <Add className={styles.icon} />
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={styles.createButton}
            onClick={handleAlertOpen}
          >
            Add Alert Policy
            <Add className={styles.icon} />
          </Button>
        </Box>
      </Box>
      <Box className={styles.tableControls}>
        <TextField
          variant="outlined"
          placeholder="Search for Policy Alerts"
          size="small"
          value={searchQuery}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <IconButton size="small">
                <Search />
              </IconButton>
            ),
          }}
          className={styles.search}
        />
      </Box>
      <MyTable
        checkboxSelection={true}
        columns={alertColumns}
        rows={filteredAlerts}
        handleSelectedRows={setSelectedPolicies}
        id={(row) => row?._id}
      />

      {/* Assign Policies to Group Dialog */}
      <Dialog
        open={assignOpen}
        onClose={handleAssignClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Select Group</DialogTitle>
        <DialogContent>
          <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel>Group</InputLabel>
            <Select
              value={selectedGroup}
              onChange={(e) => setSelectedGroup(e.target.value)}
              label="Group"
            >
              {Object.keys(groups).map((groupType) =>
                groups[groupType].map((group) => (
                  <MenuItem key={group._id} value={group._id}>
                    {group.name}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAssignClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleAssignPolicyToGroup}
            color="primary"
            disabled={!selectedGroup}
          >
            Assign
          </Button>
        </DialogActions>
      </Dialog>

      {/* Create New Alert Policy Dialog */}
      <Dialog
        open={alertOpen}
        onClose={handleAlertClose}
        maxWidth="sm"
        fullWidth
        fullScreen={fullScreen}
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <Formik
          initialValues={{
            policyName: "",
            type: "",
            osPlatform: "",
            alertLevel,
            details: [],
          }}
          validationSchema={validationSchema}
          onSubmit={handleCreatePolicy}
        >
          {({ values, handleChange, setFieldValue, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <DialogTitle className={styles.dialogTitle}>
                Add Alert Policy
                <IconButton
                  aria-label="close"
                  onClick={handleAlertClose}
                  className={styles.closeButton}
                >
                  <Close />
                </IconButton>
              </DialogTitle>
              <Divider className={styles.divider} />
              <DialogContent>
                <Box display="flex" flexDirection="column" gap={0}>
                  <FormControl fullWidth margin="normal">
                    <TextField
                      name="policyName"
                      label="Policy Name"
                      value={values.policyName}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                    />
                    <ErrorMessage
                      name="policyName"
                      component="div"
                      className={styles.error}
                    />
                  </FormControl>
                  <Box display="flex" justifyContent="space-between" gap={2}>
                    <FormControl fullWidth margin="normal">
                      <InputLabel>Select OS</InputLabel>
                      <Select
                        name="osPlatform"
                        value={values.osPlatform}
                        onChange={(e) => {
                          handleChange(e);
                          setSelectedOS(e.target.value);
                        }}
                        input={<OutlinedInput label="Select OS" />}
                      >
                        <MenuItem value="windows">Windows</MenuItem>
                        <MenuItem value="ubuntu">Ubuntu</MenuItem>
                      </Select>
                      <ErrorMessage
                        name="osPlatform"
                        component="div"
                        className={styles.error}
                      />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                      <InputLabel>Select Type</InputLabel>
                      <Select
                        name="type"
                        value={values.type}
                        onChange={(e) => {
                          handleChange(e);
                          setSelectedType(e.target.value);
                        }}
                        input={<OutlinedInput label="Select Type" />}
                      >
                        <MenuItem value="Port">Port</MenuItem>
                        <MenuItem value="FIM">FIM</MenuItem>
                        <MenuItem value="Process">Process</MenuItem>
                      </Select>
                      <ErrorMessage
                        name="type"
                        component="div"
                        className={styles.error}
                      />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                      <InputLabel>Alert Level</InputLabel>
                      <Select
                        name="alertLevel"
                        value={values.alertLevel}
                        onChange={(e) => {
                          handleChange(e);
                          setAlertLevel(e.target.value);
                        }}
                        input={<OutlinedInput label="Select Type" />}
                      >
                        <MenuItem value="Low">Low</MenuItem>
                        <MenuItem value="Medium">Medium</MenuItem>
                        <MenuItem value="High">High</MenuItem>
                      </Select>
                      <ErrorMessage
                        name="alertLevel"
                        component="div"
                        className={styles.error}
                      />
                    </FormControl>
                  </Box>
                  <FormControl fullWidth margin="normal">
                    <Autocomplete
                      multiple
                      freeSolo
                      value={values.details}
                      onChange={(event, newValue) =>
                        setFieldValue("details", newValue)
                      }
                      options={alertOptions[selectedOS][selectedType]}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            label={option}
                            {...getTagProps({ index })}
                            onDelete={() => {
                              const newDetails = values.details.filter(
                                (item) => item !== option
                              );
                              setFieldValue("details", newDetails);
                            }}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Enter Black List"
                          placeholder="Type and press enter to add"
                          onKeyDown={(event) => {
                            if (
                              event.key === "Enter" &&
                              event.target.value.trim() !== ""
                            ) {
                              event.preventDefault();
                              const newDetail = event.target.value.trim();
                              setFieldValue("details", [
                                ...values.details,
                                newDetail,
                              ]);
                              event.target.value = "";
                            }
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                    <ErrorMessage
                      name="details"
                      component="div"
                      className={styles.error}
                    />
                  </FormControl>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button type="submit" className={styles.addButton}>
                  Add
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>

      {/* Delete Alert Policy */}
      <Dialog
        open={deleteAlertPolicyOpen}
        onClose={handleDeleteAlertPolicyClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Delete Alert Policy</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this alert policy?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteAlertPolicyClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteAlertPolicy} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PolicyAlerts;
