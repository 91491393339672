import { Box, Grid, Typography } from '@mui/material'
import React, { memo } from 'react'
import text from '../shared/css/text.module.scss';
import shared from './shared.module.scss';

const CustomTitle = ({ title, count, isCount = true }) => {
  return (
    <Grid container justifyContent={'flex-start'} alignItems={'center'} gap={2} marginTop={'3vh'}>
      <Typography className={text.heading3}>{title}</Typography>
      {isCount ? <Box className={shared.badge} >{count}</Box> : null}
    </Grid>
  )
}

export default memo(CustomTitle);
