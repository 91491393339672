import { Card, Grid } from "@mui/material";
import { handleDownloadCsv, handlePrintCsv } from "components/utils/handlers";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { applicationsActions } from "../../../redux/slice/applications.slice";
import { devicesActions } from "../../../redux/slice/devices.slice";
import { packagesActions } from "../../../redux/slice/packages.slice";
import UninstallIcon from "../../../static/svgs/uninstall";
import CustomTitle from "../../shared/CustomTitle";
import MyTable from "../../shared/Tables/MyTable/MyTable";
import MyTableToolbar from "../../shared/Tables/MyTable/MyTableToolbar";
import shared from "../../shared/shared.module.scss";
import TotalApps from "./TotalApps";
import { appColumns, handleDeleteApps, userAppsColumns } from "./helper";
import { setupSingleAppsSocket, unSubscribeSocketTopics } from "../../../redux/saga/socket";
import { TOPICS } from "components/utils/enums";

const AppsContainer = ({ applications, packages }) => {
  const [appSearchTerm, setAppSearchTerm] = useState("");
  const [packageSearchTerm, setPackageSearchTerm] = useState("");
  const selectedDevice = useSelector(devicesActions.selectors.getSelectedDevice);

  const dispatch = useDispatch();

  const handleAppSearch = (term) => setAppSearchTerm(term);
  const handlePackageSearch = (term) => setPackageSearchTerm(term);

  const [selectedUserApps, setSelectedUserApps] = useState([]);
  const [selectedSystemApps, setSelectedSystemApps] = useState([]);

  const handleUserAppsSelection = (ids) => {
    setSelectedUserApps(ids);
  };

  const handleSystemAppsSelection = (ids) => {
    setSelectedSystemApps(ids);
  };

  const handleDeleteUserApps = () => {
    handleDeleteApps(
      selectedUserApps,
      filteredApplications,
      applicationsActions.actions.setApplications,
      dispatch,
      selectedDevice
    );
  };

  const handleDeleteSystemApps = () => {
    handleDeleteApps(
      selectedSystemApps,
      filteredPackages,
      packagesActions.actions.setPackages,
      dispatch,
      selectedDevice
    );
  };

  useEffect(() => {
    setupSingleAppsSocket(dispatch);
    return () => {
      unSubscribeSocketTopics([
        TOPICS.SYSTEM_PACKAGES,
        TOPICS.APPLICATIONS
      ]);
    };
  }, [dispatch]);


  const filterRows = (rows, searchTerm) => {
    if (!searchTerm) return rows;

    const regex = new RegExp(searchTerm, "i");
    return rows.filter((row) =>
      Object.values(row).some((value) => regex.test(value))
    );
  };

  const filteredApplications = useMemo(() => {
    return filterRows(applications, appSearchTerm);
  }, [applications, appSearchTerm]);

  const filteredPackages = useMemo(() => {
    return filterRows(packages, packageSearchTerm);
  }, [packages, packageSearchTerm]);

  return (
    <div>
      <TotalApps applications={applications} packages={packages} />
      <Grid my={"10px"} container spacing={2}>
        <Grid item xs={12} xl={6} className={shared.tableContainer}>
          <CustomTitle title={"User Apps"} count={filteredApplications?.length} />
          <Card>
            <MyTableToolbar
              placeHolder={"Search For Apps"}
              btn1Txt={false}
              btn2Txt={"Uninstall"}
              btn2Icon={UninstallIcon}
              onSearch={handleAppSearch}
              handleBtn2Click={handleDeleteUserApps}
              onDownload={() => handleDownloadCsv(filteredApplications, appColumns)}
              onPrint={() => handlePrintCsv(filteredApplications, appColumns)}
              isUninstall={false}
            />
            <MyTable
              checkboxSelection={true}
              columns={appColumns}
              rows={filteredApplications}
              handleSelectedRows={handleUserAppsSelection}
            />
          </Card>
        </Grid>
        <Grid item xs={12} xl={6} className={shared.tableContainer}>
          <CustomTitle title={"System Apps"} count={filteredPackages?.length} />
          <Card>
            <MyTableToolbar
              placeHolder={"Search For Apps"}
              btn1Txt={false}
              btn2Txt={false}
              btn2Icon={UninstallIcon}
              onSearch={handlePackageSearch}
              handleBtn2Click={handleDeleteSystemApps}
              onDownload={() => handleDownloadCsv(filteredPackages, userAppsColumns)}
              onPrint={() => handlePrintCsv(filteredPackages, userAppsColumns)}
              isUninstall={false}
            />
            <MyTable
              isMultiLine={true}
              checkboxSelection={true}
              columns={userAppsColumns}
              rows={filteredPackages}
              handleSelectedRows={handleSystemAppsSelection}
            />
          </Card>
        </Grid>
      </Grid>
      <br />
    </div>
  );
};

export default AppsContainer;
