import React, { useEffect, useState } from "react";
import Groups from "./Groups/Groups";
import AccessManagement from "./common/AccessManagment/AccessManagement";
import Users from "./Users/Users";
import Policies from "./Policies/Policies";
import Devices from "./Devices/Devices";
import { useDispatch, useSelector } from "react-redux";
import {
  computeTotalGroupsCount,
  countUserRoles,
} from "components/utils/policy";
import { FETCH_GROUPS } from "../../../redux/types/group.types";
import { getUsersService } from "../../../redux/services/group.service";
import { FETCH_POLICIES } from "../../../redux/types/policy.types";
import { authActions } from "../../../redux/slice/auth.slice";
import PolicyAlerts from "./Policies/PolicyAlerts";
import { FETCH_ALERT_POLICIES } from "../../../redux/types/alertPolicy.types";
import Apps from "./Apps/Apps";
import { GET_MULTI_DASHBOARD_ANALYTICS } from "../../../redux/types/multi.types";
import {
  setupMultiAppsSocket,
  unSubscribeSocketTopics,
} from "../../../redux/saga/socket";
import { TOPICS } from "components/utils/enums";
import { applicationsActions } from "../../../redux/slice/applications.slice";
import { packagesActions } from "../../../redux/slice/packages.slice";

const Management = () => {
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [selectedTab, handleSelectedTab] = useState(0);
  const groups = useSelector((state) => state.group.groups);
  const policies = useSelector((state) => state.policy.policies);
  const totalGroupsCount = computeTotalGroupsCount(groups);
  const currentUser = useSelector(authActions.selectors.getCurrentUser);
  const alertPolicies = useSelector((state) => state.alertPolicy.alertPolicies);

  useEffect(() => {
    dispatch({ type: FETCH_GROUPS });
    dispatch({ type: FETCH_POLICIES });
    dispatch({ type: FETCH_ALERT_POLICIES });

    const fetchUsers = async () => {
      try {
        const users = await getUsersService();
        setUsers(users);
      } catch (error) {
        console.error("Failed to fetch users", error);
      }
    };

    fetchUsers();
  }, [dispatch]);

  const refreshGroups = () => {
    dispatch({ type: FETCH_GROUPS });
  };

  useEffect(() => {
    dispatch({ type: GET_MULTI_DASHBOARD_ANALYTICS });
    setupMultiAppsSocket(dispatch);
    return () => {
      unSubscribeSocketTopics([TOPICS.SYSTEM_PACKAGES, TOPICS.APPLICATIONS]);
    };
  }, [dispatch]);

  const applications = useSelector(applicationsActions.selectors.getMultiApps);
  const packages = useSelector(packagesActions.selectors.getMultiPackages);

  const combineApps = [...applications, ...packages];

  const renderContent = () => {
    switch (selectedTab) {
      case 0:
        return <Groups refreshGroups={refreshGroups} />;
      case 1:
        return (
          <Users
            user={users}
            groups={groups}
            refreshGroups={refreshGroups}
            currentUser={currentUser}
          />
        );
      case 2:
        return (
          <>
            <Policies
              policies={policies}
              groups={groups}
              refreshGroups={refreshGroups}
            />
            <PolicyAlerts alertPolicies={alertPolicies} groups={groups} refreshGroups={refreshGroups} />
          </>
        );
      case 3:
        return <Devices groups={groups} refreshGroups={refreshGroups} />;
      case 4:
        return <Apps groups={groups} refreshGroups={refreshGroups} />;
      default:
        return <Groups />;
    }
  };

  return (
    <div>
      <AccessManagement
        selectedTab={selectedTab}
        handleSelectedTab={handleSelectedTab}
        groupCount={totalGroupsCount}
        userCount={users.length}
        roleCount={countUserRoles(users).length}
        policyCount={policies?.length}
        appsCount={combineApps?.length}
      />
      {renderContent()}
    </div>
  );
};

export default Management;
