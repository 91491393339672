import apiInterceptor from "./Interceptors/ApiInterceptor";

export const fetchAlertPoliciesService = async () => {
  try {
    const response = await apiInterceptor.get("/get-alert-policies");
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const createAlertPolicyService = async (payload) => {
  try {
    const response = await apiInterceptor.post("/create-alert-policy", payload);

    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const assignAlertPolicyToGroupService = async (paylaod) => {
  try {
    const response = await apiInterceptor.post(
      "/groups/assign-alert-policies",
      paylaod
    );
    return response?.data?.policies;
  } catch (error) {
    throw error;
  }
};

export const removeAlertPolicyService = async (policyId) => {
  try {
    const response = await apiInterceptor.delete(`/remove/${policyId}`);
    return response?.data?.policy;
  } catch (error) {
    throw error;
  }
};