import { Box, Typography } from "@mui/material";
import React, { memo } from "react";
import { alertMenuItems } from "../../../static/mock";
import MyCard from "../../shared/Card/Card";
import text from "../../shared/css/text.module.scss";
import DeviceMenu from "./DeviceMenu";
import PieChart from "./PieChart";
import theme from "../../utils/theme";
const TotalAlerts = ({ total }) => {
  const chartData = { a: 9, b: 20, c: 30, d: 23 };
  const graphColors = [
    theme.palette.gray.light,
    theme.palette.primary.main,
    theme.palette.primary.light,
    theme.palette.secondary.main,
  ];
  return (
    <MyCard padding={2}>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Typography className={text.heading2}>Total Alerts</Typography>
        <DeviceMenu
          title={"Device Criticality Levels"}
          menuItems={alertMenuItems}
        />
      </Box>
      <Box mt={3} display={"flex"} justifyContent={"center"}>
        <PieChart
          chartData={chartData}
          width={400}
          height={260}
          graphColors={graphColors}
          totalValue={total}
          radius={120}
          left={"43.5%"}
          showLabels={true}
          totalText={"Total"}
        />
      </Box>
    </MyCard>
  );
};

export default memo(TotalAlerts);
