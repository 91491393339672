import { Grid } from '@mui/material'
import React from 'react'
import GraphTitle from './GraphTitle'
import MyCard from 'components/shared/Card/Card'
import ThreatDetectionChart from './ThreatDetectionChart'

const ThreatDetection = () => {
    return (
        <MyCard height={450}>
            <Grid item xs={12}>
                <GraphTitle title={'Threat Detection Over Time'} />
                <ThreatDetectionChart />
            </Grid>
        </MyCard>
    )
}

export default ThreatDetection
