import React from 'react'

const NetworkIcon = ({ fill = "#FFF", width = "18", height = "18" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M11.4052 10.2492V13.248C11.4052 13.2532 11.4052 13.2585 11.4052 13.2637C11.407 13.4364 11.4541 13.5983 11.5351 13.7382C11.6157 13.8778 11.7321 13.9993 11.8804 14.0871C11.8851 14.09 11.8898 14.0927 11.8946 14.0953L14.5497 15.6283C14.3955 16.1011 14.3122 16.6055 14.3122 17.1294C14.3122 19.8034 16.4832 21.9745 19.1573 21.9745C21.8313 21.9745 24.0024 19.8034 24.0024 17.1294C24.0024 14.4553 21.8313 12.2843 19.1573 12.2843C17.7023 12.2843 16.3963 12.927 15.5079 13.9437L13.3432 12.6939V10.2492C15.5536 9.79976 17.2192 7.84344 17.2192 5.50121C17.2192 2.82712 15.0483 0.656128 12.3742 0.656128C9.7001 0.656128 7.52908 2.82712 7.52908 5.50121C7.52908 7.84344 9.19473 9.79976 11.4052 10.2492ZM5.59105 12.2843C2.91697 12.2843 0.745972 14.4553 0.745972 17.1294C0.745972 19.8034 2.91697 21.9745 5.59105 21.9745C8.26511 21.9745 10.4361 19.8034 10.4361 17.1294C10.4361 14.4553 8.26511 12.2843 5.59105 12.2843ZM19.1573 14.2224C20.7618 14.2224 22.0643 15.525 22.0643 17.1294C22.0643 18.7339 20.7618 20.0364 19.1573 20.0364C17.5529 20.0364 16.2502 18.7339 16.2502 17.1294C16.2502 15.525 17.5529 14.2224 19.1573 14.2224ZM5.59105 14.2224C7.1955 14.2224 8.4981 15.525 8.4981 17.1294C8.4981 18.7339 7.1955 20.0364 5.59105 20.0364C3.98661 20.0364 2.684 18.7339 2.684 17.1294C2.684 15.525 3.98661 14.2224 5.59105 14.2224ZM12.3742 2.59416C13.9786 2.59416 15.2813 3.89677 15.2813 5.50121C15.2813 7.10564 13.9786 8.40825 12.3742 8.40825C10.7697 8.40825 9.4671 7.10564 9.4671 5.50121C9.4671 3.89677 10.7697 2.59416 12.3742 2.59416Z" fill={fill} />
            <path fillRule="evenodd" clipRule="evenodd" d="M11.8898 12.4142L8.53298 14.3522C8.06983 14.6196 7.91088 15.2127 8.17831 15.6758C8.44572 16.139 9.03892 16.298 9.50202 16.0305L12.8588 14.0926C13.3219 13.825 13.4809 13.2319 13.2135 12.7688C12.9461 12.3056 12.3529 12.1468 11.8898 12.4142Z" fill={fill} />
        </svg>

    )
}
export default NetworkIcon
