import CustomTextField from 'components/shared/Forms/CustomTextField';
import React from 'react'
import { object } from 'yup';
import { YupAddUserSchema, formAddUserSchema } from './helper';
import { useForm } from 'react-hook-form';
import { Button } from '@mui/material';
import login from '../Login/login.module.scss'
const AddDeviceForm = ({ addDeviceUser }) => {
    let deviceUserSchema = object(YupAddUserSchema);
    const { control, handleSubmit, formState: { errors, isValid } } = useForm(formAddUserSchema(deviceUserSchema))

    const handleDeviceUserSubmit = (payload) => {
        addDeviceUser(payload)
    };
    return (
        <form onSubmit={handleSubmit(handleDeviceUserSubmit)} >
            <CustomTextField
                control={control}
                errors={errors}
                name={'username'}
                type="text"
                label="Username"
                required={true}
                placeHolder={'Enter your username'}
            />
            <br />
            <CustomTextField
                control={control}
                errors={errors}
                name={'password'}
                type="password"
                label="Password"
                required={true}
                placeHolder={'*******'}
            />
            <br />
            <div className='flex-end'>
                <Button disabled={!isValid} type="submit" variant='contained' className={login.loginBtn} >Create</Button>
            </div>
        </form>
    )
}

export default AddDeviceForm
