import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import Layout from './Layout'

const PrivateLayoutRoutes = ({ auth }) => {

  return (
    auth ?
      <>
        <Layout>
          <Outlet />
        </Layout>
      </>
      : <Navigate to='/login' />
  )
}

export default PrivateLayoutRoutes;