import React from 'react'

const BatteryIcon = ({ fill = "white", width = "19", height = "19" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M2.56205 2.05046H3.55484V0.897076C3.55484 0.4165 3.97117 0 4.45155 0H11.241C11.7214 0 12.1377 0.4165 12.1377 0.897076V2.05046H12.6821C14.0913 2.05046 15.2442 3.20384 15.2442 4.61353V20.4726C15.2442 21.8822 14.0913 23.0356 12.6821 23.0356H2.56205C1.15292 23.0356 0 21.8822 0 20.4726V4.61353C0 3.20384 1.15292 2.05046 2.56205 2.05046ZM1.92153 3.81257H13.3547V21.2735H1.92153V3.81257Z" fill={fill} />
            <path d="M11.6571 5.70264H3.52264V9.29094H11.6571V5.70264Z" fill={fill} />
            <path d="M11.6892 10.7651H3.55475V14.3534H11.6892V10.7651Z" fill={fill} />
            <path d="M11.7536 15.8271H3.61914V19.4155H11.7536V15.8271Z" fill={fill} />
        </svg>
    )
}
export default BatteryIcon
