import { Badge } from '@mui/material'
import React from 'react'

const MyBadge = ({ title , bgColor , txtColor}) => {
  return (
      <Badge badgeContent={title} sx={{
          "& .MuiBadge-badge": {
              backgroundColor: bgColor,
              color: txtColor,
              minWidth : '97px',
              height : '28px',
              padding : 0,
              borderRadius : '100px'
          }
      }} />
  )
}

export default MyBadge
