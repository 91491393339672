import React from "react";
import { Box, Typography, Chip } from "@mui/material";
import styles from "./HighAvailabilityCard.module.scss";

const HighAvailabilityCard = () => {
  return (
    <Box className={styles.container}>
      <Typography variant="h6" className={styles.title}>
        High Availability
      </Typography>
      <Box className={styles.svgRow}>
        <svg
          width="380"
          height="55"
          viewBox="0 0 384 55"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="27.5"
            cy="27.5"
            r="26.5"
            fill="white"
            stroke="#00A307"
            stroke-width="2"
          />
          <circle
            cx="356.5"
            cy="27.5"
            r="26.5"
            fill="white"
            stroke="#00A307"
            stroke-width="2"
          />
          <path
            d="M40.325 34.875H30.0962C30.065 35.7262 29.4756 36.25 28.715 36.25H26.15C25.3615 36.25 24.757 35.4993 24.7675 34.875H14.675C14.3038 34.875 14 35.1844 14 35.5625V36.25C14 37.7625 15.215 39 16.7 39H38.3C39.785 39 41 37.7625 41 36.25V35.5625C41 35.1844 40.6963 34.875 40.325 34.875ZM38.3 19.0625C38.3 17.9281 37.3888 17 36.275 17H18.725C17.6113 17 16.7 17.9281 16.7 19.0625V33.5H38.3V19.0625ZM35.6 30.75H19.4V19.75H35.6V30.75Z"
            fill="#00A307"
          />
          <path
            d="M369.325 34.875H359.096C359.065 35.7262 358.476 36.25 357.715 36.25H355.15C354.362 36.25 353.757 35.4993 353.768 34.875H343.675C343.304 34.875 343 35.1844 343 35.5625V36.25C343 37.7625 344.215 39 345.7 39H367.3C368.785 39 370 37.7625 370 36.25V35.5625C370 35.1844 369.696 34.875 369.325 34.875ZM367.3 19.0625C367.3 17.9281 366.389 17 365.275 17H347.725C346.611 17 345.7 17.9281 345.7 19.0625V33.5H367.3V19.0625ZM364.6 30.75H348.4V19.75H364.6V30.75Z"
            fill="#00A307"
          />
          <line
            x1="55"
            y1="27"
            x2="329"
            y2="27"
            stroke="#00A307"
            stroke-width="2"
            stroke-dasharray="4 4"
          />
        </svg>
      </Box>
      <Box className={styles.content}>
        <Box className={styles.leftSection}>
          <Typography variant="body1" className={styles.deviceName}>
            BDATA-645BT
          </Typography>
          <Typography variant="body2" className={styles.detail}>
            Running <span className={styles.span}>7.5 (7)</span>
          </Typography>
          <Typography variant="body2" className={styles.detail}>
            Laptop Mode
          </Typography>
          <Chip
            label="Active"
            color="success"
            size="small"
            className={styles.statusChip}
          />
        </Box>
        <Box className={styles.middleSection}>
          <Box className={styles.statusWrapper}>
            <Typography variant="body2" className={styles.status}>
              <span
                className={styles.dot}
                style={{ backgroundColor: "#00A307" }}
              ></span>
              Standby Ready
            </Typography>
            <Typography variant="body2" className={styles.status}>
              <span
                className={styles.dot}
                style={{ backgroundColor: "#00A307" }}
              ></span>
              Config Synced
            </Typography>
          </Box>
        </Box>
        <Box className={styles.rightSection}>
          <Typography variant="body2" className={styles.description}>
            This Laptop Node Standby Secondary
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default HighAvailabilityCard;
