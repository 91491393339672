import React from 'react'

const WarningIcon = ({ fill = "#FFF", width = "22", height = "22" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M15.257 1.81252L23.4923 15.5624C24.1692 16.6928 24.1692 18.0571 23.4923 19.1875C22.8155 20.3178 21.5897 21 20.2354 21H3.76461C2.41027 21 1.18453 20.3178 0.507655 19.1875C-0.169218 18.0571 -0.169218 16.6928 0.507655 15.5624L8.74304 1.81252C9.41991 0.682168 10.6457 0 12 0C13.3544 0 14.5801 0.682168 15.257 1.81252ZM12 13.8285C11.4481 13.8285 10.9947 13.4071 10.9747 12.8751L10.7157 5.95269C10.7054 5.67446 10.802 5.43116 11.0025 5.23036C11.203 5.02955 11.4517 4.92827 11.741 4.92827C12.1075 4.92827 11.8931 4.92827 12.2596 4.92827C12.5489 4.92827 12.7976 5.03013 12.9981 5.23036C13.1986 5.43116 13.2952 5.67388 13.2849 5.95269L13.0259 12.8751C13.0053 13.4071 12.5519 13.8285 12 13.8285ZM12 15.297C12.695 15.297 13.2583 15.8401 13.2583 16.51C13.2583 17.18 12.695 17.723 12 17.723C11.305 17.723 10.7417 17.18 10.7417 16.51C10.7417 15.8401 11.305 15.297 12 15.297Z" fill={fill} />
        </svg>

    )
}

export default WarningIcon
