import { useTheme } from '@emotion/react';
import Typography from '@mui/material/Typography';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { memo, useEffect, useRef } from 'react';
import MyCard from '../../shared/Card/Card';
import text from '../../shared/css/text.module.scss';
import styles from './device.module.scss';

const MapboxMap = ({ markers, center, buttonTxt }) => {
    const mapContainerRef = useRef(null);
    const theme = useTheme();

    useEffect(() => {
        mapboxgl.accessToken = process.env.REACT_APP_MAP_BOX_TOKEN;

        const map = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: center ? center.split(',').map(Number).reverse() : [60, 30],
            zoom: 10,
        });

        const addMarkers = () => {
            if (markers?.length) {
                markers.forEach(marker => {
                    const el = document.createElement('div');
                    el.innerHTML = marker.html;

                    new mapboxgl.Marker(el)
                        .setLngLat(marker.coordinates)
                        .addTo(map);
                });
            }
        };
        addMarkers();
        return () => {
            map.remove();
        };
    }, [theme.palette.error.main, theme.palette.success.main, theme.palette.warning.main, markers, center]); // Include center in dependencies

    return (
        <MyCard padding={2}>
            <div className={styles.cardTitle}>
                <Typography className={text.heading2}>Live Devices Location</Typography>
                <button className={text.button1}>
                    {buttonTxt}
                </button>
            </div>
            <div className={styles.map} ref={mapContainerRef} />
        </MyCard>
    );
}

export default memo(MapboxMap);