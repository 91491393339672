import React from 'react'
import LoginContainer from '../components/modules/Login/Login'

const Login = () => {
  return (
    <div>
          <LoginContainer/> 
    </div>
  )
}

export default Login
