import React from 'react'

const StatusIcon = ({ fill = "white", width = "17", height = "18" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.83217 11.4833V16.049H1.50269V11.4833H4.83217ZM5.83396 9.71924H0.500895C0.220983 9.71924 0 9.97865 0 10.3072V23.9179C0 24.2465 0.220983 24.5059 0.500895 24.5059H5.83396C6.11387 24.5059 6.33485 24.2465 6.33485 23.9179V10.3072C6.32012 9.97865 6.09914 9.71924 5.83396 9.71924Z" fill={fill} />
            <path d="M12.6701 7.93785V17.1038H9.34058V7.93785H12.6701ZM13.6718 6.17383H8.33879C8.05887 6.17383 7.83789 6.43324 7.83789 6.76183V23.9178C7.83789 24.2464 8.05887 24.5058 8.33879 24.5058H13.6718C13.9518 24.5058 14.1727 24.2464 14.1727 23.9178V6.76183C14.1727 6.45054 13.937 6.17383 13.6718 6.17383Z" fill={fill} />
            <path d="M20.5957 1.76402V13.1091H17.2662V1.76402H20.5957ZM21.5974 0H16.2644C15.9845 0 15.7635 0.259415 15.7635 0.588006V23.918C15.7635 24.2466 15.9845 24.506 16.2644 24.506H21.5974C21.8774 24.506 22.0983 24.2466 22.0983 23.918V0.588006C22.0983 0.259415 21.8774 0 21.5974 0Z" fill={fill} />
        </svg>
    )
}
export default StatusIcon
