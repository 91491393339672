import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import * as React from 'react';
import ArrowDownIcon from '../../../static/svgs/ArrowDown';
import text from '../css/text.module.scss';
import tables from './table.module.scss';
export default function DrawerAccordion({ip}) {
    return (
        <>
            <Accordion defaultExpanded className={tables.accordion} >
                <AccordionSummary
                    className={tables.accordionSummary}
                    expandIcon={<ArrowDownIcon />}
                >
                    <p className={text.bold1}>Netwrok Interfaces</p>
                </AccordionSummary>
                <AccordionDetails className={tables.accordionDetails}>
                    <p className={text.info2}>
                        IP : {ip}
                    </p>
                </AccordionDetails>
            </Accordion>
            <Accordion className={tables.accordion} >
                <AccordionSummary
                    className={tables.accordionSummary}
                    expandIcon={<ArrowDownIcon />}
                >
                    <p className={text.bold1}>Netwrok Ports</p>
                </AccordionSummary>
                <AccordionDetails className={tables.accordionDetails}>
                    <p className={text.info2}>
                        Port : 336 (UDP)
                    </p>
                </AccordionDetails>
            </Accordion>
             
        </>
    );
}