import { Box, Typography } from '@mui/material';
import { RAM_CPU_CHART_OPTION } from 'components/utils/networks';
import { useState } from 'react';
import Chart from 'react-apexcharts';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import styles from './RamAndCpuCard.module.scss';

const RamAndCpuCard = () => {
  const [value, setValue] = useState([new Date('2022-04-17'), new Date('2022-04-21')]);

  return (
    <Box className={styles.container}>
      <Box className={styles.header}>
        <Typography variant="h6" className={styles.title}>
          RAM And CPU
        </Typography>
        <Box className={styles.dateSelector}>
          <DateRangePicker
            value={value}
            onChange={setValue}
            size="sm"
            placeholder="Start - End Date"
            className={styles.dateRangePicker}
          />
        </Box>
      </Box>
      <Box className={styles.chartContainer}>
        <Chart
          options={RAM_CPU_CHART_OPTION}
          series={RAM_CPU_CHART_OPTION.series}
          type="bar"
          height={200}
        />
        <Box className={styles.legend}>
          <Box className={styles.legendItem}>
            <Typography variant="body2" className={styles.ramLabel}>RAM</Typography>
            <Typography variant="body2" className={styles.ramValue}>5.0% <Box className={styles.dot}></Box></Typography>
          </Box>
          <Box className={styles.legendItem}>
            <Typography variant="body2" className={styles.cpuLabel}>CPU</Typography>
            <Typography variant="body2" className={styles.cpuValue}>3.0% <Box className={styles.ydot}></Box></Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RamAndCpuCard;
