import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
    applications: [],
    combineApps: [],
    multiApps: [],
    multiCombinedApps: []
}

const name = 'applications'
const parentSelector = state => state?.[name]

const applicationsSlice = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        resetApplications: () => {
            return initialState;
        },
        setApplications: (state, action) => {
            return {
                ...state,
                applications: action.payload,
            };
        },
        setCombineApps: (state, action) => {
            return {
                ...state,
                combineApps: action.payload,
            };
        },
        setMultiApps: (state, action) => {
            return {
                ...state,
                multiApps: action.payload,
            };
        },
        setMultiCombinedApps: (state, action) => {
            return {
                ...state,
                multiCombinedApps: action.payload,
            };
        },

    },
});


const getApplications = createSelector(parentSelector, state => {
    return state?.applications
})
const getCombineApps = createSelector(parentSelector, state => {
    return state?.combineApps
})

const getMultiApps = createSelector(parentSelector, state => {
    return state?.multiApps
})
const getMultiCombinedApps = createSelector(parentSelector, state => {
    return state?.multiCombinedApps
})

const actions = {
    ...applicationsSlice.actions
}

const selectors = {
    getApplications,
    getCombineApps,
    getMultiApps,
    getMultiCombinedApps
}

export const applicationsActions = { actions, selectors }


export default applicationsSlice;