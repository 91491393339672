import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Menu, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import React, { useState } from 'react';
import styles from './device.module.scss';
const DeviceMenu = ({ title , menuItems }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };    

    return (
        <div>
            <Typography
                onClick={handleClick}
                className={styles.deviceMenu}
            >
                {title}
                {open ? < KeyboardArrowUp /> : <KeyboardArrowDown />}
            </Typography>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}

            >
                {menuItems?.map((item, idx) => (
                    <MenuItem key={idx} onClick={handleClose}>{item?.label}</MenuItem>
                ))}

            </Menu>
        </div>
    );
};

export default DeviceMenu;
