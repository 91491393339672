import "@fontsource/poppins";
import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { Toaster } from "react-hot-toast";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import MyRouter from "./components/routes/MyRouter";
import theme from "./components/utils/theme";
import { persistor, store } from "./redux/store";
import AppBoot from "./components/shared/AppBoot";
import CssBaseline from '@mui/material/CssBaseline';

const App = () => {

  return (
    <Provider store={store}>
      <PersistGate  persistor={persistor}>
        <ThemeProvider theme={theme} >
        <CssBaseline />
          <AppBoot/>
          <MyRouter />
          <Toaster/>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  )
}

export default App
