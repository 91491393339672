import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, TextField } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';

const TwoFADialog = ({ open, onClose, onVerify }) => {
  const { control, handleSubmit, watch } = useForm();
  const twoFACode = watch("twoFACode", "");

  const onSubmit = (data) => {
    onVerify(data.twoFACode);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>2FA Verification</DialogTitle>
      <DialogContent>
        <Typography>Please verify your identity through 2FA to proceed.</Typography>
        <Controller
          name="twoFACode"
          control={control}
          defaultValue=""
          rules={{ required: true, maxLength: 4, pattern: /^[0-9]{4}$/ }}
          render={({ field }) => (
            <TextField
              {...field}
              label="Enter Code"
              fullWidth
              margin="normal"
              inputProps={{ maxLength: 4 }}
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button onClick={handleSubmit(onSubmit)} color="primary" disabled={twoFACode.length !== 4}>Verify</Button>
      </DialogActions>
    </Dialog>
  );
};

export default TwoFADialog;
