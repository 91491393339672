import MyCard from 'components/shared/Card/Card';
import GraphTitle from './GraphTitle';
import * as d3 from 'd3';
import { useEffect, useRef, useState } from 'react';
import { Box, Grid } from '@mui/material';
import theme from 'components/utils/theme';
import CustomDialog from 'components/shared/Dialog/CustomDialog';

const VulnerabilityScanning = () => {
    const ref = useRef();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const chartData = [
        { name: 'Buffer Overflow', value: 80 },
        { name: 'SQL Injection', value: 70 },
        { name: 'Authentication Bypass', value: 60 },
        { name: 'Data Exposure', value: 50 },
        { name: 'Privilege Escalation', value: 40 }
    ];
    const width = 320;
    const height = 300;
    const graphColors = [theme.palette.error.main];
    const radius = Math.min(width, height) / 2 - 50; // Adjusted outer radius to prevent border clipping
    const levels = 2;

    useEffect(() => {
        const svgElement = d3.select(ref.current);

        // Clear previous SVG content
        svgElement.selectAll("*").remove();

        const svg = svgElement
            .attr("width", width)
            .attr("height", height)
            .append("g")
            .attr("transform", `translate(${width / 2},${height / 2})`);

        const allAxis = chartData.map((d) => d.name);
        const total = allAxis.length;
        const angleSlice = (Math.PI * 2) / total; // Angle between each axis
        const rScale = d3.scaleLinear().range([0, radius]).domain([0, 100]);

        // Create circular grid
        for (let i = 0; i < levels; i++) {
            svg
                .append("circle")
                .attr("r", (radius / levels) * (i + 1))
                .attr("cx", 0)
                .attr("cy", 0)
                .style("fill", "none")
                .style("stroke", "gray")
                .style("stroke-opacity", 0.5);
        }

        // Draw axis lines
        svg.selectAll(".axis")
            .data(allAxis)
            .enter()
            .append("line")
            .attr("x1", 0)
            .attr("y1", 0)
            .attr("x2", (d, i) => rScale(100) * Math.cos(angleSlice * i - Math.PI / 2))
            .attr("y2", (d, i) => rScale(100) * Math.sin(angleSlice * i - Math.PI / 2))
            .style("stroke", "gray")
            .style("stroke-width", "1px");

        // Add axis labels
        svg.selectAll(".axisLabel")
            .data(allAxis)
            .enter()
            .append("text")
            .attr("x", (d, i) => (rScale(100) + 10) * Math.cos(angleSlice * i - Math.PI / 2))
            .attr("y", (d, i) => (rScale(100) + 10) * Math.sin(angleSlice * i - Math.PI / 2))
            .text((d) => d)
            .style("text-anchor", "middle")
            .attr("font-size", "10px");

        // Radar chart data
        const radarLine = d3.lineRadial()
            .radius((d) => rScale(d.value))
            .angle((d, i) => i * angleSlice)
            .curve(d3.curveLinearClosed); // Ensures the radar path is closed

        const radarData = chartData.map((d, i) => ({
            axis: allAxis[i],
            value: d.value
        }));

        // Draw radar area
        svg.append("path")
            .datum(radarData)
            .attr("d", radarLine)
            .style("fill", graphColors[0])
            .style("fill-opacity", 0.2)  // Lower opacity for inner area color
            .style("stroke", graphColors[0])
            .style("stroke-width", 2);

        // Draw radar points
        svg.selectAll(".radarPoint")
            .data(radarData)
            .enter()
            .append("circle")
            .attr("cx", (d, i) => rScale(d.value) * Math.cos(angleSlice * i - Math.PI / 2))
            .attr("cy", (d, i) => rScale(d.value) * Math.sin(angleSlice * i - Math.PI / 2))
            .attr("r", 3) // Reduce marker size
            .style("fill", graphColors[0]);

    }, [chartData, graphColors, height, radius, width, levels]);


    return (
        <>
            <MyCard>
                <GraphTitle title={'AI-Driven Vulnerability Scanning'} />
                <Box display={'flex'} justifyContent={'center'}>
                    <div style={{ width: width }}>
                        <svg ref={ref}></svg>
                    </div>
                </Box>
            </MyCard>
        </>
    );
};

export default VulnerabilityScanning;
