import { yupResolver } from "@hookform/resolvers/yup";
import { Badge, Button, Switch, styled } from "@mui/material";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import DeleteIcon2 from "static/svgs/Delete2.js";
import { string } from "yup";
import { executeCommand, sendToSocket } from "../../../redux/saga/socket.js";
import { store } from "../../../redux/store.js";
import MyBadge from "../../shared/MyBadge.jsx";
import { getStatusBadge } from "../../shared/Tables/helper.js";
import tables from "../../shared/Tables/table.module.scss";
import { commandTypes, formatFileSize } from "../../utils/index.js";
import theme from "../../utils/theme";
import { getAccessBadge } from "../Management/Policies/helper.js";

export const getAppDetailItems = (total, user, system) => {
  return [
    {
      title: "Installed Apps",
      value: total,
      color: theme.palette.success.main,
    },
    { title: "User Apps", value: user, color: theme.palette.error.main },
    { title: "System Apps", value: system, color: theme.palette.warning.light },
  ];
};

export const openRemoteConsole = (id) => {
  const url = `/console?id=${encodeURIComponent(id)}`;
  window.open(url, "_blank", "rel=noopener noreferrer");
};

export const complainceDetailItems = [
  {
    title: "Pass & Fail Device",
    value: "40",
    color: theme.palette.success.main,
  },
  { title: "User apps", value: "05", color: theme.palette.error.main },
  { title: "Total roles", value: "54", color: theme.palette.warning.light },
];

export const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 49,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(23px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 0,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.gray.light,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.gray.main,
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export const networkColumns = [
  {
    field: "name",
    headerName: "Name",
    minWidth: 100,
    flex: 1,
  },
  {
    field: "mac",
    headerName: "MAC",
    minWidth: 150,
    flex: 1,
  },
  {
    field: "state",
    headerName: "State",
    minWidth: 100,
    flex: 1,
  },
  {
    field: "mtu",
    headerName: "MTU",
    minWidth: 100,
    flex: 1,
  },
  {
    field: "type",
    headerName: "Type",
    minWidth: 100,
    flex: 1,
    renderCell: (params) => (
      <Button
        variant="contained"
        onClick={(event) => {
          event.stopPropagation();
        }}
        className={tables.actionBtn}
        size="small"
      >
        {params.row.type}
      </Button>
    ),
  },
];

export const portColumns = [
  {
    field: "localPort",
    headerName: "Local Port",
    minWidth: 120,
    flex: 1,
  },
  {
    field: "localIp",
    headerName: "Local IP",
    minWidth: 150,
    flex: 1,
  },
  {
    field: "state",
    headerName: "State",
    minWidth: 100,
    flex: 1,
  },
  {
    field: "status",
    headerName: "Status",
    minWidth: 100,
    flex: 1,
    renderCell: (params) => getStatusBadge(params?.row?.state),
  },
  {
    field: "protocol",
    headerName: "Protocol",
    minWidth: 100,
    flex: 1,
    renderCell: (params) => (
      <Badge
        badgeContent={params?.row?.protocol}
        sx={{
          "& .MuiBadge-badge": {
            backgroundColor: theme.palette.white.dark,
            color: theme.palette.primary.main,
          },
        }}
      />
    ),
  },
  {
    field: "action",
    headerName: "Action",
    minWidth: 100,
    flex: 1,
    renderCell: (params) => (
      <IOSSwitch
        onClick={() => handlePortAction(params?.row?.localPort)}
        defaultChecked
        inputProps={{ "aria-label": "ant design" }}
      />
    ),
  },
];

const handlePortAction = (port) => {
  const state = store.getState();

  executeCommand(
    port,
    commandTypes.PORT,
    state?.devices.selectedDevice?.uid,
    state?.devices.selectedDevice?.systemDetails?.os
  );
};

export const packageColumns = [
  {
    field: "Name",
    headerName: "File Name",
    minWidth: 120,
    flex: 1,
  },
  // {
  //   field: "InstallDate",
  //   headerName: "Install Date",
  //   minWidth: 100,
  //   flex: 0.5,
  //   renderCell: (params) =>
  //     params?.row?.InstallDate
  //       ? dayjs(params?.row?.InstallDate).format("DD-MM-YYYY")
  //       : null
  // },
  {
    field: "Version",
    headerName: "Version",
    minWidth: 70,
    flex: 0.5,
  },
  {
    field: "InstallLocation",
    headerName: "InstallLocation",
    minWidth: 120,
    flex: 1,
  },
  {
    field: "Publisher",
    headerName: "Publisher",
    minWidth: 120,
    flex: 1,
  },
];

export const processesColumns = [
  {
    field: "name",
    headerName: "Name",
    minWidth: 100,
    flex: 1,
  },
  {
    field: "user",
    headerName: "User",
    minWidth: 100,
    flex: 1,
  },
  {
    field: "vmsize",
    headerName: "Size",
    minWidth: 100,
    flex: 1,
    renderCell: (params) => formatFileSize(params.row.vmsize),
  },
  {
    field: "pid",
    headerName: "PID",
    minWidth: 100,

    flex: 1,
  },
  {
    field: "parent_id",
    headerName: "Parent PID",
    flex: 1,
    minWidth: 100,
  },
  {
    field: "size",
    headerName: "CPU",
    flex: 1,
    minWidth: 100,
    renderCell: (params) => {
      return getCpuUsageBadge(handleCpuUsage(params.row.cpu_usage));
    },
  },
  {
    field: "memory_percent",
    headerName: "Memory",
    flex: 1,
    minWidth: 100,
    renderCell: (params) => `${params.row.memory_percent?.toFixed(3)}%`,
  },

  {
    field: "args",
    headerName: "Args",
    minWidth: 100,
    renderCell: (params) => (
      <Badge
        max={100000}
        variant="standard"
        showZero
        badgeContent={"args"}
        sx={{
          "& .MuiBadge-badge": {
            backgroundColor: theme.palette.white.dark,
            color: theme.palette.primary.main,
          },
        }}
      />
    ),
    flex: 1,
  },
];

const handleCpuUsage = (cpuUsage) => {
  if (cpuUsage > 100) {
    return (cpuUsage / 10)?.toFixed(2);
  } else {
    return cpuUsage?.toFixed(2);
  }
};

export const getCpuUsageBadge = (usage) => {
  if (usage > 80) {
    return (
      <MyBadge
        title={usage + "%"}
        bgColor={theme.palette.error.main}
        txtColor={theme.palette.white.main}
      />
    );
  } else if (usage > 60) {
    return (
      <MyBadge
        title={usage + "%"}
        bgColor={theme.palette.warning.main}
        txtColor={theme.palette.text.primary}
      />
    );
  } else if (usage > 40) {
    return (
      <MyBadge
        title={usage + "%"}
        bgColor={theme.palette.info.main}
        txtColor={theme.palette.white.main}
      />
    );
  } else if (usage > 20) {
    return (
      <MyBadge
        title={usage + "%"}
        bgColor={theme.palette.success.main}
        txtColor={theme.palette.white.main}
      />
    );
  } else {
    return (
      <MyBadge
        title={0.0 + "%"}
        bgColor={theme.palette.gray.main}
        txtColor={theme.palette.text.primary}
      />
    );
  }
};

export const alertColumns = [
  { field: "type", headerName: "Type", width: 150, flex: 0.5 },
  {
    field: "Date",
    headerName: "Date",
    flex: 0.8,
    renderCell: (params) => dayjs(params?.row?.timestamp).format("MMMM D, YYYY"),
  },
  {
    field: "Time",
    headerName: "Time",
    flex: 0.5,
    renderCell: (params) => dayjs(params?.row?.timestamp).format("h:mm:ss A"),
  },
  {
    field: "size",
    headerName: "Alert Level",
    flex: 0.5,
    renderCell: (params) => {
      return getAccessBadge(params.row.level);
    },
  },
  { field: "message", headerName: "Description", width: 150, flex: 2 },
];

function formatTime(seconds) {
  if (seconds === 0) {
    return "No Age"; // You can customize this message as needed
  }
  const days = Math.floor(seconds / (3600 * 24));
  const hours = Math.floor((seconds % (3600 * 24)) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;

  let timeString = "";
  if (days > 0) timeString += `${days}d `;
  if (hours > 0) timeString += `${hours}h `;
  if (minutes > 0) timeString += `${minutes}m `;
  if (secs > 0) timeString += `${secs}s`;

  return timeString.trim();
}
export const getDeviceUserColumns = (currentDevice, deletePermission) => {
  return [
    { field: "name", headerName: "Name", width: 120, flex: 1 },
    {
      field: "password_age",
      headerName: "Password Age",
      width: 70,
      flex: 0.7,
      renderCell: (params) => formatTime(params?.row?.password_age),
    },
    { field: "flags", headerName: "Flags", width: 100, flex: 0.4 },
    { field: "comment", headerName: "Comment", width: 100, flex: 1.3 },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      flex: 1,
      renderCell: (params) => (
        <Button
          variant="contained"
          endIcon={<DeleteIcon2 className={tables.icon} />}
          onClick={() =>
            handleDeleteClick(
              params?.row?.name,
              currentDevice,
              deletePermission
            )
          }
          className={tables.deleteBtn}
          size="small"
        >
          Delete
        </Button>
      ),
    },
  ];
};

const handleDeleteClick = (username, currentDevice, deletePermission) => {
  const payload = {
    os: currentDevice?.systemDetails?.os,
    uid: currentDevice?.uid,
    username,
  };
  if (deletePermission) {
    sendToSocket("DELETE_DEVICE_USER", payload);
  } else {
    toast.error("Permissions not allowed");
  }
};


export const YupAddUserSchema = {
  username: string().required(),
  password: string().required(),
};

export const formAddUserSchema = (addUserSchema) => {
  return {
    resolver: yupResolver(addUserSchema),
    mode: "onChange",
    defaultValues: {
      username: "",
      password: "",
    },
  };
};
