import React from "react";
import { MapContainer, TileLayer, CircleMarker, Popup } from "react-leaflet";
import { Card, Typography, Box, Link } from "@mui/material";
import "leaflet/dist/leaflet.css";
import styles from "./MapComponent.module.scss";

const MapComponent = () => {
  const position = [51.505, -0.09];

  return (
    <Box className={styles.card}>
      <Box className={styles.header}>
        <Typography variant="h6" className={styles.title}>
          Map
        </Typography>
        <a href="#" className={styles.viewList}>
          View Large
        </a>
      </Box>
      <MapContainer
        center={position}
        zoom={1}
        scrollWheelZoom={false}
        className={styles.map}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <CircleMarker center={position} color="red" radius={0}>
          <Popup>
            A pretty CSS3 popup. <br /> Easily customizable.
          </Popup>
        </CircleMarker>
      </MapContainer>
      <Box className={styles.legend}>
        <Box className={styles.item}>
          <Typography
            variant="body2"
            className={styles.legendItem}
            style={{ color: "#00A307" }}
          >
            0 - 10%{" "}
          </Typography>
          <Box
            className={styles.color}
            style={{ backgroundColor: "#00A307" }}
          ></Box>
        </Box>
        <Box className={styles.item}>
          <Typography
            variant="body2"
            className={styles.legendItem}
            style={{ color: "#FF0000" }}
          >
            10 - 26
          </Typography>
          <Box
            className={styles.color}
            style={{ backgroundColor: "#FF0000" }}
          ></Box>
        </Box>
        <Box className={styles.item}>
          <Typography
            variant="body2"
            className={styles.legendItem}
            style={{ color: "#FFD600" }}
          >
            51 - 72
          </Typography>
          <Box
            className={styles.color}
            style={{ backgroundColor: "#FFD600" }}
          ></Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MapComponent;