import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
    deviceUsers: []
}

const name = 'deviceUsers'
const parentSelector = state => state?.[name]

const deviceUsersSlice = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        resetdeviceUsers: () => {
            return initialState;
        },
        setdeviceUsers: (state, action) => {
            return {
                ...state,
                deviceUsers: action.payload,
            };
        },
    },
});


const getdeviceUsers = createSelector(parentSelector, state => {
    return state?.deviceUsers
})


const actions = {
    ...deviceUsersSlice.actions
}

const selectors = {
    getdeviceUsers
}

export const deviceUsersActions = { actions, selectors }


export default deviceUsersSlice;