import React from 'react'

const PrintIcon = ({ fill = "#030229", width = "22", height = "22" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.6607 3.51245H15.9319L14.9602 0.482961C14.8698 0.197575 14.576 0 14.2596 0H7.20891C6.89253 0 6.59875 0.197575 6.50836 0.504914L5.53662 3.51245H1.80788C0.813544 3.51245 0 4.30275 0 5.26867V11.6569C0 12.6229 0.813544 13.4132 1.80788 13.4132H2.37284L0.903938 17.9793C0.836143 18.1989 0.88134 18.4184 1.01693 18.616C1.15252 18.7916 1.35591 18.8794 1.58189 18.8794H19.864C20.09 18.8794 20.316 18.7696 20.4516 18.594C20.5872 18.4184 20.6324 18.1769 20.5646 17.9574L19.0957 13.3912H19.6607C20.655 13.3912 21.4685 12.6009 21.4685 11.635V5.26867C21.4685 4.30275 20.655 3.51245 19.6607 3.51245ZM7.75127 1.40498H13.7399L14.4178 3.51245H7.05072L7.75127 1.40498ZM2.57622 17.4744L4.97166 10.0105H16.4743L18.8697 17.4744H2.57622ZM20.0222 11.6569C20.0222 11.8326 19.864 11.9862 19.6833 11.9862H18.6437L17.7172 9.08845C17.6268 8.80307 17.333 8.58354 17.0166 8.58354H4.4519C4.13552 8.58354 3.84174 8.78111 3.75134 9.08845L2.82481 11.9862H1.80788C1.62709 11.9862 1.4689 11.8326 1.4689 11.6569V5.26867C1.4689 5.09305 1.62709 4.93938 1.80788 4.93938H19.6833C19.864 4.93938 20.0222 5.09305 20.0222 5.26867V11.6569Z" fill={fill} />
            <path d="M18.011 7.83713C18.5477 7.83713 18.9827 7.4145 18.9827 6.89316C18.9827 6.37182 18.5477 5.94919 18.011 5.94919C17.4743 5.94919 17.0392 6.37182 17.0392 6.89316C17.0392 7.4145 17.4743 7.83713 18.011 7.83713Z" fill={fill} />
            <path d="M7.3897 13.04H14.0788C14.4856 13.04 14.802 12.7326 14.802 12.3375C14.802 11.9423 14.4856 11.635 14.0788 11.635H7.3897C6.98293 11.635 6.66655 11.9423 6.66655 12.3375C6.66655 12.7326 6.98293 13.04 7.3897 13.04Z" fill={fill} />
            <path d="M15.028 14.4449H6.44057C6.0338 14.4449 5.71742 14.7523 5.71742 15.1474C5.71742 15.5426 6.0338 15.8499 6.44057 15.8499H15.028C15.4348 15.8499 15.7511 15.5426 15.7511 15.1474C15.7511 14.7742 15.4348 14.4449 15.028 14.4449Z" fill={fill} />
        </svg>



    )
}
export default PrintIcon
