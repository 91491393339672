import React from "react";
import Management from "components/modules/Management/Management";

const AccessManagement = () => {
  return (
    <div>
      <Management />
    </div>
  );
};

export default AccessManagement;
