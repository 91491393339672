import React from 'react';
import text from '../../shared/css/text.module.scss';
import styles from './apps.module.scss';

const AppCard = ({ label, value, icon, color }) => {
  return (
    <div style={{ backgroundColor: color }} className={styles.appCard}>
      <div className={styles.left}>
        <p className={text.heading1} style={{ color: color }}>{value}</p>
        <p className={text.heading4}>{label}</p>
      </div>
      <div className={styles.right}>
        {icon}
      </div>
    </div>
  )
}

export default AppCard
