export const findDeviceById = (devices, id) => {
    for (const device of devices) {
        if (device?._id === id) {
            return device;
        }
        for (const child of device?.childDevices) {
            if (child?._id === id) {
                return child;
            }
        }
    }
    return null;
}