import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
    userRoles: {},
    highRiskDevices: []
}

const name = 'analytics'
const parentSelector = state => state?.[name]

const analyticsSlice = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        resetanalytics: () => {
            return initialState;
        },
        setUserRoles: (state, action) => {
            return {
                ...state,
                userRoles: action.payload,
            };
        },
        setHighRiskDevices: (state, action) => {
            return {
                ...state,
                highRiskDevices: action.payload,
            };
        },

    },
});

const getUserRoles = createSelector(parentSelector, state => {
    return state?.userRoles
});

const getHighRiskDevices = createSelector(parentSelector, state => {
    return state?.highRiskDevices
});


const actions = {
    ...analyticsSlice.actions
}

const selectors = {
    getUserRoles,
    getHighRiskDevices
}

export const analyticsActions = { actions, selectors }

export default analyticsSlice;