import { Box, Grid } from "@mui/material";
import CustomNavTabs from "components/shared/CustomNavTabs";
import { NavItems } from "components/utils/networks";
import RenderNetwork from "components/utils/RenderNetwork";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { uiActions } from "../redux/slice/ui.slice";

const Networks = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const dispatch = useDispatch()
  const handleSelectedTab = (value) => {
    setSelectedTab(value);
  };

  useEffect(() => {
    dispatch(uiActions.actions.setSelectedNav("Networks"));

  }, [dispatch])

  return (
    <Box sx={{ backgroundColor: "#f4f4f9" }}>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{ backgroundColor: "white", borderRadius: 2 }}
      >
        <Grid item sx={{ flexGrow: 1 }}>
          <CustomNavTabs
            selectedTab={selectedTab}
            handleSelectedTab={handleSelectedTab}
            navitems={NavItems}
          />
        </Grid>
      </Grid>
      <Box>{RenderNetwork(selectedTab)}</Box>
    </Box>
  );
};

export default Networks;
