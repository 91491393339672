import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
    ports: [],
    multiPorts: []
}

const name = 'ports'
const parentSelector = state => state?.[name]

const portsSlice = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        resetPorts: () => {
            return initialState;
        },
        setPorts: (state, action) => {
            return {
                ...state,
                ports: action.payload,
            };
        },
        setMultiPorts: (state, action) => {
            return {
                ...state,
                multiPorts: action.payload,
            };
        },
    },
});


const getPorts = createSelector(parentSelector, state => {
    return state?.ports
})

const getMultiPorts = createSelector(parentSelector, state => {
    return state?.multiPorts
})

const actions = {
    ...portsSlice.actions
}

const selectors = {
    getPorts,
    getMultiPorts
}

export const portsActions = { actions, selectors }


export default portsSlice;