import React, { memo } from 'react';
import ColoredIcon from '../../shared/ColoredIcon';
import text from '../../shared/css/text.module.scss';
import theme from '../../utils/theme';
import styles from './singleDashboard.module.scss';
import { Skeleton } from '@mui/material';
const SystemInfoCard = ({ icon, title, value, symbol }) => {
    return (
        <div className={styles.infoCard}>

            <ColoredIcon icon={icon} bgColor={theme.palette.primary.main} m={0} width='41px' height='41px' />
            <div className={styles.right}>
                <p className={text.heading4}>{title}</p>
                <div className={`${styles.systemInfoBadge} ${text.info3}`}>
                    {value ? (value + symbol) :
                        <Skeleton variant="rounded" />
                    }
                </div>
            </div>
        </div>
    )
}

export default memo(SystemInfoCard)
