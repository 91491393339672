import { Card, Grid } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getFilesSocket, setupFilesSocket, unSubscribeSocketTopics } from '../../../redux/saga/socket';
import { devicesActions } from '../../../redux/slice/devices.slice';
import { filesActions } from '../../../redux/slice/files.slice';
import { GET_FILES } from '../../../redux/types/shared.types';
import CustomTitle from '../../shared/CustomTitle';
import MyTable from '../../shared/Tables/MyTable/MyTable';
import MyTableToolbar from '../../shared/Tables/MyTable/MyTableToolbar';
import tables from '../../shared/Tables/table.module.scss';
import { getFileColumns } from './helper';

const Files = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const selectedDevice = useSelector(devicesActions.selectors.getSelectedDevice)
    useEffect(() => {
        if (id) {
            const payload = {
                deviceId: id
            }
            dispatch({ type: GET_FILES, payload })
        }
    }, [dispatch, id]);

    useEffect(() => {
        if (selectedDevice) {
            getFilesSocket({
                deviceId: selectedDevice?._id
            });
        }
    }, [selectedDevice]);

    useEffect(() => {
        setupFilesSocket(dispatch);
        return () => {
            unSubscribeSocketTopics(["FILES_DATA"]); // Unsubscribe from the topic
        };
    }, [dispatch]);


    const files = useSelector(filesActions.selectors.getFiles);
    const fileColumns = getFileColumns(dispatch)
    return (
        <Grid container>
            <Grid container spacing={2} marginTop={'3vh'} alignItems="center">
                <Grid item xs={12} sm={true}>
                    <CustomTitle title={'Total Files'} count={files?.length} />
                </Grid>
            </Grid>
            <Grid item xs={12} xl={6} className={tables.tableContainer}>
                <Card>
                    <MyTableToolbar placeHolder={'Search for files'} btn1Txt={false} />
                    <MyTable checkboxSelection={true} columns={fileColumns} rows={files} />
                </Card>
            </Grid>
        </Grid>
    )
}

export default Files
