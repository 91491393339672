import authInterceptor from "./Interceptors/AuthInterceptor";

export const loginServ = async (payload) => {
  try {
    const response = await authInterceptor.post(`/login`, payload);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const verifyUserServ = async (payload) => {
  try {
    const response = await authInterceptor.post("/verify-otp", payload);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const uploadProfileImageService = async (file) => {
  const formData = new FormData();
  formData.append("image", file);

  const response = await authInterceptor.post("/update-image", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response;
};

export const updateUserService = async (userData) => {
  const response = await authInterceptor.put("/update-user", userData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response;
};

export const resetPasswordService = async (userData) => {
  const payload = {
    email: userData.email,
    oldPassword: userData.currentPassword,
    newPassword: userData.newPassword,
  };

  const response = await authInterceptor.put("/reset-password", payload);
  return response;
};

export const deleteUserService = async (userId) => {
  const response = await authInterceptor.delete(`/delete-user/${userId}`);
  return response.data;
};

export const forgotPasswordServ = async (payload) => {
  try {
    const response = await authInterceptor.post(`/forgot-password`, payload);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const changePasswordService = async (userData) => {
  const payload = {
    email: userData.email,
    newPassword: userData.newPassword,
  };

  const response = await authInterceptor.post("/change-password", payload);
  return response;
};

export const update2FAService = async (payload) => {
  const response = await authInterceptor.put("/update-two-fa", payload);
  return response?.data;
};

export const send2FAotpService = async (payload) => {
  const response = await authInterceptor.post("/send-two-fa-otp", payload);
  return response;
};

export const verify2FAotpService = async (payload) => {
  const response = await authInterceptor.post("/verify-two-fa-otp", payload);
  return response?.data;
};