import React from 'react'
import MyConsole from '../components/modules/Console/MyConsole'

const RemoteConsolePage = () => {
    return (
        <div>
            <MyConsole />
        </div>
    )
}

export default RemoteConsolePage
