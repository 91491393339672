import { Box, Button, Grid } from '@mui/material';
import CustomTitle from 'components/shared/CustomTitle';
import MyTable from 'components/shared/Tables/MyTable/MyTable';
import { encryptUserData, getNetworkCols, validateCredentials } from 'components/utils/networks';
import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { sendToSocket } from '../../../../redux/saga/socket';
import { networkDeviceActions } from '../../../../redux/slice/networkDevice.slice';
import { SAVE_NETWORK_CREDENTIALS } from '../../../../redux/types/networkDevice.types';
import login from '../../Login/login.module.scss'

const IPTable = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const deviceNetworks = useSelector(networkDeviceActions.selectors.getNetworkDevices);
    const selectedNetwork = deviceNetworks.find(network => network._id === id);

    const [credentials, setCredentials] = useState(
        selectedNetwork.credentials.reduce((acc, credential) => {
            acc[credential.ip] = { ...credential.credentials };
            return acc;
        }, {})
    );

    const [validationErrors, setValidationErrors] = useState(
        selectedNetwork.credentials.reduce((acc, credential) => {
            acc[credential.ip] = {
                username: false,
                password: false,
                deviceType: false,
                isVerified: false
            };
            return acc;
        }, {})
    );

    const handleChange = (ip, field, value) => {
        setCredentials({
            ...credentials,
            [ip]: {
                ...credentials[ip],
                [field]: value,
            },
        });
    };

    const handleSubmit = () => {
        if (validateCredentials(credentials, setValidationErrors, validationErrors)) {
            const encryptedCredentials = encryptUserData(credentials)
            const formattedData = selectedNetwork.credentials.map(credential => ({
                ip: credential.ip,
                credentials: encryptedCredentials[credential.ip]
            }));
            const payload = {
                networkId: selectedNetwork.networkId,
                orgId: selectedNetwork.orgId,
                credentials: formattedData
            }
            sendToSocket("NETWORK_CREDENTIALS", payload)
            dispatch({ type: SAVE_NETWORK_CREDENTIALS, payload })
        }
    };

    const rowsWithCredentials = selectedNetwork.credentials.map(credential => ({
        id: credential.ip,
        ...credential,
        credentials: credentials[credential.ip]
    }));

    const isAllVerified = useMemo(() => {
        return selectedNetwork.credentials?.every(item => item?.credentials?.isVerified) ?? false;
    }, [selectedNetwork.credentials]);


    return (
        <Grid item xs={12}>
            <CustomTitle title={"Network Credentials"} count={selectedNetwork?.credentials?.length} />
            <Box style={{ marginTop: '10px', backgroundColor: "white", borderRadius: "10px" }}>
                <MyTable
                    checkboxSelection={false}
                    columns={getNetworkCols(handleChange, validationErrors)}
                    rows={rowsWithCredentials}
                />
            </Box>
            <br />
            <Grid container item justifyContent={'flex-end'} xs={12}>
                <Grid item display={'flex'} justifyContent={'flex-end'} xs={3} >
                    <Button disabled={isAllVerified} onClick={handleSubmit} type="submit" variant='contained' className={login.loginBtn} >Submit</Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default IPTable
