import { yupResolver } from "@hookform/resolvers/yup";
import { string } from "yup";

export const YupSignUpSchema = {
    email: string().email(),
    username: string().required(),
    password: string().required(),
  };
  
  export const formSignUpSchema = (signupSchema) => {
    return {
      resolver: yupResolver(signupSchema),
      mode: "onChange",
      defaultValues: {
        email: "",
        password: "",
        username: "",
      },
    };
  };