export const notifications = [
    'Your order has been shipped and should arrive within the next three to five business days.',
    'New friend request from John Doe. Tap to view their profile.',
    'You have a new message waiting in your inbox. Click here to read.',
];

export const securityMenuItems = [
    { label: 'Incident Notifications' },
    { label: 'Anomaly Detection' },
    { label: 'Device Status Changes' },
    { label: 'Configuration Changes' },
    { label: 'Policy Violations' },
]

export const alertMenuItems = [
    { label: 'Device Types' },
    { label: 'Device Health' },
]

export const installationSteps = ['Open the bash terminal on the device.', 'Copy the command form clipboard.', 'Past the command in the terminal and run it.', 'Blot client will be installed on the device.']
