import React from "react";
import DetailChartCard from "components/modules/SingleDashboard/DetailChartCard";
import { Box, Typography, Link } from "@mui/material";
import styles from "./HardwareHealthOverview.module.scss";

const HardwareHealthOverview = () => {
  const complianceDetailItems = [
    {
      label: "Up",
      value: "186",
      color: "#00A307", // Green
    },
    {
      label: "Cancel",
      value: "05",
      color: "#FF0000", // Red
    },
    {
      label: "Warning",
      value: "07",
      color: "#FFD600", // Yellow
    },
    {
      label: "Undefined",
      value: "20",
      color: "#A6A6A6", // Grey
    },
  ];

  const graphColors = [
    "#00A307", // Green
    "#FF0000", // Red
    "#FFD600", // Yellow
    "#A6A6A6", // Grey
  ];

  return (
    <Box className={styles.container}>
      <Box className={styles.header}>
        <Typography variant="h6" className={styles.title}>
          Hardware Health Overview
        </Typography>
        <a href="#" className={styles.viewList}>
          View Large
        </a>
      </Box>
      <Box className={styles.content}>
        <Box className={styles.leftSide}>
          {complianceDetailItems.map((item, index) => (
            <Box className={styles.item} key={index}>
              <Box
                className={styles.color}
                style={{ backgroundColor: item.color }}
              ></Box>
              <Typography variant="body2" className={styles.label}>
                {item.label}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box className={styles.chart}>
          <DetailChartCard
            details={complianceDetailItems}
            chartSeries={[186, 5, 7, 20]}
            totalValue={218}
            graphColors={graphColors}
            isLeft={true}
            title2={""}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default HardwareHealthOverview;
