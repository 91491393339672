import { Button, Card } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { object } from 'yup';
import { FORGOT_PASSWORD, USER_LOGIN } from '../../../redux/types/auth.types';
import EmailIcon from 'static/svgs/Email';
import CustomTextField from 'components/shared/Forms/CustomTextField';
import text from 'components/shared/css/text.module.scss';
import { YupEmailSchema, formEmailSchema } from './helper';
import styles from './forgotpass.module.scss';

const EmailForm = () => {
  const dispatch = useDispatch();
  let emailSchema = object(YupEmailSchema);
  const { control, handleSubmit, formState: { errors, isValid } } = useForm(formEmailSchema(emailSchema))

  const handleEmailSubmit = (payload) => {
    dispatch({ type: FORGOT_PASSWORD, payload })
  };

  return (
    <Card className={styles.formContainer}>
      <div>
        <p className={text.heading1}>Forgot Password</p>
        <p className={text.info1}>Enter your email to recover your password</p>
        <br />
      </div>
      <form onSubmit={handleSubmit(handleEmailSubmit)} className={styles.fieldsContainer}>
        <CustomTextField
          control={control}
          errors={errors}
          name={'email'}
          type="text"
          label="Email"
          icon={EmailIcon}
          required={true}
          placeHolder={'Enter your email'}
        />
        <br />
        <div className={styles.btnContainer}>
          <Button disabled={!isValid} type="submit" variant='contained' className={styles.loginBtn} >Submit</Button>
        </div>
      </form>
    </Card >
  )
}

export default EmailForm
