import { Box } from '@mui/material'
import MyCard from 'components/shared/Card/Card'
import theme from 'components/utils/theme'
import PieChart from '../Device/PieChart'
import CustomLegend from './CustomLegend'
import GraphTitle from './GraphTitle'
import { useMemo } from 'react'

const UserRoleDistribution = ({ userRoles = { users: 0, admins: 0, pendingInvites: 0 } }) => {

    console.log("userRoles", userRoles)

    const arr = [
        {
            label: "Admins",
            color: theme.palette.primary.light
        },
        {
            label: "Users",
            color: theme.palette.primary.main
        },
        {
            label: "Pending Invites",
            color: theme.palette.warning.main
        },

    ]
    const chartData = useMemo(() => {
        return { a: userRoles?.admins, b: userRoles?.users, c: userRoles?.pendingInvites }
    }, [userRoles]);
    const graphColors = [
        theme.palette.primary.light,
        theme.palette.primary.main,
        theme.palette.warning.main,
    ];
    return (
        <MyCard>
            <GraphTitle title={'User Role Distribution'} />
            <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                <PieChart
                    chartData={chartData}
                    width={400}
                    height={230}
                    graphColors={graphColors}
                    totalValue={userRoles?.admins + userRoles?.users + userRoles?.pendingInvites}
                    radius={90}
                    left={"43.5%"}
                    top={'80px'}
                    showLabels={true}
                    totalText={"Total"}
                    showPercentage={false}
                />
            </Box>
            <CustomLegend arr={arr} />
        </MyCard>
    )
}

export default UserRoleDistribution
