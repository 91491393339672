import React from "react";
import { Box, Typography, Chip, Avatar } from "@mui/material";
import styles from "./DeviceNetworkDetails.module.scss";

const DeviceNetworkDetails = () => {
  return (
    <Box className={styles.container}>
      <Typography variant="h6" className={styles.title}>
        Device Network Details
      </Typography>
      <Box className={styles.content}>
        <Box className={styles.iconWrapper}>
            <svg
              width="78"
              height="78"
              viewBox="0 0 78 78"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="38.888"
                cy="38.888"
                r="37.888"
                fill="white"
                stroke="#00A307"
                stroke-width="2"
              />
              <path
                d="M57.975 48.625H42.4424C42.395 49.9405 41.5 50.75 40.345 50.75H36.45C35.2527 50.75 34.3347 49.5899 34.3507 48.625H19.025C18.4612 48.625 18 49.1031 18 49.6875V50.75C18 53.0875 19.845 55 22.1 55H54.9C57.155 55 59 53.0875 59 50.75V49.6875C59 49.1031 58.5387 48.625 57.975 48.625ZM54.9 24.1875C54.9 22.4344 53.5163 21 51.825 21H25.175C23.4837 21 22.1 22.4344 22.1 24.1875V46.5H54.9V24.1875ZM50.8 42.25H26.2V25.25H50.8V42.25Z"
                fill="#00A307"
              />
            </svg>
        </Box>
        <Box className={styles.details}>
          <Typography variant="h6" className={styles.deviceName}>
            BTA-US08
          </Typography>
          <Box className={styles.chipWrapper}>
            <Chip label="admin,  BDATA ASA 645BT" className={styles.chip} />
          </Box>
          <Typography variant="body2" className={styles.detail}>
            Running <span className={styles.span}>7.5 (7)</span>
          </Typography>
          <Typography variant="body2" className={styles.detail}>
            Routed Mode{" "}
            <Chip
              label="Active"
              color="success"
              size="small"
              className={styles.statusChip}
            />
          </Typography>
          <Typography variant="body2" className={styles.detail}>
            Polled At <span className={styles.span}>19.018.8.5</span>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default DeviceNetworkDetails;
