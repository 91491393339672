import React from 'react'

const CriticalIcon = ({ fill = "white", width = "19", height = "19" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.2814 0.978476C2.91383 0.81347 1.8916 1.94501 1.8916 3.17374V8.78473C1.8916 8.9616 2.05529 9.09272 2.23061 9.06919C2.41414 9.04454 2.6014 9.0318 2.79167 9.0318C4.78894 9.0318 6.45873 10.434 6.87033 12.3076H10.2851C10.7821 12.3076 11.1851 12.7106 11.1851 13.2076C11.1851 13.7047 10.7821 14.1076 10.2851 14.1076H6.8704C6.45887 15.9814 4.78901 17.3836 2.79167 17.3836C2.6014 17.3836 2.41414 17.3709 2.23061 17.3463C2.05529 17.3227 1.8916 17.4538 1.8916 17.6307V23.2418C1.8916 24.4705 2.91383 25.602 4.2814 25.437C10.0895 24.7363 14.6527 19.9959 15.0778 14.1076H22.382L19.204 17.2855C18.8526 17.637 18.8526 18.2068 19.204 18.5583C19.5555 18.9097 20.1253 18.9097 20.4768 18.5583L25.1886 13.8464C25.5401 13.4949 25.5401 12.9251 25.1886 12.5737L20.469 7.85406C20.1176 7.50258 19.5478 7.50258 19.1963 7.85406C18.8448 8.20553 18.8448 8.77535 19.1963 9.12682L22.3771 12.3076H15.0778C14.6526 6.41951 10.0894 1.67917 4.2814 0.978476Z" fill={fill} />
            <path d="M2.79132 10.4165C1.24971 10.4165 0 11.6662 0 13.2079C0 14.7495 1.24971 15.9992 2.79132 15.9992C4.33293 15.9992 5.58267 14.7495 5.58267 13.2079C5.58267 11.6662 4.33293 10.4165 2.79132 10.4165Z" fill={fill} />
        </svg>
    )
}
export default CriticalIcon
