import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { persistReducer, persistStore } from "redux-persist";
import reducer from "./slice/index";
import { rootSaga } from "./saga";
import localforage from "localforage";

const persistConfig = {
  key: "root",
  whitelist: ["auth", "ui", "devices", "networkDevice"],
  storage: localforage,
};
const middleware = [];

const sagaMiddleware = createSagaMiddleware();

middleware.push(sagaMiddleware);

const persistedReducer = persistReducer(persistConfig, reducer);

let store = null;
const getStore = () => {
  store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: true,
        immutableCheck: false,
        serializableCheck: false,
      }).concat(sagaMiddleware),
    devTools: true,
  });
  sagaMiddleware.run(rootSaga);
};

getStore();

export { getStore, store };

export const persistor = persistStore(store);
