import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { uiActions } from '../redux/slice/ui.slice';
import MultiDashboard from '../components/modules/MultiDashboard/MultiDashboard';
import { setupMultiDashboardSocket, unSubscribeSocketTopics } from '../redux/saga/socket';
import { TOPICS } from '../components/utils/enums';

const MultiDashboardPage = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(uiActions.actions.setSelectedNav('Dashboard'))
        setupMultiDashboardSocket(dispatch)
        return () => {
            unSubscribeSocketTopics([TOPICS.PROCESSES, TOPICS.SYSTEM, TOPICS.SYSTEM_PACKAGES, TOPICS.APPLICATIONS, TOPICS.NETWORK_INTERFACES, TOPICS.PORTS, TOPICS.ALL_APPS]);
        };
    }, [dispatch]);


    return (
        <div>
            <MultiDashboard />
        </div>
    )
}

export default MultiDashboardPage
