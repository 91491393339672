import React from 'react';
import { Button } from '@mui/material';
import buttons from './buttons.module.scss';

const CustomButton = ({ label, Icon, onClick, bgColor, color, hover, padding = '0 10px'  }) => {
    return (
        <Button
            className={buttons.customButton}
            sx={{
                backgroundColor: bgColor, color: color,
                padding: padding,
                '&:hover': {
                    backgroundColor: hover,
                }
            }}
            onClick={onClick}
            endIcon={
                Icon ? (
                    <div style={{ backgroundColor: color, color: bgColor }} className={buttons.iconWrapper}>
                        <Icon />
                    </div>
                ) : null
            }
        >
            {label}
        </Button>
    );
};

export default CustomButton;
