import React from 'react'

const MenuIcon = ({ fill = "#FFF", width = "18", height = "18" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.970581" width="14.8235" height="2.11765" rx="1.05882" fill={fill} />
            <rect x="0.970581" y="4.94116" width="19.0588" height="2.11765" rx="1.05882" fill={fill} />
            <rect x="0.970581" y="9.88235" width="10.5882" height="2.11765" rx="1.05882" fill={fill} />
        </svg>

    )
}
export default MenuIcon
