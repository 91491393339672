import apiInterceptor from "./Interceptors/ApiInterceptor";


export const getApplicationsServ = async () => {
    try {
        const response = await apiInterceptor.get(`/get-applications-analytics`);
        return response?.data;
    } catch (error) {
        throw error;
    }
}

export const getCombineAppsServ = async (payload) => {
    try {
        const response = await apiInterceptor.get(`/get-combine-applications`, {
            params: payload
        });
        return response?.data;
    } catch (error) {
        throw error;
    }
}


export const getNetworksServ = async (payload) => {
    try {
        const response = await apiInterceptor.get(`/get-networks-analytics`, {
            params: payload
        });
        return response?.data;
    } catch (error) {
        throw error;
    }
}

export const getPackagesServ = async (payload) => {
    try {
        const response = await apiInterceptor.get(`/get-packages-analytics`, {
            params: payload
        });
        return response?.data;
    } catch (error) {
        throw error;
    }
}
export const getPortsServ = async (payload) => {
    try {
        const response = await apiInterceptor.get(`/get-ports-analytics`, {
            params: payload
        });
        return response?.data;
    } catch (error) {
        throw error;
    }
}

export const getProcessesServ = async (payload) => {
    try {
        const response = await apiInterceptor.get(`/get-processes-analytics`, {
            params: payload
        });
        return response?.data;
    } catch (error) {
        throw error;
    }
}

export const getDashboardAnalyticsServ = async (payload) => {
    try {
        const response = await apiInterceptor.get(`/get-dashboard-analytics`, {
            params: payload
        });
        return response?.data;
    } catch (error) {
        throw error;
    }
}

export const getFilesServ = async (payload) => {
    try {
        const response = await apiInterceptor.get(`/get-files`, {
            params: payload
        });
        return response?.data;
    } catch (error) {
        throw error;
    }
}


export const deleteFileServ = async (payload) => {
    try {
        const response = await apiInterceptor.delete(`/delete-file`, {
            params: payload
        });
        return response?.data;
    } catch (error) {
        throw error;
    }
}


export const getFimServ = async (payload) => {
    try {
        const response = await apiInterceptor.get(`/get-fim-data`, {
            params: payload
        });
        return response?.data;
    } catch (error) {
        throw error;
    }
}

export const getDeviceUsersServ = async (payload) => {
    try {
        const response = await apiInterceptor.get(`/get-device-users`, {
            params: payload
        });
        return response?.data;
    } catch (error) {
        throw error;
    }
}

export const fetchAlertsService = async (payload) => {
    try {
        const response = await apiInterceptor.get("/get-device-alerts", { params: payload });
        return response?.data;
    } catch (error) {
        throw error;
    }
};



