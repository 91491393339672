import IPTable from 'components/modules/Networks/IpTable/IpTable';

const IPTablePage = () => {

  return (
    <>
      <IPTable />
    </>
  );
};

export default IPTablePage;
