import React from 'react'

const DownloadIcon = ({ fill = "#030229", width = "22", height = "22" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 -1.90735e-06C11.5263 -1.90735e-06 11.957 0.430657 11.957 0.957017V11.6756L14.8041 8.99598C15.1869 8.63709 15.7851 8.66102 16.1679 9.04383C16.5268 9.42663 16.5028 10.0248 16.12 10.4076L11.646 14.5945C11.2632 14.9534 10.689 14.9534 10.3061 14.5706L5.87993 10.1683C5.49712 9.78552 5.49712 9.18738 5.87993 8.80457C6.07134 8.61317 6.31059 8.51747 6.54985 8.51747C6.7891 8.51747 7.02836 8.61317 7.21976 8.80457L10.043 11.6039V0.957017C10.043 0.430657 10.4736 -1.90735e-06 11 -1.90735e-06Z" fill={fill} />
            <path d="M18.8954 19.1643H3.10459C1.93224 19.1643 0.951294 18.2073 0.951294 17.011V11.9149C0.951294 11.3885 1.38195 10.9579 1.90831 10.9579C2.43467 10.9579 2.86533 11.3885 2.86533 11.9149V17.011C2.86533 17.1306 2.96103 17.2503 3.10459 17.2503H18.8954C19.015 17.2503 19.1347 17.1546 19.1347 17.011V11.9149C19.1347 11.3885 19.5653 10.9579 20.0917 10.9579C20.618 10.9579 21.0487 11.3885 21.0487 11.9149V17.011C21.0487 18.2073 20.0917 19.1643 18.8954 19.1643Z" fill={fill} />
        </svg>


    )
}
export default DownloadIcon
