import NetworkKeyFeatures from "components/modules/Networks/NetworkKeyFeatures/NetworkKeyFeatures";
import NetworkCustomizable from "components/modules/Networks/NetworkCustomizable/NetworkCustomizable";
import NetworkStreamlined from "components/modules/Networks/NetworkStreamlined/NetworkStreamlined";

const RenderNetwork = (tab) => {
  if (tab === 0) {
    return <NetworkKeyFeatures />;
  } else if (tab === 1) {
    return <NetworkStreamlined />;
  } else if (tab === 2) {
    return <NetworkCustomizable />;
  } else {
    return <NetworkKeyFeatures />;
  }
};

export default RenderNetwork;
