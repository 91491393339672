import { Box } from '@mui/material'
import MyCard from 'components/shared/Card/Card'
import theme from 'components/utils/theme'
import { useMemo } from 'react'
import PieChart from '../Device/PieChart'
import CustomLegend from './CustomLegend'
import GraphTitle from './GraphTitle'
import { processChartData } from './helper'

const ThreatComparison = ({ alerts }) => {
    const arr = [
        {
            label: "High Alerts",
            color: theme.palette.error.main
        },
        {
            label: "Medium Alerts",
            color: theme.palette.warning.main
        },
        {
            label: "Low Alerts",
            color: theme.palette.success.main
        },
    ]
    const threats = useMemo(() => {
        return processChartData(alerts)
    }, [alerts]);


    const graphColors = [
        theme.palette.error.main,
        theme.palette.warning.main,
        theme.palette.success.main,
    ];
    return (
        <MyCard>
            <GraphTitle title={'Threat Detection Comparison'} />
            <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                <PieChart
                    chartData={threats?.chartData}
                    width={400}
                    height={230}
                    graphColors={graphColors}
                    totalValue={threats?.total}
                    radius={90}
                    left={"43.5%"}
                    top={'80px'}
                    showLabels={true}
                    totalText={"Total"}
                    showPercentage={false}
                />
            </Box>
            <CustomLegend arr={arr} />
        </MyCard>
    )
}

export default ThreatComparison
