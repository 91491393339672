import React from 'react'

const AlertIcon = ({ fill = "#0C5E9B", width = "22", height = "22" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M12.5031 0C5.61127 0 0 5.61128 0 12.503C0 19.3948 5.61127 25 12.5031 25C19.3948 25 25 19.3948 25 12.503C25 5.61128 19.3948 0 12.5031 0ZM12.5031 2.0835C18.2688 2.0835 22.9165 6.73729 22.9165 12.503C22.9165 18.2688 18.2688 22.9165 12.5031 22.9165C6.73728 22.9165 2.0835 18.2688 2.0835 12.503C2.0835 6.73729 6.73728 2.0835 12.5031 2.0835ZM17.6874 7.48148C17.55 7.4915 17.4159 7.52866 17.293 7.59083C17.1701 7.65299 17.0607 7.73892 16.9712 7.84365L10.857 14.9406L7.99015 11.9801C7.89428 11.8819 7.77994 11.8035 7.65369 11.7496C7.52745 11.6957 7.39179 11.6673 7.25451 11.6659C7.11724 11.6646 6.98106 11.6905 6.85381 11.7419C6.72655 11.7934 6.61072 11.8696 6.51298 11.966C6.31675 12.159 6.2049 12.4219 6.20187 12.6971C6.19885 12.9724 6.30489 13.2377 6.49684 13.435L10.1511 17.2093C10.2518 17.3134 10.3731 17.3953 10.5072 17.4498C10.6413 17.5044 10.7854 17.5304 10.9301 17.5262C11.0748 17.522 11.2171 17.4876 11.3478 17.4254C11.4785 17.3631 11.5948 17.2743 11.6893 17.1645L18.5482 9.19677C18.7287 8.98765 18.8187 8.71541 18.7984 8.43993C18.7782 8.16445 18.6494 7.90827 18.4404 7.72774C18.2316 7.54976 17.961 7.46122 17.6874 7.48148Z" fill={fill} />
        </svg>



    )
}
export default AlertIcon
