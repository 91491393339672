import React from 'react'
import styles from './shared.module.scss'
import { Box } from '@mui/material'
const ColoredIcon = ({ bgColor, icon, m = '20px', width = '37px', height = '37px' }) => {
  return (
    <Box
      className={styles.coloredIcon}
      sx={{ backgroundColor: bgColor, m: m, width: width, height: height }}>
      {icon}
    </Box>
  )
}

export default ColoredIcon
