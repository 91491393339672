import React, { useEffect } from "react";
import {
  Box,
  Typography,
  Switch,
  FormControlLabel,
  Checkbox,
  Grid,
  Skeleton
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { FETCH_ALERT_SETTINGS, UPDATE_ALERT_SETTINGS } from "../../../../redux/types/alertSettings.types";
import styles from "./EmailNotifications.module.scss";

const EmailNotifications = ({ title }) => {
  const dispatch = useDispatch();
  const alertSettings = useSelector((state) => state.alertSettings.alertSettings);
  const status = useSelector((state) => state.alertSettings.status);
  const userId = useSelector((state) => state.auth.currentUser?._id);  // Assuming you have currentUser in auth slice

  useEffect(() => {
    if (userId) {
      dispatch({ type: FETCH_ALERT_SETTINGS, payload: { userId } });
    }
  }, [dispatch, userId]);

  const [emailNotificationsEnabled, setEmailNotificationsEnabled] = React.useState(false);

  useEffect(() => {
    if (alertSettings) {
      if (title === "Email Notification") {
        setEmailNotificationsEnabled(Object.values(alertSettings.emailNotifications).some(v => v));
      } else {
        setEmailNotificationsEnabled(Object.values(alertSettings.webAlerts).some(v => v));
      }
    }
  }, [alertSettings, title]);

  const handleToggle = (event) => {
    if (!alertSettings) return;

    setEmailNotificationsEnabled(event.target.checked);
    const updatedSettings = {
      userId,
      ...alertSettings,
      [title === "Email Notification" ? 'emailNotifications' : 'webAlerts']: {
        ...alertSettings[title === "Email Notification" ? 'emailNotifications' : 'webAlerts'],
        FIM: event.target.checked,
        Apps: event.target.checked,
        PORT: event.target.checked,
        Files: event.target.checked,
      }
    };
    dispatch({ type: UPDATE_ALERT_SETTINGS, payload: updatedSettings });
  };

  const handleCheckboxChange = (event) => {
    if (!alertSettings) return;

    const { name, checked } = event.target;
    const updatedSettings = {
      userId,
      ...alertSettings,
      [title === "Email Notification" ? 'emailNotifications' : 'webAlerts']: {
        ...alertSettings[title === "Email Notification" ? 'emailNotifications' : 'webAlerts'],
        [name]: checked,
      }
    };
    dispatch({ type: UPDATE_ALERT_SETTINGS, payload: updatedSettings });
  };

  if (status === "loading" || !alertSettings) {
    return (
      <Box className={styles.notificationContainer}>
        <Skeleton variant="text" width={100} height={30} />
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={6}>
            <Skeleton variant="rectangular" width="100%" height={40} />
          </Grid>
          <Grid item xs={6}>
            <Skeleton variant="rectangular" width="100%" height={40} />
          </Grid>
          <Grid item xs={6}>
            <Skeleton variant="rectangular" width="100%" height={40} />
          </Grid>
          <Grid item xs={6}>
            <Skeleton variant="rectangular" width="100%" height={40} />
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <Box className={styles.notificationContainer}>
      <Grid container alignItems="center">
        <Grid item xs>
          <Typography variant="subtitle1" className={styles.label}>
            {title}
          </Typography>
        </Grid>
        <Grid item>
          <Switch
            checked={emailNotificationsEnabled}
            onChange={handleToggle}
            color="primary"
          />
        </Grid>
      </Grid>
      <Box className={styles.checkboxContainer}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControlLabel control={
              <Checkbox
                name="FIM"
                checked={alertSettings?.[title === "Email Notification" ? 'emailNotifications' : 'webAlerts']?.FIM || false}
                onChange={handleCheckboxChange}
              />
            } label="FIM" />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel control={
              <Checkbox
                name="PORT"
                checked={alertSettings?.[title === "Email Notification" ? 'emailNotifications' : 'webAlerts']?.PORT || false}
                onChange={handleCheckboxChange}
              />
            } label="PORT" />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel control={
              <Checkbox
                name="Apps"
                checked={alertSettings?.[title === "Email Notification" ? 'emailNotifications' : 'webAlerts']?.Apps || false}
                onChange={handleCheckboxChange}
              />
            } label="Apps" />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel control={
              <Checkbox
                name="Files"
                checked={alertSettings?.[title === "Email Notification" ? 'emailNotifications' : 'webAlerts']?.Files || false}
                onChange={handleCheckboxChange}
              />
            } label="Files" />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default EmailNotifications;
