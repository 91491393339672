import { useTheme } from "@emotion/react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  Box,
  CssBaseline,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authActions } from "../../../redux/slice/auth.slice";
import { uiActions } from "../../../redux/slice/ui.slice";
import { USER_LOGOUT } from "../../../redux/types/auth.types";
import DashboardIcon from "../../../static/svgs/dashboard";
import LogoutIcon from "../../../static/svgs/Logout";
import MenuIcon from "../../../static/svgs/menu";
import { getMenuItems } from "../../utils";
import { Drawer, DrawerHeader } from "./helper";
import styles from "./sidebar.module.scss";

const Sidebar = ({ children }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const currentUser = useSelector(authActions.selectors.getCurrentUser);

  const selected = useSelector(uiActions.selectors.getSelectedNav);
  const handleButtonClick = (link) => {
    navigate(link);
  };
  const handleSelect = (value, link) => {
    dispatch(uiActions.actions.setSelectedNav(value));
    handleButtonClick(link);
  };

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleLogout = () => {
    dispatch({ type: USER_LOGOUT });
  };

  return (
    <Box display={"flex"}>
      <CssBaseline />
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            sx={{ alignSelf: open ? "flex-start" : "center" }}
            onClick={() => handleButtonClick("/")}
          >
            <DashboardIcon width="26" height="26" />
          </IconButton>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ alignSelf: open ? "flex-end" : "center", ml: 0 }}
          >
            {open ? <ChevronLeftIcon color="white" /> : <MenuIcon />}
          </IconButton>
        </DrawerHeader>
        <br />
        <List className={styles.listContainer}>
          <Box>
            {getMenuItems(currentUser?.role)?.map((item, index) => (
              <ListItem key={index} disablePadding>
                <ListItemButton
                  disableRipple
                  disableTouchRipple
                  className={`${styles.itemButton}  ${selected === item?.label ? styles.selectedItem : null
                    }`}
                  onClick={() => handleSelect(item?.label, item?.link)}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {item.icon
                      ? React.createElement(item.icon, {
                        fill:
                          selected === item.label
                            ? theme.palette.primary.main
                            : "white",
                      })
                      : null}
                  </ListItemIcon>
                  <Typography
                    color={selected === item?.label ? "primary" : "white.main"}
                    sx={{ opacity: open ? 1 : 0 }}
                  >
                    {item?.label}
                  </Typography>
                </ListItemButton>
              </ListItem>
            ))}
          </Box>
          <Box>
            <ListItem onClick={handleLogout} disablePadding>
              <ListItemButton className={`${styles.itemButton}`}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <LogoutIcon />
                </ListItemIcon>
                <Typography color={"white.main"} sx={{ opacity: open ? 1 : 0 }}>
                  Logout
                </Typography>
              </ListItemButton>
            </ListItem>
          </Box>
        </List>
      </Drawer>
      <Box component="main" className={styles.main}>
        {children}
      </Box>
    </Box>
  );
};

export default Sidebar;
