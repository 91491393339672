import { Add, Search } from "@mui/icons-material";
import { Box, Button, IconButton, TextField } from "@mui/material";
import { CUSTOMIZEABLE_NETWORK_COLUMNS } from "components/utils/networks";
import React, { useMemo } from "react";
import BarsIcon from "static/svgs/Bars";
import DownloadIcon from "static/svgs/Download";
import PrintIcon from "static/svgs/Print";
import styles from "../NetworkCustomizable.module.scss";
import MyTable from "components/shared/Tables/MyTable/MyTable";
import tables from "components/shared/Tables/table.module.scss";

const filteredNetworks = [
    {
      id: 0,
      name: "West-5486-Core",
      status: "Warning",
      lastActive: "09-02-2024",
    },
    {
      id: 1,
      name: "East-9865-12sfI",
      status: "Connected",
      lastActive: "09-02-2024",
    },
  ];

const ConnectionRates = () => {
  const memoizedFilteredNetworks = useMemo(() => filteredNetworks, []);

  return (
    <Box className={styles.tableContainer}>
      <Box className={styles.controls}>
        <TextField
          variant="outlined"
          placeholder="Search for devices"
          size="small"
          InputProps={{
            startAdornment: (
              <IconButton size="small">
                <Search />
              </IconButton>
            ),
          }}
          className={styles.search}
        />
        <Box display="flex" alignItems="center">
          <Box className={styles.iconButtons}>
            <IconButton className={tables.iconBtn}>
              <DownloadIcon />
            </IconButton>
            <IconButton className={tables.iconBtn}>
              <PrintIcon />
            </IconButton>
            <IconButton className={tables.iconBtn}>
              <BarsIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <MyTable
        checkboxSelection={false}
        columns={CUSTOMIZEABLE_NETWORK_COLUMNS}
        rows={memoizedFilteredNetworks}
      />
    </Box>
  );
};

export default ConnectionRates;
