import React from "react";
import ForgotPasswordContainer from "components/modules/ForgotPassword/ForgotPassword";

const ForgotPassword = () => {
  return (
    <div>
      <ForgotPasswordContainer />
    </div>
  );
};

export default ForgotPassword;
