import React from 'react';
import { Box, Grid } from '@mui/material';
import EmailNotifications from 'components/modules/Account/EmailNotifications/EmailNotifications';
import _2FA from 'components/modules/Account/2FA/2FA';
import styles from './NotificationsTab.module.scss';

const NotificationsTab = ({ isTwoFaEnabled }) => {
  return (
    <Box className={styles.notificationsTab}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <EmailNotifications title={'Email Notification'} />
        </Grid>
        <Grid item xs={12} md={6}>
          <EmailNotifications title={'Web Alerts'} />
        </Grid>
        <Grid item xs={12}>
          <_2FA isTwoFaEnabled={isTwoFaEnabled} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default NotificationsTab;
