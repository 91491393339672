import React from 'react'

const IdIcon = ({ fill = "white", width = "13", height = "20" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 18 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.95801 6.1875H10.208C10.8267 6.1875 11.333 5.68687 11.333 5.0625V1.125C11.333 0.506264 10.8267 0 10.208 0H7.95801C7.33361 0 6.83301 0.506264 6.83301 1.125V5.0625C6.83301 5.68687 7.33361 6.1875 7.95801 6.1875Z" fill={fill} />
            <path d="M15.5625 2.8125H12.4575V5.06236C12.4575 6.30327 11.4481 7.31236 10.2075 7.31236H7.95749C6.71686 7.31236 5.70749 6.30327 5.70749 5.06236V2.8125H2.0625C1.1344 2.8125 0.375 3.5719 0.375 4.5V25.3125C0.375 26.2463 1.1344 27 2.0625 27H15.5625C16.4906 27 17.25 26.2463 17.25 25.3125V4.5C17.25 3.5719 16.4906 2.8125 15.5625 2.8125ZM8.8125 7.875C10.0556 7.875 11.0625 8.88749 11.0625 10.125C11.0625 11.3681 10.0556 12.375 8.8125 12.375C7.5694 12.375 6.5625 11.3681 6.5625 10.125C6.5625 8.88749 7.5694 7.875 8.8125 7.875ZM4.3125 24.1896H3.1875V23.0646H4.3125V24.1896ZM4.3125 21.3771H3.1875V20.2521H4.3125V21.3771ZM12.1875 24.1896H5.4375V23.0646H12.1875V24.1896ZM12.1875 21.3771H5.4375V20.2521H12.1875V21.3771ZM14.4375 24.1896H13.3125V23.0646H14.4375V24.1896ZM14.4375 21.3771H13.3125V20.2521H14.4375V21.3771ZM13.875 18.5625H3.75C3.44063 18.5625 3.1875 18.315 3.1875 18C3.1875 15.6994 4.56563 13.6575 6.70312 12.7912C6.90002 12.7069 7.13063 12.7519 7.29374 12.8981C8.13749 13.6744 9.48751 13.6744 10.3313 12.8981C10.4888 12.7519 10.725 12.7069 10.9219 12.7912C13.0594 13.6575 14.4375 15.6994 14.4375 18C14.4375 18.315 14.1844 18.5625 13.875 18.5625Z" fill={fill} />
        </svg>


    )
}
export default IdIcon
