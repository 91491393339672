import React, { useMemo } from "react";
import { STREMLINED_NWTWORK_COLUMNS } from "components/utils/networks";
import styles from "../NetworkStreamlined.module.scss";
import MyTable from "components/shared/Tables/MyTable/MyTable";
import { Box, IconButton, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";
import DownloadIcon from "static/svgs/Download";
import PrintIcon from "static/svgs/Print";
import BarsIcon from "static/svgs/Bars";
import tables from "components/shared/Tables/table.module.scss";

const filteredNetworks = [
    {
      id: 0,
      name: "West-5486-Core",
      interface: "10.182.0.29.1",
      recentErrors: "DC:A7:F4:78:08:06",
      status: "Warning",
      lastActive: "09-02-2024",
      actions: "Install",
    },
    {
      id: 1,
      name: "East-9865-12sfI",
      interface: "10.182.0.29.1",
      recentErrors: "DC:A7:F4:78:08:06",
      status: "Connected",
      lastActive: "09-02-2024",
      actions: "Txn",
    },
  ];

const NetworkDeviceDetails = () => {
  const memoizedFilteredNetworks = useMemo(() => filteredNetworks, []);

  return (
    <Box className={styles.tableContainer}>
      <Box className={styles.controls}>
        <TextField
          variant="outlined"
          placeholder="Search for devices"
          size="small"
          InputProps={{
            startAdornment: (
              <IconButton size="small">
                <Search />
              </IconButton>
            ),
          }}
          className={styles.search}
        />
        <Box display="flex" alignItems="center">
          <Box className={styles.iconButtons}>
            <IconButton className={tables.iconBtn}>
              <DownloadIcon />
            </IconButton>
            <IconButton className={tables.iconBtn}>
              <PrintIcon />
            </IconButton>
            <IconButton className={tables.iconBtn}>
              <BarsIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <MyTable
        checkboxSelection={false}
        columns={STREMLINED_NWTWORK_COLUMNS}
        rows={memoizedFilteredNetworks}
      />
    </Box>
  );
};

export default NetworkDeviceDetails;
