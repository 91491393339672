import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import { string } from "yup";

export const YupEmailSchema = {
  email: string().email().required(),
};

export const formEmailSchema = (emailSchema) => {
  return {
    resolver: yupResolver(emailSchema),
    mode: "onChange",
    defaultValues: {
      email: "",
    },
  };
};

export const YupTwoFaSchema = {
  otp: string()
    .required("Code is required")
    .matches(/^\d{4}$/, "Code must be exactly 4 digits"),
};

export const formTwoFaSchema = (twoFaSchema) => {
  return {
    resolver: yupResolver(twoFaSchema),
    mode: "onChange",
    defaultValues: {
      otp: "",
    },
  };
};

export const YupResetPasswordSchema = {
  newPassword: Yup.string().required('Password is required').min(6, 'Password must be at least 6 characters'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('Confirm Password is required')
};

export const formResetPasswordSchema = (resetPasswordSchema) => ({
  resolver: yupResolver(resetPasswordSchema),
  mode: 'onChange',
  defaultValues: {
    newPassword: '',
    confirmPassword: '',
  },
});