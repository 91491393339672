import { Close } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import React from 'react';
import styles from './dialog.module.scss';
import text from '../../shared/css/text.module.scss';

const CustomDialog = ({ title, isOpen, handleToggle, children, maxWidth, borderRadius = "31px", fullScreen = false }) => {
    return (
        <Dialog
            open={isOpen}
            onClose={handleToggle}
            className={styles.dialogContainer}
            maxWidth={maxWidth}
            fullScreen={fullScreen}
            PaperProps={{ sx: { borderRadius: borderRadius, overflow: 'none' } }}
        >
            <div className={styles.dialogTitle}>
                <DialogTitle className={text.heading1} >
                    {title}
                </DialogTitle>
                <IconButton onClick={handleToggle} className={styles.close} aria-label="close">
                    <Close />
                </IconButton>
            </div>
            <DialogContent className={styles.dialogueContent}>
                {children}
            </DialogContent>
        </Dialog>
    )
}

export default CustomDialog
