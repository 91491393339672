import React from 'react'

const CopyIcon = ({ fill = "white", width = "19", height = "19" }) => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.4211 6.94727H18.5263C19.4473 6.94828 20.3303 7.31458 20.9815 7.96581C21.6327 8.61704 21.999 9.50001 22 10.421V18.5262C21.999 19.4472 21.6327 20.3302 20.9815 20.9814C20.3303 21.6326 19.4473 21.9989 18.5263 21.9999H10.4211C9.50008 21.9989 8.61712 21.6326 7.9659 20.9814C7.31468 20.3302 6.94839 19.4472 6.94739 18.5262V10.421C6.94838 9.50001 7.31468 8.61704 7.9659 7.96581C8.61712 7.31458 9.50008 6.94828 10.4211 6.94727ZM9.26316 18.5262C9.26346 18.8332 9.38554 19.1276 9.60262 19.3447C9.81971 19.5617 10.1141 19.6838 10.4211 19.6841H18.5263C18.8333 19.6838 19.1277 19.5617 19.3447 19.3446C19.5618 19.1276 19.6839 18.8332 19.6842 18.5262V10.421C19.6839 10.114 19.5618 9.81965 19.3447 9.60256C19.1277 9.38548 18.8333 9.26339 18.5263 9.26309H10.4211C10.1141 9.26338 9.81971 9.38547 9.60263 9.60255C9.38554 9.81964 9.26346 10.114 9.26316 10.421V18.5262Z" fill="white" />
            <path d="M3.47355 0H11.5788C12.4998 0.00100088 13.3827 0.367297 14.034 1.01852C14.6852 1.66974 15.0515 2.5527 15.0525 3.47367V4.63156C15.0525 4.78362 15.0225 4.93419 14.9644 5.07467C14.9062 5.21515 14.8209 5.34279 14.7134 5.45031C14.6058 5.55783 14.4782 5.64312 14.3377 5.70131C14.1972 5.7595 14.0467 5.78945 13.8946 5.78945C13.7425 5.78945 13.592 5.7595 13.4515 5.70131C13.311 5.64312 13.1834 5.55783 13.0759 5.45031C12.9683 5.34279 12.883 5.21515 12.8249 5.07467C12.7667 4.93419 12.7367 4.78362 12.7367 4.63156V3.47367C12.7364 3.16667 12.6143 2.87233 12.3972 2.65525C12.1802 2.43817 11.8858 2.31608 11.5788 2.31578H3.47355C3.16655 2.31609 2.87222 2.43818 2.65514 2.65526C2.43806 2.87234 2.31597 3.16667 2.31566 3.47367V11.5789C2.31597 11.8859 2.43806 12.1803 2.65514 12.3974C2.87222 12.6144 3.16655 12.7365 3.47355 12.7368H4.63144C4.93853 12.7368 5.23304 12.8588 5.45019 13.076C5.66734 13.2931 5.78933 13.5876 5.78933 13.8947C5.78933 14.2018 5.66734 14.4963 5.45019 14.7135C5.23304 14.9306 4.93853 15.0526 4.63144 15.0526H3.47355C2.55258 15.0516 1.66962 14.6853 1.0184 14.0341C0.367174 13.3829 0.00087738 12.4999 -0.000123024 11.5789V3.47367C0.00087738 2.5527 0.367174 1.66974 1.0184 1.01852C1.66962 0.367297 2.55258 0.00100088 3.47355 0Z" fill="white" />
        </svg>
    )
}
export default CopyIcon
