import React from 'react'

const TimeIcon = ({ fill = "#0C5E9B", width = "22", height = "22" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.125 7.1875C13.125 6.66972 12.7053 6.25 12.1875 6.25C11.6697 6.25 11.25 6.66972 11.25 7.1875V13.6479L17.0073 17.2039C17.4479 17.476 18.0255 17.3394 18.2976 16.8989C18.5697 16.4584 18.4332 15.8807 17.9927 15.6086L13.125 12.6021V7.1875Z" fill={fill} />
            <path fillRule="evenodd" clipRule="evenodd" d="M25 12.5C25 19.4036 19.4036 25 12.5 25C5.59644 25 0 19.4036 0 12.5C0 5.59644 5.59644 0 12.5 0C19.4036 0 25 5.59644 25 12.5ZM23.125 12.5C23.125 18.368 18.368 23.125 12.5 23.125C6.63197 23.125 1.875 18.368 1.875 12.5C1.875 6.63197 6.63197 1.875 12.5 1.875C18.368 1.875 23.125 6.63197 23.125 12.5Z" fill={fill} />
        </svg>
 
    )
}
export default TimeIcon
