import { useEffect } from 'react';
import { disconnectSocket, initilizeSocket } from '../../redux/saga/socket';

const AppBoot = () => {
    useEffect(() => {
        initilizeSocket();
        return () => {
            disconnectSocket();
        };
    }, []);
    return null
}

export default AppBoot
