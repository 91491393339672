import React, { useEffect } from "react";
import AppsContainer from "../components/modules/Apps/AppsContainer";
import { useDispatch, useSelector } from "react-redux";
import { GET_MULTI_DASHBOARD_ANALYTICS } from "../redux/types/multi.types";
import { applicationsActions } from "../redux/slice/applications.slice";
import { packagesActions } from "../redux/slice/packages.slice";
import {
  setupMultiAppsSocket,
  unSubscribeSocketTopics,
} from "../redux/saga/socket";
import { TOPICS } from "../components/utils/enums";

const AppsPage = () => {
  const applications = useSelector(applicationsActions.selectors.getMultiApps);
  const packages = useSelector(packagesActions.selectors.getMultiPackages);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: GET_MULTI_DASHBOARD_ANALYTICS });
    setupMultiAppsSocket(dispatch);
    return () => {
      unSubscribeSocketTopics([TOPICS.SYSTEM_PACKAGES, TOPICS.APPLICATIONS]);
    };
  }, [dispatch]);

  return (
    <>
      <AppsContainer applications={applications} packages={packages} />
    </>
  );
};

export default AppsPage;
