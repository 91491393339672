import { DeviceStatus } from "components/utils/enums";
import { CONNECTED_CHILD_ICON, CONNECTED_PARENT_ICON, DISCONNECTED_CHILD_ICON, DISCONNECTED_PARENT_ICON } from "components/utils/networks";

export const convertToElements = (data) => {
    const elements = [];
    const parentDevice = data?.parentDevice ? data?.parentDevice : null;
    const childDevices = data?.childDevices || [];

    if (parentDevice) {
        elements.push({
            data: {
                id: parentDevice._id,
                label: parentDevice.deviceId,
                status: parentDevice.status,
                type: 'parent',
                device: parentDevice
            },
            classes: parentDevice.status === "Connected" ? "active" : "inactive"
        });

        childDevices.forEach(child => {
            elements.push({
                data: {
                    id: child._id,
                    label: child.deviceId,
                    status: child.status,
                    type: 'child',
                    device: child
                },
                classes: child.status === "Registered" ? "active" : "inactive"
            });

            elements.push({
                data: { source: child._id, target: parentDevice._id },
                classes: "edge"
            });
        });
    }

    return elements;
}


export const truncateLabel = (label) => {
    const maxLength = 10;
    return label.length > maxLength ? label.substring(0, maxLength) + '...' : label;
};


export const getIconForNode = (node) => {
    const isParent = node.data('type') === "parent";
    const status = node.data('status');

    if (isParent) {
        return status === DeviceStatus.connected ? CONNECTED_PARENT_ICON : DISCONNECTED_PARENT_ICON;
    } else {
        return status === DeviceStatus.connected ? CONNECTED_CHILD_ICON : DISCONNECTED_CHILD_ICON;
    }
};