import React from 'react'

const ArrowDownIcon = ({ fill = "black", width = "13", height = "13" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.786221 0C0.585275 0 0.381339 0.0858097 0.228867 0.253209C-0.0762891 0.588242 -0.0762891 1.14225 0.228867 1.47729L5.93929 7.74679C6.08663 7.90856 6.29462 8 6.49664 8C6.69673 8 6.89618 7.92005 7.05399 7.74679L12.7565 1.48549C13.076 1.15538 13.08 0.588242 12.7749 0.253209C12.4697 -0.0818241 11.9651 -0.0818241 11.66 0.253209L11.6597 0.253443L6.50667 5.92204L1.34336 0.253209C1.19068 0.0855753 0.986953 0 0.786221 0Z" fill={fill} />
        </svg>
    )
}
export default ArrowDownIcon
