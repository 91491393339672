import { createSlice } from "@reduxjs/toolkit";
import toast from 'react-hot-toast';

const initialState = {
  alertPolicies: [],
  status: "idle",
  error: null,
};

const alertPolicySlice = createSlice({
  name: "alertPolicy",
  initialState,
  reducers: {
    fetchAlertPoliciesRequest: (state) => {
      state.status = "loading";
    },
    fetchAlertPoliciesSuccess: (state, action) => {
      state.status = "succeeded";
      state.alertPolicies = action.payload;
    },
    fetchAlertPoliciesFailure: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    createAlertPolicyRequest: (state) => {
      state.status = "loading";
    },
    createAlertPolicySuccess: (state, action) => {
      console.log("action", action)
      state.status = "succeeded";
      toast.success('Alert Policy Created');
      state.alertPolicies.push(action.payload);
    },
    createAlertPolicyFailure: (state, action) => {
      state.status = "failed";
      toast.error("Something went wrong");
      state.error = action.payload;
    },
  },
});

export const alertPolicyActions = alertPolicySlice.actions;

export default alertPolicySlice;
