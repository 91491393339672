import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
    packages: [],
    multiPackages: []
}

const name = 'packages'
const parentSelector = state => state?.[name]

const packagesSlice = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        resetPackages: () => {
            return initialState;
        },
        setPackages: (state, action) => {
            return {
                ...state,
                packages: action.payload,
            };
        },
        setMultiPackages: (state, action) => {
            return {
                ...state,
                multiPackages: action.payload,
            };
        },
    },
});


const getPackages = createSelector(parentSelector, state => {
    return state?.packages
})

const getMultiPackages = createSelector(parentSelector, state => {
    return state?.multiPackages
})

const actions = {
    ...packagesSlice.actions
}

const selectors = {
    getPackages,
    getMultiPackages
}

export const packagesActions = { actions, selectors }


export default packagesSlice;