import React, { useState } from "react";
import {
  Box,
  Typography,
  Switch,
  Grid,
} from "@mui/material";
import styles from "./2FA.module.scss";
import { TWO_FA } from "../../../../redux/types/auth.types";
import { useDispatch } from "react-redux";

const _2FA = ({isTwoFaEnabled}) => {
  const dispatch = useDispatch();

  const handleToggle = (event) => {
    dispatch({ type: TWO_FA, payload: { isTwoFaEnabled: event.target.checked }});
  };

  return (
    <Box className={styles.notificationContainer}>
      <Grid container alignItems="baseline">
        <Grid item xs>
          <Typography variant="subtitle1" className={styles.label}>
            {isTwoFaEnabled ? "Disable 2FA":"Enable 2FA"}
          </Typography>
        </Grid>
        <Grid item className={styles.checkboxContainer}>
          <Switch
            checked={isTwoFaEnabled}
            onChange={handleToggle}
            color="primary"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default _2FA;
