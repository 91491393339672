import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
    processes: [],
    multiPorcesses: []
}

const name = 'processes'
const parentSelector = state => state?.[name]

const processesSlice = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        resetProcesses: () => {
            return initialState;
        },
        setProcesses: (state, action) => {
            return {
                ...state,
                processes: action.payload,
            };
        },
        setMultiPorcesses: (state, action) => {
            return {
                ...state,
                multiPorcesses: action.payload,
            };
        },
    },
});


const getProcesses = createSelector(parentSelector, state => {
    return state?.processes
})
const getMultiPorcesses = createSelector(parentSelector, state => {
    return state?.multiPorcesses
})

const actions = {
    ...processesSlice.actions
}

const selectors = {
    getProcesses,
    getMultiPorcesses
}

export const processesActions = { actions, selectors }


export default processesSlice;