import React from 'react'
import styles from './login.module.scss';
import text from '../../shared/css/text.module.scss';
import slider1 from '../../../static/assets/slider1.png'
const LoginSlide = () => {
    return (
        <div className={styles.slideContainer}>
            <div>
                <p className={text.heading1}>Start Your BDATA Journey With Us</p>
                <p className={`${text.normal2} ${text.fontCapital}` }>To Meet Cyber Resilience and compliance, use BIoT technology to <br/> achieve Zero Trust multilayer cyber security and avoid credentials lost
                </p>

            </div>
            <img src={slider1} alt='slider1' />
        </div>
    )
}

export default LoginSlide
