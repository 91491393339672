import React, { useState } from "react";
import { Box, Grid, Typography, TextField, Button, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import styles from "./PasswordReset.module.scss";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { RESET_PASSWORD } from "../../../../redux/types/auth.types";

const PasswordReset = ({ email }) => {
  const dispatch = useDispatch();
  const { handleSubmit, control, watch, setError, clearErrors, formState: { errors } } = useForm();
  const newPassword = watch("newPassword");
  const confirmPassword = watch("confirmPassword");

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const toggleShowCurrentPassword = () => setShowCurrentPassword(!showCurrentPassword);
  const toggleShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const toggleShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  const onSubmit = (data) => {
    if (newPassword !== confirmPassword) {
      setError("confirmPassword", {
        type: "manual",
        message: "Passwords do not match",
      });
      return;
    }
    clearErrors("confirmPassword");
    dispatch({ type: RESET_PASSWORD, payload: { ...data, email }});
  };

  return (
    <Box className={styles.formContainer}>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" className={styles.label}>
              Current Password<span className={styles.required}>*</span>
            </Typography>
            <Controller
              name="currentPassword"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="Current Password"
                  required
                  type={showCurrentPassword ? "text" : "password"}
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    sx: { height: 40, borderRadius: 2 },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={toggleShowCurrentPassword} edge="end">
                          {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" className={styles.label}>
              New Password<span className={styles.required}>*</span>
            </Typography>
            <Controller
              name="newPassword"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="New Password"
                  required
                  type={showNewPassword ? "text" : "password"}
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    sx: { height: 40, borderRadius: 2 },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={toggleShowNewPassword} edge="end">
                          {showNewPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" className={styles.label}>
              Confirm Password<span className={styles.required}>*</span>
            </Typography>
            <Controller
              name="confirmPassword"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="Confirm Password"
                  required
                  type={showConfirmPassword ? "text" : "password"}
                  fullWidth
                  variant="outlined"
                  error={!!errors.confirmPassword}
                  helperText={errors.confirmPassword ? errors.confirmPassword.message : ""}
                  InputProps={{
                    sx: { height: 40, borderRadius: 2 },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={toggleShowConfirmPassword} edge="end">
                          {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
              <Button type="submit" variant="contained" color="primary" sx={{ height: 35, borderRadius: "20px" }}>
                Reset Password
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default PasswordReset;
