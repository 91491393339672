import React from "react";
import { Box, Typography } from "@mui/material";
import styles from "./AccessManagement.module.scss";
import PeopleIcon from "static/svgs/People";
import UsersIcon from "static/svgs/users";
import RegisterSettingsIcon from "static/svgs/RegisterSettings";
import UserAppsIcon from "static/svgs/UserApps";
import AppsIcon from "static/svgs/Apps";
import { ACCESS_MANAGEMENT_TABS } from "components/utils/accessManagement";
import CustomNavTabs from "components/shared/CustomNavTabs";

const AccessManagement = ({
  selectedTab,
  handleSelectedTab,
  groupCount,
  userCount,
  roleCount,
  policyCount,
  appsCount,
}) => {
  const resources = [
    {
      count: groupCount,
      label: "User Group",
      icon: <PeopleIcon />,
      className: "user-group",
      tab: ACCESS_MANAGEMENT_TABS.GROUPS,
    },
    {
      count: userCount,
      label: "Users",
      icon: <UsersIcon />,
      className: "users",
      tab: ACCESS_MANAGEMENT_TABS.USER,
    },
    {
      count: roleCount,
      label: "Roles",
      icon: <UserAppsIcon />,
      className: "roles",
      tab: ACCESS_MANAGEMENT_TABS.USER,
    },
    {
      count: policyCount,
      label: "Policies",
      icon: <RegisterSettingsIcon />,
      className: "policies",
      tab: ACCESS_MANAGEMENT_TABS.POLICIES,
    },
    {
      count: appsCount,
      label: "Apps",
      icon: <AppsIcon />,
      className: "apps",
      tab: ACCESS_MANAGEMENT_TABS.APPS,
    },
  ];

  const colors = [
    "#00B0DF",
    "#ffe082",
    "#0C5E9B",
    "#00B0DF",
    "#FFCCBC",
    "#0C5E9B",
  ];

  return (
    <Box className={styles["tabs-container"]}>
      <Box className={styles["tabs-section"]}>
        <Typography
          variant="h6"
          component="div"
          className={styles["tabs-header"]}
        >
          Access Management
        </Typography>
        <CustomNavTabs
          selectedTab={selectedTab}
          handleSelectedTab={handleSelectedTab}
          navitems={[
            { label: ACCESS_MANAGEMENT_TABS.GROUPS },
            { label: ACCESS_MANAGEMENT_TABS.USER },
            { label: ACCESS_MANAGEMENT_TABS.POLICIES },
            { label: ACCESS_MANAGEMENT_TABS.DEVICES },
            { label: ACCESS_MANAGEMENT_TABS.APPS },
          ]}
        />
      </Box>
      <Box className={styles["resources-section"]}>
        <Typography
          variant="h6"
          component="div"
          className={styles["resources-header"]}
        >
          Bdata Total Resources
        </Typography>
        <Box className={styles["resource-cards"]}>
          {resources.map((resource, index) => (
            <Box
              key={resource.label}
              className={`${styles.card} ${styles[resource.className]}`}
              onClick={() => handleSelectedTab(resource.tab)}
            >
              <Box className={styles["card-content"]}>
                <Typography
                  className="count"
                  sx={{ fontWeight: "bold", color: `${colors[index]}` }}
                >
                  {resource.count}
                </Typography>
                <Typography className="label">{resource.label}</Typography>
              </Box>
              <Box className={styles["card-icon"]}>{resource.icon}</Box>
              <Box className={styles.curve}></Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default AccessManagement;
