import React, { useMemo } from "react";
import {
  Box,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import styles from "./KeyFeatures.module.scss";
import theme from "components/utils/theme";
import DetailChartCard from "components/modules/SingleDashboard/DetailChartCard";

const complainceDetailItems = [
  {
    value: "40",
    color: theme.palette.success.main,
  },
  {
    value: "05",
    color: theme.palette.error.main,
  },
  {
    value: "54",
    color: theme.palette.warning.light,
  },
];

const getRandomColor = () => {
  const colors = [styles.greenDot, styles.redDot, styles.yellowDot];
  return colors[Math.floor(Math.random() * colors.length)];
};

const graphColors = [
  theme.palette.success.main,
  theme.palette.error.main,
  theme.palette.warning.light,
];



const KeyFeatures = React.memo(({ connections }) => {

  const memoizedConnectivity = useMemo(() => {
    const newDynamicConnectivity = connections?.map(connection => {
      const color = getRandomColor();
      return {
        deviceIp: connection.ip,
        connectivity: connection.connectivity,
        deviceDot: color,
        connectivityDot: color,
      };
    });
    return newDynamicConnectivity;
  }, [connections?.length]);

  const memoizedComplainceDetailItems = useMemo(() => complainceDetailItems, []);
  const memoizedGraphColors = useMemo(() => graphColors, []);

  return (
    <Box className={styles.container}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box className={styles.section}>
            <Typography variant="h6" className={styles.title}>
              Interfaces With High Percent Utilization
            </Typography>
            <Box className={styles.listHeader}>
              <Typography className={styles.listHeaderTitle}>
                Device Ips
              </Typography>
              <Typography className={styles.listHeaderTitle}>
                Type Of Connectivity
              </Typography>
            </Box>
            <List className={styles.list}>
              {memoizedConnectivity?.map((item, index) => (
                <ListItem key={index} className={styles.listItem}>
                  <Grid container alignItems="center">
                    <Grid item xs={6} className={styles.deviceContainer}>
                      <Box className={`${styles.dot} ${item.deviceDot}`} />
                      <ListItemText
                        primary={item.deviceIp}
                        className={styles.deviceText}
                      />
                    </Grid>
                    <Grid item xs={6} className={styles.connectivityContainer}>
                      <Box
                        className={`${styles.dot} ${item.connectivityDot}`}
                      />
                      <ListItemText
                        primary={item.connectivity}
                        className={styles.connectivityText}
                      />
                    </Grid>
                  </Grid>
                </ListItem>
              ))}
            </List>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} className={styles.chartSection}>
          <Box className={styles.section}>
            <Typography variant="h6" className={styles.title}>
              Total Result
            </Typography>
            <DetailChartCard
              details={memoizedComplainceDetailItems}
              chartSeries={{ a: 40, b: 20, c: 35 }}
              totalValue={memoizedConnectivity?.length}
              graphColors={memoizedGraphColors}
              isLeft={false}
              title2={""}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
});

export default KeyFeatures;
