import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
    fim: [],
}

const name = 'fim'
const parentSelector = state => state?.[name]

const fimSlice = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        resetFim: () => {
            return initialState;
        },
        setFim: (state, action) => {
            return {
                ...state,
                fim: action.payload,
            };
        },
    },
});


const getFim = createSelector(parentSelector, state => {
    return state?.fim
})

const actions = {
    ...fimSlice.actions
}

const selectors = {
    getFim
}

export const fimActions = { actions, selectors }


export default fimSlice;