import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@mui/material";
import Group from "./components/Group/Group";
import styles from "./Groups.module.scss";
import {
  CREATE_GROUP,
} from "../../../../redux/types/group.types";

const Groups = ({refreshGroups}) => {
  const dispatch = useDispatch();
  const groups = useSelector((state) => state.group.groups);
  const [selectedType, setSelectedType] = useState("Functional");
  const [open, setOpen] = useState(false);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const handleOpen = (type) => {
    setSelectedType(type);
    reset({
      name: "",
      description: "",
      type: type,
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const onSubmit = (data) => {
    const newGroup = {
      ...data,
      policies: [],
      users: [],
      devices: [],
    };
    dispatch({ type: CREATE_GROUP, payload: newGroup });
    handleClose();
    refreshGroups();
  };

  const descriptions = {
    Functional:
      "A user group is a collection of BDATA users. Use groups to specify permissions for a collection of users.",
    "Location-Based":
      "An Bdata user is an entity that you create in group to represent the person or application that users into interact with Bdata.",
    "Criticality-Based":
      "You can attach up to 20 policies to this user group. All the users in this group will have permissions that are defined in the selected policies.",
    "Vendor/Product":
      "You can attach up to 20 policies to this user group. All the users in this group will have permissions that are defined in the selected policies.",
    "Regulatory Compliance":
      "An Bdata user is an entity that you create in group to represent the person or application that users into interact with Bdata.",
    Custom:
      "An Bdata user is an entity that you create in group to represent the person or application that users into interact with Bdata.",
  };

  return (
    <div className={styles.container}>
      {Object.keys(groups).map((type) => (
        <Group
          key={type}
          type={type}
          description={descriptions[type]}
          rows={groups[type]}
          handleOpen={() => handleOpen(type)}
          refreshGroups={refreshGroups}
        />
      ))}

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Create a New Group</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              rules={{ required: "Group Name is required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Group Name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  error={!!errors.name}
                  helperText={errors.name ? errors.name.message : ""}
                />
              )}
            />
            <Controller
              name="description"
              control={control}
              defaultValue=""
              rules={{ required: "Description is required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Description"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  error={!!errors.description}
                  helperText={
                    errors.description ? errors.description.message : ""
                  }
                />
              )}
            />
            <FormControl
              variant="outlined"
              fullWidth
              margin="normal"
              error={!!errors.type}
            >
              <InputLabel>Type</InputLabel>
              <Controller
                name="type"
                control={control}
                defaultValue={selectedType}
                rules={{ required: "Type is required" }}
                render={({ field }) => (
                  <Select {...field} label="Type">
                    <MenuItem value="Functional">Functional Group</MenuItem>
                    <MenuItem value="Location-Based">
                      Location-Based Group
                    </MenuItem>
                    <MenuItem value="Criticality-Based">
                      Criticality-Based Group
                    </MenuItem>
                    <MenuItem value="Vendor/Product">
                      Vendor/Product Group
                    </MenuItem>
                    <MenuItem value="Regulatory Compliance">
                      Regulatory Compliance Group
                    </MenuItem>
                    <MenuItem value="Custom">Custom Group</MenuItem>
                  </Select>
                )}
              />
              {errors.type && (
                <FormHelperText>{errors.type.message}</FormHelperText>
              )}
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Create
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default Groups;
