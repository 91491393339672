import MyCard from 'components/shared/Card/Card';
import theme from 'components/utils/theme';
import Chart from 'react-apexcharts';
import CustomLegend from './CustomLegend';
import GraphTitle from './GraphTitle';
import CustomDialog from 'components/shared/Dialog/CustomDialog';
import { Grid } from 'rsuite';
import { useState } from 'react';


const ScatterChart = () => {
    const arr = [
        {
            label: "Critical Alerts",
            color: theme.palette.error.main
        },
        {
            label: "Moderate Alerts",
            color: theme.palette.warning.main
        },
        {
            label: "No Alerts",
            color: theme.palette.success.main
        },

    ]

    const options = {
        chart: {
            type: 'scatter',
            toolbar: {
                show: false,
            },
            zoom: {
                type: 'xy',
            },
        },
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        },
        legend: {
            show: false,
        },
        colors: [theme.palette.error.main, theme.palette.warning.main, theme.palette.success.main],
        grid: {
            show: true,
            borderColor: '#e0e0e0',
            xaxis: {
                lines: {
                    show: true,
                },
            },
            yaxis: {
                lines: {
                    show: true,
                },
            },
        },
        annotations: {
            shapes: [
                {
                    type: 'line',
                    x1: 'Feb',
                    y1: 30,
                    x2: 'Jul',
                    y2: 90,
                    borderColor: '#0000FF',
                    strokeWidth: 2,
                    opacity: 1,
                    label: {
                        text: 'Central Line',
                        position: 'center',
                        style: {
                            color: '#0000FF',
                            background: '#ffffff',
                        },
                    },
                },
            ],
        },
    };

    const series = [
        {
            name: 'Critical Alerts',
            data: [
                { x: 'Jan', y: 30 },
                { x: 'Feb', y: 25 },
                { x: 'Mar', y: 28 },
                { x: 'Jun', y: 30 },
                { x: 'Sep', y: 30 },
                { x: 'Oct', y: 30 },
                { x: 'Nov', y: 30 },
            ],
            color: theme.palette.error.main,
            markers: {
                shape: 'circle',
                size: 8,
            },
        },
        {
            name: 'Moderate Alerts',
            data: [
                { x: 'Jan', y: 45 },
                { x: 'Feb', y: 50 },
                { x: 'Mar', y: 55 },
                { x: 'Apr', y: 60 },
                { x: 'May', y: 65 },
                { x: 'Jun', y: 50 },
                { x: 'Jul', y: 70 },
                { x: 'Aug', y: 75 },
            ],
            color: theme.palette.warning.main,
            markers: {
                shape: 'triangle', // Yellow triangle for Moderate Alerts
                size: 8,
            },
        },
        {
            name: 'No Alerts',
            data: [
                { x: 'Jan', y: 80 },
                { x: 'Feb', y: 85 },
                { x: 'Feb', y: 80 },
                { x: 'Mar', y: 88 },
                { x: 'Apr', y: 90 },
                { x: 'May', y: 85 },
                { x: 'Jun', y: 90 },
                { x: 'Jul', y: 92 },
                { x: 'Aug', y: 95 },
            ],
            color: theme.palette.success.main,
            markers: {
                shape: 'square',
                size: 8,
            },
        },
    ];

    const [isFullScreen, setIsFullScreen] = useState(false);

    const handleToggleScreen = () => {
        setIsFullScreen((prevState) => {
            return !prevState
        })
    }

    return (
        <>
            <MyCard height={450}>
                <GraphTitle showFullScreenBtn={true} handleClickFullScreen={handleToggleScreen} title={'AI-Driven Anomaly Detection'} />
                <Chart options={options} series={series} type="scatter" height={325} />
                <CustomLegend arr={arr} />
            </MyCard>
            <CustomDialog fullScreen={true} title={'AI-Driven Anomaly Detection'} isOpen={isFullScreen} handleToggle={handleToggleScreen} maxWidth={'xl'}>
                <Grid xs={12} height={'100%'}>
                    <Chart options={options} series={series} type="scatter" height={'490px'} />
                    <CustomLegend arr={arr} />
                </Grid>
            </CustomDialog>
        </>
    );
};

export default ScatterChart;
