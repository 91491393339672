import { Button, Card } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { object } from 'yup';
import { CHANGE_PASSWORD, FORGOT_PASSWORD, USER_LOGIN } from '../../../redux/types/auth.types';
import CustomTextField from 'components/shared/Forms/CustomTextField';
import text from 'components/shared/css/text.module.scss';
import { YupResetPasswordSchema, formResetPasswordSchema } from './helper';
import styles from './forgotpass.module.scss';
import LockIcon from 'static/svgs/Lock';
import { authActions } from '../../../redux/slice/auth.slice';

const ResetPasswordForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector(authActions.selectors.getLoggedInStatus);
  const user = useSelector(authActions.selectors.getCurrentUser);
  let resetPasswordSchema = object(YupResetPasswordSchema);
  const { control, handleSubmit, formState: { errors, isValid } } = useForm(formResetPasswordSchema(resetPasswordSchema));

  const handlePasswordResetSubmit = (payload) => {
    payload={
      ...payload,
      ...user,
      navigate,
    }
    if(auth && user){
      dispatch({ type: CHANGE_PASSWORD, payload });
    }
  };

  return (
    <Card className={styles.formContainer}>
      <div>
        <p className={text.heading1}>Reset Password</p>
        <p className={text.info1}>Enter new password to reset</p>
        <br />
      </div>
      <form onSubmit={handleSubmit(handlePasswordResetSubmit)} className={styles.fieldsContainer}>
        <CustomTextField
          control={control}
          errors={errors}
          name={'newPassword'}
          type="password"
          label="Password"
          icon={LockIcon}
          required={true}
          placeHolder={'*******'}
        />
        <CustomTextField
          control={control}
          errors={errors}
          name={'confirmPassword'}
          type="password"
          label="Confirm Password"
          icon={LockIcon}
          required={true}
          placeHolder={'*******'}
        />
        <br />
        <div className={styles.btnContainer}>
          <Button disabled={!isValid} type="submit" variant='contained' className={styles.loginBtn} >Reset</Button>
        </div>
      </form>
    </Card >
  )
}

export default ResetPasswordForm
