import MyCard from 'components/shared/Card/Card'
import React, { useMemo } from 'react'
import GraphTitle from './GraphTitle'
import ReactApexChart from "react-apexcharts";

const PrevilageAccess = ({ accessPrevilage = { Least: 0, Moderate: 0, Excessive: 0 } }) => {
    const series = useMemo(() => {
        return [
            {
                name: "Number of Users",
                data: [
                    accessPrevilage?.Least || 0,
                    accessPrevilage?.Moderate || 0,
                    accessPrevilage?.Excessive || 0
                ],
            },
        ];
    }, [accessPrevilage]);

    // Configuration options
    const options = {
        chart: {
            type: 'bar',
            height: 250,
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                distributed: true,
                horizontal: false,
                columnWidth: '45%',
                endingShape: 'flat',
            },
        },
        grid: {
            borderColor: '#E0E0E0',
            strokeDashArray: 4,
            xaxis: {
                lines: {
                    show: true,
                }
            },
            yaxis: {
                lines: {
                    show: true,
                }
            }
        },
        colors: ['#00A307', '#FFA500', '#FF0000'],
        dataLabels: {
            enabled: false,
        },
        xaxis: {
            categories: ['Least Privilege', 'Moderate Privilege', 'Excessive Privilege'],
            tickPlacement: 'on',
            axisBorder: {
                show: false,
                color: '#808080',
                height: 1,
                offsetX: 0,
                offsetY: 1,
            },
            axisTicks: {
                show: true,
                borderType: 'solid',
                color: '#808080',
                width: 6
            },
            labels: {
                style: {
                    fontSize: '14px',
                },
            },
        },
        yaxis: {
            tickAmount: 5,
            title: {
                text: 'Number of Users',
                style: {
                    color: '#808080',
                    fontSize: '14px',
                    fontFamily: 'Poppins',
                    fontWeight: 'bold',
                }
            },
            axisBorder: {
                show: false,
                color: '#808080',
                height: 1,
                offsetX: 0,
                offsetY: 1,
            },
            axisTicks: {
                show: true,
                borderType: 'solid',
                color: '#808080',
                width: 6
            },
            labels: {
                style: {
                    fontSize: '14px',
                },
            },
        },
        legend: {
            show: false,
        },
        tooltip: {
            enabled: true,
            y: {
                formatter: function (val) {
                    return `${val}`;
                },
            },
        },
    };

    return (
        <MyCard>
            <GraphTitle title={'Privilege Access Monitoring'} />
            {series && <ReactApexChart options={options} series={series} type="bar" height={250} />}
        </MyCard>
    )
}

export default PrevilageAccess;
