import React from 'react'

const ProcessesIcon = ({ fill = "white", width = "19", height = "19" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.4359 20.5624C18.0492 19.3069 19.3031 18.0447 20.5625 18.4359C20.7451 18.4837 20.9365 18.4098 21.0408 18.2532C21.6931 17.253 22.1497 16.1571 22.3933 15.0047C22.4324 14.8177 22.3454 14.6307 22.1802 14.535C21.6323 14.2263 21.2931 13.6479 21.2931 13.0304C21.2931 12.4129 21.6323 11.8345 22.1802 11.5257C22.3454 11.43 22.4324 11.2431 22.3933 11.056C22.1497 9.90363 21.6931 8.80773 21.0408 7.80752C20.9365 7.65097 20.7451 7.57702 20.5625 7.62488C19.3046 8.01174 18.0497 6.75864 18.436 5.49828C18.4837 5.31569 18.4098 5.12435 18.2533 5.01996C17.2531 4.36764 16.1572 3.91103 15.0047 3.6675C14.8177 3.62837 14.6308 3.71533 14.5351 3.88058C14.2263 4.42853 13.6479 4.76774 13.0304 4.76774C12.4129 4.76774 11.8345 4.42853 11.5257 3.88058C11.4301 3.71533 11.2387 3.62837 11.0561 3.6675C9.90366 3.91103 8.80776 4.36764 7.80755 5.01996C7.651 5.12435 7.57708 5.31569 7.62491 5.49834C7.79016 6.10282 7.62491 6.75078 7.18568 7.18565C6.75081 7.62488 6.1072 7.79014 5.49836 7.62488C5.31573 7.57702 5.12438 7.65098 5.02001 7.80752C4.3677 8.80773 3.91108 9.90363 3.66752 11.056C3.6284 11.243 3.71538 11.43 3.88064 11.5257C4.42856 11.8345 4.76777 12.4128 4.76777 13.0304C4.76777 13.6479 4.42856 14.2263 3.88064 14.535C3.71538 14.6307 3.6284 14.8177 3.66752 15.0047C3.91108 16.1571 4.3677 17.253 5.02001 18.2532C5.12438 18.4098 5.31572 18.4837 5.49836 18.4359C6.10285 18.2663 6.75081 18.4359 7.19004 18.8751C7.62491 19.31 7.79016 19.958 7.62491 20.5624C7.57708 20.7451 7.651 20.9364 7.80755 21.0408C8.80776 21.6931 9.90366 22.1497 11.0561 22.3932C11.2387 22.4324 11.4301 22.3454 11.5257 22.1802C12.1401 21.0121 13.9208 21.015 14.5351 22.1802C14.6134 22.3193 14.7612 22.402 14.9134 22.402C16.1058 22.1917 17.2271 21.7002 18.2533 21.0408C18.4098 20.9364 18.4837 20.7451 18.4359 20.5624ZM13.0304 17.3791C10.6342 17.3791 8.68166 15.4266 8.68166 13.0304C8.92593 7.26047 17.1358 7.26217 17.3792 13.0304C17.3792 15.4266 15.4266 17.3791 13.0304 17.3791Z" fill={fill} />
            <path d="M3.4633 13.0306C3.4633 12.268 2.97083 11.6182 2.28754 11.3855C2.79639 8.04013 4.85372 5.09749 7.84858 3.4708C8.05965 3.35613 8.13779 3.09198 8.02313 2.88091C7.90847 2.66984 7.64346 2.59234 7.43324 2.70636C4.17985 4.47366 1.95029 7.68015 1.41755 11.3213C-0.640864 11.7121 -0.38754 14.7378 1.72386 14.7701C2.68489 14.7701 3.4633 13.9917 3.4633 13.0306Z" fill={fill} />
            <path d="M12.9701 22.5979C12.2113 22.5979 11.5611 23.0909 11.3245 23.7748C7.97963 23.2659 5.03745 21.2087 3.41102 18.2139C3.29636 18.0033 3.03135 17.9251 2.82113 18.0394C2.61007 18.154 2.53192 18.4182 2.64659 18.6293C4.41399 21.8829 7.62051 24.1123 11.2609 24.6449C11.6552 26.7018 14.6771 26.448 14.7096 24.3374C14.7096 23.3807 13.9311 22.5979 12.9701 22.5979Z" fill={fill} />
            <path d="M24.2764 11.1216C22.317 11.1412 21.8849 13.8656 23.7134 14.5065C23.2041 17.851 21.1466 20.7932 18.1522 22.4196C17.757 22.6168 17.9269 23.2534 18.3603 23.2367C21.7516 21.6522 24.0287 18.2198 24.5835 14.5703C26.6411 14.1761 26.3867 11.1539 24.2764 11.1216Z" fill={fill} />
            <path d="M13.0305 3.46331C13.7932 3.46331 14.4431 2.97073 14.6757 2.28739C18.0208 2.79632 20.9635 4.8538 22.5906 7.8488C22.706 8.06178 22.9735 8.13657 23.1805 8.02334C23.3915 7.90868 23.4697 7.64453 23.355 7.43346C21.5871 4.1794 18.3801 1.95008 14.7398 1.41774C14.3491 -0.64089 11.3233 -0.387592 11.291 1.72386C11.291 2.68486 12.0694 3.46331 13.0305 3.46331Z" fill={fill} />
        </svg>

    )
}
export default ProcessesIcon
