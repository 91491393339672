import React from 'react'

const RemoteIcon = ({ fill = "white", width = "12", height = "25" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 16 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.6409 7.26318C14.154 10.4885 11.3597 12.9684 7.99746 12.9684C4.64026 12.9684 1.85611 10.4936 1.35911 7.27331C0.517284 7.88694 0 8.87078 0 9.95097V29.6783C0 31.5091 1.49097 33.0001 3.32678 33.0001H12.6783C14.509 33.0001 16 31.5091 16 29.6783V9.95097C16 8.8505 15.4726 7.86667 14.6409 7.26318ZM12.4146 29.3538C12.1204 29.6479 11.7097 29.8305 11.2583 29.8305H4.74676C3.84406 29.8305 3.10871 29.1002 3.10871 28.1975C3.10871 27.7462 3.29127 27.3354 3.59049 27.0413C3.88464 26.7471 4.29541 26.5646 4.74676 26.5646H11.2583C12.161 26.5646 12.8963 27.2948 12.8963 28.1975C12.8963 28.6489 12.7138 29.0597 12.4146 29.3538ZM12.4146 23.2784C12.1204 23.5725 11.7097 23.7551 11.2583 23.7551H4.74676C3.84406 23.7551 3.10871 23.0248 3.10871 22.1221C3.10871 21.6708 3.29127 21.26 3.59049 20.9658C3.88464 20.6717 4.29541 20.4891 4.74676 20.4891H11.2583C12.161 20.4891 12.8963 21.2194 12.8963 22.1221C12.8963 22.5735 12.7138 22.9842 12.4146 23.2784ZM12.4146 17.3601C12.1204 17.6543 11.7097 17.8368 11.2583 17.8368H4.74676C3.84406 17.8368 3.10871 17.1066 3.10871 16.2039C3.10871 15.7525 3.29127 15.3468 3.59049 15.0476C3.88464 14.7535 4.29541 14.5709 4.74676 14.5709H11.2583C12.161 14.5709 12.8963 15.3012 12.8963 16.2039C12.8963 16.6552 12.7138 17.061 12.4146 17.3601Z" fill={fill} />
            <path d="M7.99709 0.543457C4.85286 0.543457 2.29694 3.10446 2.29694 6.24868C2.29694 9.45313 4.91415 11.9539 7.99709 11.9539C11.085 11.9539 13.7023 9.48582 13.7023 6.24868C13.7023 3.10446 11.1464 0.543457 7.99709 0.543457ZM9.14457 8.47598C8.53542 7.86633 7.46668 7.86633 6.85752 8.47598L6.14041 7.75887C7.16715 6.73161 8.83479 6.73145 9.86168 7.75887L9.14457 8.47598ZM10.3074 7.3129C9.03462 6.04064 6.96757 6.04077 5.69469 7.31315L4.97758 6.59603C6.6459 4.92718 9.35607 4.92681 11.0245 6.59578L10.3074 7.3129ZM11.4702 6.15006C9.55256 4.23238 6.44935 4.23281 4.53186 6.15031L3.81474 5.4332C6.12862 3.11932 9.8732 3.11876 12.1874 5.43295L11.4702 6.15006Z" fill={fill} />
        </svg>
    )
}
export default RemoteIcon
