import React from 'react'

const StatsIcon = ({ fill = "#0C5E9B", width = "22", height = "22" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.843751 27.5H4.461C4.92674 27.5 5.30474 27.1228 5.30474 26.6562V20.4846C5.30474 20.018 4.92674 19.6409 4.461 19.6409H0.843751C0.378001 19.6409 0 20.018 0 20.4846V26.6562C0 27.122 0.378001 27.5 0.843751 27.5ZM1.6875 21.3284H3.61725V25.8125H1.6875V21.3284Z" fill={fill} />
            <path d="M11.6906 27.5C12.1563 27.5 12.5343 27.1228 12.5343 26.6562V9.24072C12.5343 8.77413 12.1563 8.39697 11.6906 8.39697H8.07495C7.6092 8.39697 7.2312 8.77413 7.2312 9.24072V26.6562C7.2312 27.1228 7.6092 27.5 8.07495 27.5H11.6906ZM8.9187 10.0845H10.8468V25.8125H8.9187V10.0845Z" fill={fill} />
            <path d="M18.9218 27.5C19.3875 27.5 19.7655 27.1228 19.7655 26.6562V15.0102C19.7655 14.5436 19.3875 14.1664 18.9218 14.1664H15.3062C14.8404 14.1664 14.4624 14.5436 14.4624 15.0102V26.6562C14.4624 27.1228 14.8404 27.5 15.3062 27.5H18.9218ZM16.1499 15.8539H18.0796V25.8125H16.1499V15.8539Z" fill={fill} />
            <path d="M21.6968 1.34375V26.6562C21.6968 27.1228 22.0748 27.5 22.5405 27.5H26.1562C26.6219 27.5 26.9999 27.1228 26.9999 26.6562V1.34375C26.9999 0.877156 26.6219 0.5 26.1562 0.5H22.5405C22.0748 0.5 21.6968 0.877156 21.6968 1.34375ZM23.3843 2.1875H25.3124V25.8125H23.3843V2.1875Z" fill={fill} />
        </svg>


    )
}
export default StatsIcon
