import React from "react";
import { TextField, MenuItem, Box, Grid, Typography, Button } from "@mui/material";
import styles from "./UserForm.module.scss";
import { UPDATE_USER } from "../../../../redux/types/auth.types";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";

const UserForm = ({ email, gender, username }) => {
  const dispatch = useDispatch();
  const { handleSubmit, control } = useForm();

  const onSubmit = (data) => {
    dispatch({ type: UPDATE_USER, payload: data });
  };

  return (
    <Box className={styles.formContainer}>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" className={styles.label}>
              Username<span className={styles.required}>*</span>
            </Typography>
            <Controller
              name="username"
              control={control}
              defaultValue={username}
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="Username"
                  required
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    sx: { height: 40, borderRadius: 2 },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" className={styles.label}>
              Email<span className={styles.required}>*</span>
            </Typography>
            <Controller
              name="email"
              control={control}
              defaultValue={email}
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="Email"
                  required
                  type="email"
                  fullWidth
                  variant="outlined"
                  disabled
                  InputProps={{
                    sx: { height: 40, borderRadius: 2 },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" className={styles.label}>
              Gender<span className={styles.required}>*</span>
            </Typography>
            <Controller
              name="gender"
              control={control}
              defaultValue={gender}
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="Gender"
                  required
                  select
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    sx: { height: 40, borderRadius: 2 },
                  }}
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </TextField>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
              <Button type="submit" variant="contained" color="primary" sx={{ height: 35, borderRadius: "20px" }}>
                Save Changes
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default UserForm;
