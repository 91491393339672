import { Button, Card, Grid, Typography } from "@mui/material";
import CustomTitle from "components/shared/CustomTitle";
import MyTable from "components/shared/Tables/MyTable/MyTable";
import MyTableToolbar from "components/shared/Tables/MyTable/MyTableToolbar";
import { handleDownloadCsv, handlePrintCsv } from "components/utils/handlers";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "static/svgs/Delete";
import RemoteIcon from "static/svgs/remote.js";
import { alertsActions } from "../../../redux/slice/alerts.slice";
import { analyticsActions } from "../../../redux/slice/analytics.slice";
import { devicesActions } from "../../../redux/slice/devices.slice";
import { groupSelectors } from "../../../redux/slice/group.slice";
import { GET_DEVICES, GET_DEVICES_LOCATION } from "../../../redux/types/devices.types";
import { GET_ACCESS_PREVILAGE, GET_ALL_ALERTS, GET_HIGH_RISK_DEVICES, GET_MULTI_DASHBOARD_ANALYTICS, GET_USER_ROLES } from "../../../redux/types/multi.types";
import buttons from '../../shared/css/buttons.module.scss';
import text from '../../shared/css/text.module.scss';
import table from '../../shared/Tables/table.module.scss';
import theme from "../../utils/theme";
import DeviceMap from "../Device/DeviceMap";
import { getMarker } from "../Device/helper";
import DeviceStatusChart from "./DeviceStatusChart";
import { highRiskColumns, WarningIcon } from "./helper";
import PrevilageAccess from "./PrevilageAccess";
import ScatterChart from "./ScatterChart";
import SecurityAlerts from "./SecurityAlerts";
import ThreatComparison from "./ThreatComparison";
import ThreatDetection from "./ThreatDetection";
import UserRoleDistribution from "./UserRoleDistribution";
import VulnerabilityScanning from "./VulnerabilityScanning";
import { LocalPolice } from "@mui/icons-material";

const MultiDashboard = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const payload = {
      orgId: process.env.REACT_APP_ORG_ID,
    };
    dispatch({ type: GET_DEVICES, payload });
    dispatch({ type: GET_MULTI_DASHBOARD_ANALYTICS });
    dispatch({ type: GET_DEVICES_LOCATION, payload });
    dispatch({ type: GET_HIGH_RISK_DEVICES, payload });
  }, [dispatch]);


  const devices = useSelector(devicesActions.selectors.getDevices);
  const devicesLocs = useSelector(devicesActions.selectors.getDeviceLocation);

  const markers = useMemo(() => {
    if (devicesLocs?.length) {
      return devicesLocs.map((device) => {
        const loc = device?.loc;
        if (loc) {
          const coordinates = loc.split(",").map(Number)?.reverse();
          const html = getMarker(theme.palette.success.main);
          return { coordinates, html };
        }
        return null
      })
    }
  }, [devicesLocs]);

  const allAlerts = useSelector(alertsActions.selectors.getAllAlerts)
  const accessPrevilage = useSelector(groupSelectors.getAccessPrevilage)
  const userRoles = useSelector(analyticsActions.selectors.getUserRoles)
  const highRiskDevices = useSelector(analyticsActions.selectors.getHighRiskDevices)
  console.log("highRiskDevices", highRiskDevices)
  useEffect(() => {
    dispatch({ type: GET_ALL_ALERTS })
    dispatch({ type: GET_ACCESS_PREVILAGE })
    dispatch({ type: GET_USER_ROLES })
  }, [dispatch]);


  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const filteredDevices = useMemo(() => {
    if (!searchTerm) return highRiskDevices;
    const regex = new RegExp(searchTerm, "i");
    return highRiskDevices.filter((device) =>
      Object.values(device).some((value) => regex.test(value))
    );
  }, [highRiskDevices, searchTerm]);

  return (
    <div>
      <Grid
        container
        sx={{ justifyContent: { xl: "space-between", md: "center" } }}
        spacing={2}
      >
        <Grid item xs={12} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
          <Typography className={text.heading3}>Multiple Device Dashboard</Typography>
          <Button startIcon={<RemoteIcon fill={theme.palette.primary.main} height={20} />} className={buttons.outlined} variant="outlined">
            Multiple Device Console
          </Button>
        </Grid>
        <Grid my={"5px"} container spacing={2}>
          <Grid item md={12} lg={6}>
            <ScatterChart />
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <ThreatDetection />
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <DeviceMap markers={markers} center={devicesLocs?.[0]?.loc} />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <VulnerabilityScanning />
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          <ThreatComparison alerts={allAlerts?.chartData} />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <DeviceStatusChart devices={devices} />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <SecurityAlerts alerts={allAlerts} />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <PrevilageAccess accessPrevilage={accessPrevilage} />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <UserRoleDistribution userRoles={userRoles} />
        </Grid>

        <Grid item xs={12} className={table.tableContainer}>
          <CustomTitle title={"High Risk Devices"} count={highRiskDevices?.length} />
          <Card>
            <MyTableToolbar
              placeHolder={"Search For High Risk Devices"}
              onSearch={handleSearch}
              onDownload={() => handleDownloadCsv(highRiskDevices, highRiskColumns)}
              onPrint={() => handlePrintCsv(highRiskDevices, highRiskColumns)}
              btn2Txt={"Quarantine Device"}
              btn2Icon={LocalPolice}
              btn1Txt={"Issue Warning"}
              btn1Icon={WarningIcon}
            />
            <MyTable
              checkboxSelection={true}
              columns={highRiskColumns}
              rows={filteredDevices}
              id={(row) => row?._id}
            />
          </Card>
        </Grid>

      </Grid>


      <br />
    </div>
  );
};

export default MultiDashboard;
