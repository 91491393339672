import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import { Box, Typography } from '@mui/material';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import styles from './ConnectionsCard.module.scss';
import { CONNECTION_CHART_OPTION } from 'components/utils/networks';

const ConnectionsCard = () => {
  const [value, setValue] = useState([new Date('2022-04-17'), new Date('2022-04-21')]);

  return (
    <Box className={styles.container}>
      <Box className={styles.header}>
        <Typography variant="h6" className={styles.title}>
          Connections
        </Typography>
        <Box className={styles.dateSelector}>
          <DateRangePicker
            value={value}
            onChange={setValue}
            size="sm"
            placeholder="Start - End Date"
            className={styles.dateRangePicker}
          />
        </Box>
      </Box>
      <Box className={styles.chartContainer}>
        <Chart
          options={CONNECTION_CHART_OPTION}
          series={CONNECTION_CHART_OPTION.series}
          type="bar"
          height={200}
        />
        <Box className={styles.legend}>
          <Box className={styles.legendItem}>
            <Typography variant="body2" className={styles.label}>In Use</Typography>
            <Typography variant="body2" className={styles.value}>
              8.9 <span className={styles.dot} style={{ backgroundColor: '#0C5E9B' }}></span>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ConnectionsCard;
