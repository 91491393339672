import { Box, Grid } from '@mui/material';
import KeyFeatures from 'components/modules/Networks/NetworkKeyFeatures/KeyFeatures/KeyFeatures';
import LiveDevicesMap from 'components/modules/Networks/NetworkKeyFeatures/LiveDevicesMap/LiveDevicesMap';
import MapSummaryTable from 'components/modules/Networks/NetworkKeyFeatures/MapSummary/MapSummaryTable';
import SingleTopologyDetail from 'components/modules/Networks/NetworkKeyFeatures/TopologyDetails/TopologyDetails';
import CustomTitle from 'components/shared/CustomTitle';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { sendToSocket, setUpSingleNetworksocket, unSubscribeSocketTopics } from '../../../redux/saga/socket';
import { networkDeviceActions } from '../../../redux/slice/networkDevice.slice';
import { uiActions } from '../../../redux/slice/ui.slice';
import { GET_SINGLE_NETWORKS_DEVICES } from '../../../redux/types/networkDevice.types';
import MultiTopology from '../Networks/NetworkKeyFeatures/TopologyDetails/MultiTopology';
import CustomDialog from 'components/shared/Dialog/CustomDialog';

const SingleNetwork = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const selectedNetwork = useSelector(networkDeviceActions.selectors.getSelectedNetwork);

    useEffect(() => {
        dispatch(uiActions.actions.setSelectedNav(""));

        if (id) {
            const payload = {
                networkId: id
            }
            dispatch({ type: GET_SINGLE_NETWORKS_DEVICES, payload })
            sendToSocket("GET_SINGLE_NETWORK", payload)
        }
    }, [dispatch, id]);

    useEffect(() => {
        setUpSingleNetworksocket(dispatch);

        return () => {
            unSubscribeSocketTopics(["SINGLE_NETWORK_RESP"]);
        };
    }, [dispatch])

    const [isFullScreen, setIsFullScreen] = useState(false);
    const handleFullScreen = () => {
        setIsFullScreen(!isFullScreen);
    };



    return (
        <Grid container spacing={2} >
            <Grid item xs={12}>
                <CustomTitle title={"Network Details"} isCount={false} />
            </Grid>
            <Grid item xs={12}>
                <KeyFeatures connections={selectedNetwork?.connections} />
            </Grid>
            <Grid item xs={12} md={8} sx={{ position: 'relative' }}>
                <MultiTopology xOffset={0} yOffset={70} isFullScreen={isFullScreen} deviceNetworks={[selectedNetwork]} handleFullScreen={handleFullScreen} />
            </Grid>
            <Grid item xs={12} md={4}>
                <LiveDevicesMap />
            </Grid>

            <Grid item xs={12} md={12} sm={12}>
                <CustomTitle title={"Devices List"} isCount={false} />
                <MapSummaryTable deviceNetworks={[selectedNetwork]} isSingle={true} />
            </Grid>
            <CustomDialog fullScreen={true} title={'List Topology Details'} isOpen={isFullScreen} handleToggle={handleFullScreen} maxWidth={'xl'}>
                <Grid xs={12} height={'100%'}>
                    <MultiTopology xOffset={10} yOffset={115} isFullScreen={isFullScreen} deviceNetworks={[selectedNetwork]} handleFullScreen={handleFullScreen} />
                </Grid>
            </CustomDialog>
        </Grid>
    )
}

export default SingleNetwork
