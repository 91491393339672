import React from "react";
import { Toaster } from "react-hot-toast";
import Sidebar from "../shared/sidebar/Sidebar";
import Navbar from "../shared/navbar/Navbar";
import styles from "../shared/css/layout.module.scss";
const Layout = ({ children }) => {
  return (
    <>
      <Toaster position="top-center" />

      <Sidebar>
        <>
          <Navbar />
          <div className={styles.main}>{children}</div>
        </>
      </Sidebar>
    </>
  );
};

export default Layout;
