import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import {
    Box,
    Checkbox,
    Collapse,
    FormControlLabel,
    Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import styles from "./Policies.module.scss";

const PolicyCheckerItem = ({ groupName, permissions, selectedPermissions, onSelectionChange }) => {
    const [expanded, setExpanded] = useState(false);
    const [localSelectedPermissions, setLocalSelectedPermissions] = useState([]);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleParentCheckboxChange = (event) => {
        const checked = event.target.checked;
        const updatedSelection = checked
            ? [...selectedPermissions, ...permissions.filter(permission => !selectedPermissions.includes(permission))]
            : selectedPermissions.filter(permission => !permissions.includes(permission));

        onSelectionChange(updatedSelection);
    };

    const handlePermissionChange = (permission) => {
        const isSelected = localSelectedPermissions.includes(permission);
        const updatedSelection = isSelected
            ? selectedPermissions.filter((item) => item !== permission)
            : [...selectedPermissions, permission];

        onSelectionChange(updatedSelection);
    };

    useEffect(() => {
        setLocalSelectedPermissions(
            permissions.filter(permission => selectedPermissions.includes(permission))
        );
    }, [permissions, selectedPermissions]);

    return (
        <Box>
            <Box className={styles.permissionTitle} display={'flex'} alignItems={'center'} sx={{ cursor: 'pointer' }}>
                <FormControlLabel
                    sx={{ m: 0 }}
                    control={
                        <Checkbox
                            checked={localSelectedPermissions.length === permissions.length}
                            indeterminate={localSelectedPermissions.length > 0 && localSelectedPermissions.length < permissions.length}
                            onChange={handleParentCheckboxChange}
                        />
                    }
                    label={
                        <Typography >{groupName}</Typography>
                    }
                />
                {expanded ?
                    <KeyboardArrowUp onClick={handleExpandClick} />
                    :
                    <KeyboardArrowDown onClick={handleExpandClick} />
                }
            </Box>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Box sx={{ pl: 4 }} display={'flex'} flexDirection={'column'}>
                    {permissions.map((permission, index) => (
                        <FormControlLabel
                            key={index}
                            className={styles.permissionSub}
                            control={
                                <Checkbox
                                    checked={selectedPermissions.includes(permission)}
                                    onChange={() => handlePermissionChange(permission)}
                                />
                            }
                            label={permission}
                        />
                    ))}
                </Box>
            </Collapse>
        </Box>
    );
};

export default PolicyCheckerItem;
