import { yupResolver } from "@hookform/resolvers/yup";
import { string } from "yup";

export const YupLoginSchema = {
    email: string().email().required(),
    password: string().required()
}

export const formLoginSchema = (loginSchema) => {
    return {
        resolver: yupResolver(loginSchema),
        mode: 'onChange',
        defaultValues: {
            email: '',
            password: '', 
        }
    }
}


export const YupTwoFaSchema = {
    otp: string()
        .required('Code is required')
        .matches(/^\d{4}$/, 'Code must be exactly 4 digits'),
}

export const formTwoFaSchema = (twoFaSchema) => {
    return {
        resolver: yupResolver(twoFaSchema),
        mode: 'onChange',
        defaultValues: {
            otp: '',
        }
    }
}