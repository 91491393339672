import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
    files: []
}

const name = 'files'
const parentSelector = state => state?.[name]

const filesSlice = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        resetFiles: () => {
            return initialState;
        },
        setFiles: (state, action) => {
            return {
                ...state,
                files: action.payload,
            };
        }
    },
});


const getFiles = createSelector(parentSelector, state => {
    return state?.files
})


const actions = {
    ...filesSlice.actions
}

const selectors = {
    getFiles
}

export const filesActions = { actions, selectors }


export default filesSlice;