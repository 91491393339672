import { Grid } from "@mui/material";
import DeviceMap from "components/modules/Device/DeviceMap";
import { getMarker } from "components/modules/Device/helper";
import CustomTitle from "components/shared/CustomTitle";
import CustomDialog from "components/shared/Dialog/CustomDialog";
import theme from "components/utils/theme";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNetowrkDevicesSocket, setUpNetworkDevicesocket, unSubscribeSocketTopics } from "../../../../redux/saga/socket";
import { networkDeviceActions } from "../../../../redux/slice/networkDevice.slice";
import { GET_ALL_NETWORK_LOCATIONS, GET_NETWORKS_DEVICES } from "../../../../redux/types/networkDevice.types";
import MapSummaryTable from "./MapSummary/MapSummaryTable";
import MultiTopology from "./TopologyDetails/MultiTopology";

const NetworkKeyFeatures = () => {
  const dispatch = useDispatch();

  const deviceNetworks = useSelector(networkDeviceActions.selectors.getNetworkDevices);
  const devicesLocs = useSelector(networkDeviceActions.selectors.getAllDevicesLocation)

  useEffect(() => {
    if (deviceNetworks?.length) {
      const payload = {
        orgId: process.env.REACT_APP_ORG_ID,
      }
      dispatch({ type: GET_ALL_NETWORK_LOCATIONS, payload })
    }
  }, [dispatch, deviceNetworks?.length]);


  useEffect(() => {
    //Subscribe Socket
    setUpNetworkDevicesocket(dispatch);

    //Call API
    const payload = {
      orgId: process.env.REACT_APP_ORG_ID,
    };
    dispatch({ type: GET_NETWORKS_DEVICES, payload });

    //Call Socket
    getNetowrkDevicesSocket({
      orgId: process.env.REACT_APP_ORG_ID,
    });

    return () => {
      unSubscribeSocketTopics(["NETWORK_DEVICES"]);
    };
  }, [dispatch]);

  const markers = useMemo(() => {
    if (devicesLocs?.length) {
      return devicesLocs.map((device) => {
        const loc = device?.loc;
        if (loc) {
          const coordinates = loc.split(",").map(Number)?.reverse();
          const html = getMarker(theme.palette.success.main);
          return { coordinates, html };
        }
        return null
      })
    }
  }, [devicesLocs]);

  const [isFullScreen, setIsFullScreen] = useState(false);
  const handleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomTitle title="Networks" isCount={false} />
        </Grid>
        <Grid item xs={12} md={8} position={'relative'}>
          <MultiTopology xOffset={0} yOffset={70} isFullScreen={isFullScreen} deviceNetworks={deviceNetworks} handleFullScreen={handleFullScreen} />
        </Grid>
        <Grid item height={'100%'} xs={12} md={4}>
          <DeviceMap markers={markers} center={devicesLocs?.[0]?.loc} height="418px" />
        </Grid>

        <Grid item xs={12} md={12} sm={12}>
          <CustomTitle title={"All Networks"} count={deviceNetworks?.length} />
          <MapSummaryTable deviceNetworks={deviceNetworks} />
        </Grid>
      </Grid >
      <CustomDialog fullScreen={true} title={'List Topology Details'} isOpen={isFullScreen} handleToggle={handleFullScreen} maxWidth={'xl'}>
        <Grid xs={12} height={'100%'}>
          <MultiTopology xOffset={10} yOffset={115} isFullScreen={isFullScreen} deviceNetworks={deviceNetworks} handleFullScreen={handleFullScreen} />
        </Grid>
      </CustomDialog>
    </>
  );
};

export default NetworkKeyFeatures;
