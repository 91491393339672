import { createSlice } from "@reduxjs/toolkit";
import toast from 'react-hot-toast';

const initialState = {
  alertSettings: null,
  status: "idle",
  error: null,
};

const alertSettingsSlice = createSlice({
  name: "alertSettings",
  initialState,
  reducers: {
    fetchAlertSettingsRequest: (state) => {
      state.status = "loading";
    },
    fetchAlertSettingsSuccess: (state, action) => {
      state.status = "succeeded";
      state.alertSettings = action.payload;
    },
    fetchAlertSettingsFailure: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    updateAlertSettingsRequest: (state) => {
      state.status = "loading";
    },
    updateAlertSettingsSuccess: (state, action) => {
      state.status = "succeeded";
      toast.success('Settings Updated');
      state.alertSettings = action.payload;
    },
    updateAlertSettingsFailure: (state, action) => {
      state.status = "failed";
      toast.error("Something went wrong");
      state.error = action.payload;
    },
  },
});

export const alertSettingsActions = alertSettingsSlice.actions;

export default alertSettingsSlice;
