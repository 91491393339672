import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  Tooltip
} from "@mui/material";
import { Add, Search, ViewList, Delete, DeleteForever } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import styles from "./Group.module.scss";
import { format, isValid } from "date-fns";
import { customTableStyles } from "../../../../../shared/Tables/helper";
import DownloadIcon from "static/svgs/Download";
import PrintIcon from "static/svgs/Print";
import BarsIcon from "static/svgs/Bars";
import {
  removeDeviceFromGroupService,
  removePolicyFromGroupService,
  removeUserFromGroupService,
  blacklistAppInGroupService,
  removeAlertPolicyFromGroupService,
  removeGroupService
} from "../../../../../../redux/services/group.service";
import { handleDownloadCsv, handlePrintCsv } from "components/utils/handlers";
import toast from "react-hot-toast";

const Group = ({ type, description, rows, handleOpen, refreshGroups }) => {
  const [pageSize, setPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [viewDetailsOpen, setViewDetailsOpen] = useState(false);
  const [currentGroup, setCurrentGroup] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmData, setConfirmData] = useState({ type: "", id: "" });
  const [selectedAppIds, setSelectedAppIds] = useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null)
  const [filteredRows, setFilteredRows] = useState(rows);

  useEffect(() => {
    setFilteredRows(rows)
  }, [rows])

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setFilteredRows(rows.filter((row) =>
      row?.name?.toLowerCase().includes(event.target.value?.toLowerCase())
    ));
  };

  const handleViewDetailsOpen = (group) => {
    setCurrentGroup(group);
    const blacklistedIds = group.blacklistedApplications.map(app => app.id);
    setSelectedAppIds(blacklistedIds || []);
    setViewDetailsOpen(true);
  };

  const handleViewDetailsClose = () => {
    setCurrentGroup(null);
    setViewDetailsOpen(false);
  };

  const handleRemove = async () => {
    try {
      if (confirmData.type === "user") {
        await removeUserFromGroupService(currentGroup._id, confirmData.id);
        setCurrentGroup({
          ...currentGroup,
          users: currentGroup.users.filter((user) => user._id !== confirmData.id),
        });
      } else if (confirmData.type === "policy") {
        await removePolicyFromGroupService(currentGroup._id, confirmData.id);
        setCurrentGroup({
          ...currentGroup,
          policies: currentGroup.policies.filter((policy) => policy._id !== confirmData.id),
        });
      } else if (confirmData.type === "device") {
        await removeDeviceFromGroupService(currentGroup._id, confirmData.id);
        setCurrentGroup({
          ...currentGroup,
          devices: currentGroup.devices.filter((device) => device._id !== confirmData.id),
        });
      } else if (confirmData.type === "alert-policy") {
        await removeAlertPolicyFromGroupService(currentGroup._id, confirmData.id);
        setCurrentGroup({
          ...currentGroup,
          alertPolicies: currentGroup.alertPolicies.filter((alertPolicy) => alertPolicy._id !== confirmData.id),
        });
      }
      setConfirmOpen(false);
      refreshGroups();
    } catch (error) {
      console.error(`Failed to remove ${confirmData.type} from group`, error);
    }
  };

  const handleConfirmOpen = (type, id) => {
    setConfirmData({ type, id });
    setConfirmOpen(true);
  };

  const handleConfirmClose = () => {
    setConfirmData({ type: "", id: "" });
    setConfirmOpen(false);
  };

  const handleBlacklistChange = async (appId) => {
    try {
      let updatedAppIds = [];
      if (selectedAppIds.includes(appId)) {
        updatedAppIds = selectedAppIds.filter(id => id !== appId);
      } else {
        updatedAppIds = [...selectedAppIds, appId];
      }
      setSelectedAppIds(updatedAppIds);
      const response = await blacklistAppInGroupService(currentGroup._id, updatedAppIds);
      if (response) {
        refreshGroups();
      }
    } catch (error) {
      console.error("Failed to update blacklist apps", error);
    }
  };

  const handleDeleteOpen = (id) => {
    setSelectedGroup(id)
    setDeleteOpen(true)
  }

  const handleDeleteClose = () => {
    setDeleteOpen(false)
  }

  const handleDeleteGroup = async () => {
    toast.promise(
      removeGroupService(selectedGroup),
      {
        loading: 'Deleting group...',
        success: 'Group deleted successfully',
        error: 'Failed to delete group',
      }
    ).then(() => {
      setFilteredRows((prevRows) => prevRows.filter(group => group._id !== selectedGroup));
      setDeleteOpen(false);
      refreshGroups();
      setSelectedGroup(null);
    }).catch((error) => {
      console.error("Failed to delete group", error);
    });
  };

  const columns = [
    { field: "name", headerName: "Group Name", flex: 1 },
    {
      field: "users",
      headerName: "Users",
      flex: 1,
      valueGetter: (params) => params?.length || 0,
    },
    {
      field: "devices",
      headerName: "Devices",
      flex: 1,
      valueGetter: (params) => params?.length || 0,
    },
    {
      field: "policies",
      headerName: "Policies",
      flex: 1,
      valueGetter: (params) => params?.length || 0,
    },
    {
      field: "applications",
      headerName: "Apps",
      flex: 1,
      valueGetter: (params) => params?.length || 0,
    },
    {
      field: "alertPolicies",
      headerName: "Alert Policies",
      flex: 1,
      valueGetter: (params) => params?.length || 0,
    },
    {
      field: "createdAt",
      headerName: "Creation Time",
      flex: 1,
      valueGetter: (params) => {
        const date = new Date(params);
        return isValid(date)
          ? format(date, "d MMMM, yyyy h:mmaaa")
          : "Invalid date";
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <>
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              handleViewDetailsOpen(params.row);
            }}
          >
            <ViewList />
          </IconButton>
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              handleDeleteOpen(params.row._id);
            }}
          >
            <Delete />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <Box className={styles.container}>
      <Box className={styles.headerContainer}>
        <Box className={styles.textContainer}>
          <Typography variant="h6" className={styles.header}>
            {type}
          </Typography>
          <Box className={styles.subheaderContainer}>
            <Typography className={styles.subheader}>{description}</Typography>
          </Box>
        </Box>
        <Box className={styles.controls}>
          <Box className={styles.iconButtons}>
            <IconButton className={styles.iconBtn} onClick={() => handleDownloadCsv(filteredRows, columns, false, true)}>
              <DownloadIcon />
            </IconButton>
            <IconButton className={styles.iconBtn} onClick={() => handlePrintCsv(rows, columns, false, true)}>
              <PrintIcon />
            </IconButton>
            <IconButton className={styles.iconBtn}>
              <BarsIcon />
            </IconButton>
          </Box>
          <Box className={styles.actionButtons}>
            <Button
              variant="contained"
              size="small"
              className={styles.createButton}
              onClick={handleOpen}
            >
              Create Groups
              <Add className={styles.icon} />
            </Button>
          </Box>
        </Box>
      </Box>
      <Box className={styles.tableControls}>
        <TextField
          variant="outlined"
          placeholder="Search Group"
          size="small"
          value={searchQuery}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <IconButton size="small">
                <Search />
              </IconButton>
            ),
          }}
          className={styles.search}
        />
      </Box>
      <Box sx={{ width: "100%", height: "450px" }}>
        <DataGrid
          rows={filteredRows}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 30]}
          pagination
          sx={customTableStyles}
          checkboxSelection
          disableColumnSorting
          disableColumnMenu
          disableRowSelectionOnClick
          getRowId={(row) => row._id}
        />
      </Box>

      {/* View Group Details Dialog */}
      <Dialog
        open={viewDetailsOpen}
        onClose={handleViewDetailsClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Group Details</DialogTitle>
        <DialogContent>
          <Typography variant="h6">Policies</Typography>
          <Box sx={{ width: "100%", height: "300px", mb: 2 }}>
            <DataGrid
              rows={currentGroup?.policies || []}
              columns={[
                { field: "name", headerName: "Policy Name", flex: 1 },
                {
                  field: "permissions",
                  headerName: "Permissions",
                  flex: 4,
                  renderCell: (params) => (
                    <Tooltip title={params.value.join(", ")}>
                      <span>{params.value.join(", ")}</span>
                    </Tooltip>
                  ),
                },
                {
                  field: "actions",
                  headerName: "Actions",
                  flex: 1,
                  renderCell: (params) => (
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation();
                        handleConfirmOpen("policy", params?.id);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  ),
                },
              ]}
              pageSize={5}
              rowsPerPageOptions={[5, 10, 15]}
              pagination
              sx={customTableStyles}
              getRowId={(row) => row._id}
              rowSelection={false}
            />
          </Box>
          <Typography variant="h6">Users</Typography>
          <Box sx={{ width: "100%", height: "300px", mb: 2 }}>
            <DataGrid
              rows={currentGroup?.users || []}
              columns={[
                { field: "username", headerName: "User Name", flex: 1 },
                { field: "email", headerName: "Email", flex: 4 },
                {
                  field: "actions",
                  headerName: "Actions",
                  flex: 1,
                  renderCell: (params) => (
                    <IconButton
                      onClick={() => handleConfirmOpen("user", params.id)}
                    >
                      <Delete />
                    </IconButton>
                  ),
                },
              ]}
              pageSize={5}
              rowsPerPageOptions={[5, 10, 15]}
              pagination
              sx={customTableStyles}
              getRowId={(row) => row._id}
              rowSelection={false}
            />
          </Box>
          <Typography variant="h6">Devices</Typography>
          <Box sx={{ width: "100%", height: "300px" }}>
            <DataGrid
              rows={currentGroup?.devices || []}
              columns={[
                { field: "deviceId", headerName: "Device ID", flex: 1 },
                { field: "assetId", headerName: "Asset ID", flex: 4 },
                {
                  field: "actions",
                  headerName: "Actions",
                  flex: 1,
                  renderCell: (params) => (
                    <IconButton
                      onClick={() =>
                        handleConfirmOpen("device", params.id)
                      }
                    >
                      <Delete />
                    </IconButton>
                  ),
                },
              ]}
              pageSize={5}
              rowsPerPageOptions={[5, 10, 15]}
              pagination
              sx={customTableStyles}
              getRowId={(row) => row._id}
              rowSelection={false}
            />
          </Box>
          <Typography variant="h6">Applications</Typography>
          <Box sx={{ width: "100%", height: "300px" }}>
            <DataGrid
              rows={currentGroup?.applications || []}
              columns={[
                { field: "Name", headerName: "Name", flex: 1 },
                { field: "Version", headerName: "Version", flex: 1 },
                { field: "InstallLocation", headerName: "InstallLocation", flex: 1 },
                {
                  field: "blacklist",
                  headerName: "Blacklist",
                  flex: 1,
                  renderCell: (params) => (
                    <Checkbox
                      checked={selectedAppIds.includes(params.row.id)}
                      onChange={() => handleBlacklistChange(params.row.id)}
                    />
                  ),
                },
              ]}
              pageSize={5}
              rowsPerPageOptions={[5, 10, 15]}
              pagination
              sx={customTableStyles}
              getRowId={(row) => row?.id}
              rowSelection={false}
            />
          </Box>
          <Typography variant="h6">Alert Policies</Typography>
          <Box sx={{ width: "100%", height: "300px" }}>
            <DataGrid
              rows={currentGroup?.alertPolicies || []}
              columns={[
                { field: "policyName", headerName: "Policy Name", flex: 1 },
                {
                  field: "details",
                  headerName: "Permissions",
                  flex: 4,
                  renderCell: (params) => (
                    <Tooltip title={params.value.join(", ")}>
                      <span>{params.value.join(", ")}</span>
                    </Tooltip>
                  ),
                },
                {
                  field: "actions",
                  headerName: "Actions",
                  flex: 1,
                  renderCell: (params) => (
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation();
                        handleConfirmOpen("alert-policy", params.row?._id);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  ),
                },
              ]}
              pageSize={5}
              rowsPerPageOptions={[5, 10, 15]}
              pagination
              sx={customTableStyles}
              getRowId={(row) => row?._id}
              rowSelection={false}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleViewDetailsClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirm Remove Dialog */}
      <Dialog open={confirmOpen} onClose={handleConfirmClose} maxWidth="xs" fullWidth>
        <DialogTitle>Confirm Remove</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to remove this {confirmData.type} from the group?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleRemove} color="primary">
            Remove
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Group */}
      <Dialog
        open={deleteOpen}
        onClose={handleDeleteClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Delete Group</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this group?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteGroup} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Group;
