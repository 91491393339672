import React from 'react'

const ProfileIcon = ({ fill = "#666666", width = "18", height = "18" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.3831 10.6974C14.2653 9.86989 14.8871 8.78095 15.1663 7.57468C15.4454 6.3684 15.3688 5.10167 14.9465 3.94209C14.5241 2.78251 13.776 1.78474 12.8012 1.08082C11.8263 0.376898 10.6706 0 9.48701 0C8.30342 0 7.14773 0.376898 6.17286 1.08082C5.19799 1.78474 4.44989 2.78251 4.02756 3.94209C3.60523 5.10167 3.52858 6.3684 3.80774 7.57468C4.0869 8.78095 4.70871 9.86989 5.59091 10.6974C3.95882 11.2968 2.54441 12.4127 1.54294 13.8912C0.541468 15.3696 0.00234372 17.1377 0 18.9512C0 19.2294 0.105357 19.4961 0.292893 19.6928C0.48043 19.8895 0.734783 20 1 20C1.26522 20 1.51957 19.8895 1.70711 19.6928C1.89464 19.4961 2 19.2294 2 18.9512C2.00172 17.1854 2.67136 15.4924 3.86196 14.2437C5.05257 12.9951 6.66688 12.2928 8.35065 12.291H10.6494C12.3331 12.2928 13.9474 12.9951 15.138 14.2437C16.3286 15.4924 16.9983 17.1854 17 18.9512C17 19.2294 17.1054 19.4961 17.2929 19.6928C17.4804 19.8895 17.7348 20 18 20C18.2652 20 18.5196 19.8895 18.7071 19.6928C18.8946 19.4961 19 19.2294 19 18.9512C18.9958 17.1344 18.4529 15.3639 17.4465 13.885C16.4401 12.4062 15.0201 11.2922 13.3831 10.6974ZM9.48701 2.15076C10.2435 2.15076 10.9829 2.38601 11.6119 2.82676C12.2409 3.26751 12.7311 3.89397 13.0206 4.62691C13.31 5.35985 13.3858 6.16636 13.2382 6.94444C13.0906 7.72253 12.7264 8.43724 12.1915 8.99821C11.6566 9.55918 10.9751 9.9412 10.2332 10.096C9.49125 10.2507 8.72224 10.1713 8.02337 9.86772C7.3245 9.56412 6.72717 9.05 6.30691 8.39038C5.88665 7.73075 5.66234 6.95523 5.66234 6.16191C5.66234 5.63515 5.76126 5.11356 5.95347 4.62691C6.14568 4.14025 6.4274 3.69807 6.78256 3.3256C7.49982 2.57337 8.47264 2.15076 9.48701 2.15076Z" fill={fill} />
        </svg>
    )
}
export default ProfileIcon
