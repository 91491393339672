import React from 'react'

const UninstallIcon = ({ fill = "white", width = "19", height = "19" }) => {
    return (
        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.3994 15.2C11.3994 14.6753 11.8247 14.25 12.3494 14.25H13.2994C13.8241 14.25 14.2494 14.6753 14.2494 15.2C14.2494 15.7247 13.8241 16.15 13.2994 16.15H12.3494C11.8247 16.15 11.3994 15.7247 11.3994 15.2Z" fill="#0C5E9B" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.9 2.85C1.9 2.32533 2.32533 1.9 2.85 1.9H3.8C4.32467 1.9 4.75 1.47467 4.75 0.95C4.75 0.425334 4.32467 0 3.8 0H2.85C1.27599 0 0 1.27599 0 2.85V16.15C0 17.7241 1.27599 19 2.85 19H14.25C15.8241 19 17.1 17.7241 17.1 16.15V2.85C17.1 1.27599 15.8241 0 14.25 0H13.3C12.7753 0 12.35 0.425334 12.35 0.95C12.35 1.47467 12.7753 1.9 13.3 1.9H14.25C14.7747 1.9 15.2 2.32533 15.2 2.85V11.5622C14.9028 11.4572 14.5831 11.4 14.25 11.4H2.85C2.51689 11.4 2.19714 11.4572 1.9 11.5622V2.85ZM1.9 14.25V16.15C1.9 16.6747 2.32533 17.1 2.85 17.1H14.25C14.7747 17.1 15.2 16.6747 15.2 16.15V14.25C15.2 13.7253 14.7747 13.3 14.25 13.3H2.85C2.32533 13.3 1.9 13.7253 1.9 14.25Z" fill="#0C5E9B" />
            <path d="M9.18118 0.239963C8.82123 -0.0799876 8.27878 -0.0799876 7.91882 0.239963L5.06887 2.7733C4.67672 3.12187 4.6414 3.72235 4.98997 4.11449C5.33855 4.50663 5.93901 4.54195 6.33116 4.19338L7.6 3.06551V8.55C7.6 9.07469 8.02532 9.5 8.55 9.5C9.07469 9.5 9.5 9.07469 9.5 8.55V3.0655L10.7688 4.19338C11.161 4.54194 11.7615 4.50662 12.11 4.11448C12.4586 3.72234 12.4233 3.12186 12.0312 2.7733L9.18118 0.239963Z" fill="#0C5E9B" />
        </svg>
    )
}
export default UninstallIcon
