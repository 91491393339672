import apiInterceptor from "./Interceptors/ApiInterceptor";

export const fetchPoliciesService = async () => {
  try {
    const response = await apiInterceptor.get(
      "/policies/get-policies"
    );
    return response?.data?.policies;
  } catch (error) {
    throw error;
  }
};

export const createPolicyService = async (payload) => {
  try {
    const response = await apiInterceptor.post(
      "/policies/create-policy",
      payload
    );
    return response?.data?.policy;
  } catch (error) {
    throw error;
  }
};

export const assignPolicyToGroupService = async (paylaod) => {
  try {
    const response = await apiInterceptor.post(
      "/groups/assign-policies",
      paylaod
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const updatePolicyService = async (payload) => {
  try {
    const response = await apiInterceptor.put(`/policies/update-policy/${payload.id}`, payload);
    return response?.data?.policy;
  } catch (error) {
    throw error;
  }
};

export const deletePolicyService = async (policyId) => {
  try {
    const response = await apiInterceptor.delete(`/policies/delete-policy/${policyId}`);
    return response?.data;
  } catch (error) {
    throw error;
  }
};
