// MapAccordion.js
import { Box } from '@mui/material';
import MapSummaryItem from './MapSummaryItem';

const MapAccordion = ({ handleInstallDevice, selectedRows, handleRowSelect, deviceNetworks, isSingle }) => {
  return (
    <Box >
      {deviceNetworks?.map((item, index) => (
        <MapSummaryItem
          key={`root-${index}`}
          item={item}
          handleInstallDevice={handleInstallDevice}
          selectedRows={selectedRows}
          handleRowSelect={handleRowSelect}
          isSingle={isSingle}
        />
      ))}
    </Box>
  );
};

export default MapAccordion;
