import React from 'react'
import shared from '../../shared/shared.module.scss';
import text from '../../shared/css/text.module.scss';
import styles from './singleDashboard.module.scss';
const DetailChartItem = ({ title, color, value }) => {
    return (
        <div className={styles.detailItem}>
            <div>
                <p className={text.normal1}>{title}</p>
            </div>
            <div>
                <p className={text.heading2} style={{ color: color }}>{value}</p>
                <div style={{ backgroundColor: color }} className={shared.statsBadge}></div>

            </div>
        </div>
    )
}

export default DetailChartItem
