import React from 'react'

const VisibleNoneIcon = () => {
    return (
        <svg width="24" height="30" viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1511_670)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.29289 1.29289C1.68342 0.90237 2.31658 0.90237 2.70711 1.29289L22.7071 21.2929C23.0976 21.6834 23.0976 22.3166 22.7071 22.7071C22.3166 23.0976 21.6834 23.0976 21.2929 22.7071L17.924 19.3383C16.2041 20.3919 14.1727 21 12 21C6.75861 21 2.29213 17.4607 1.08032 12.648C1.02676 12.4353 1 12.2176 1 12C0.999999 11.7824 1.02675 11.5647 1.08031 11.352C1.62339 9.19518 2.84148 7.30162 4.45325 5.86746L1.29289 2.70711C0.90237 2.31658 0.90237 1.68342 1.29289 1.29289ZM14.7573 16.1715L16.4604 17.8746C15.1403 18.5913 13.6204 19 12 19C7.66144 19 4.0045 16.0705 3.01978 12.1597C3.00661 12.1073 3 12.0537 3 12C3 11.9463 3.0066 11.8926 3.01977 11.8403C3.46721 10.0633 4.49184 8.48101 5.87033 7.28454L7.82847 9.24268C7.30525 10.0331 7 10.9816 7 11.9999C7 14.7614 9.23858 16.9999 12 16.9999C13.0183 16.9999 13.9669 16.6947 14.7573 16.1715ZM9 11.9999C9 11.5362 9.10473 11.0979 9.29216 10.7064L13.2936 14.7078C12.9021 14.8952 12.4637 14.9999 12 14.9999C10.3431 14.9999 9 13.6568 9 11.9999ZM10.1912 5.17356C10.7752 5.05982 11.38 5 12 5C16.3386 5 19.9955 7.92952 20.9802 11.8404C20.9934 11.8927 21 11.9463 21 12C21 12.0537 20.9934 12.1073 20.9802 12.1596C20.7781 12.9625 20.4188 13.7446 19.9778 14.4917C19.6971 14.9673 19.8551 15.5804 20.3307 15.8612C20.8063 16.1419 21.4194 15.9839 21.7002 15.5083C22.2056 14.652 22.6573 13.69 22.9197 12.6479C22.9733 12.4353 23 12.2176 23 12C23 11.7823 22.9732 11.5647 22.9197 11.352C21.7079 6.53927 17.2414 3 12 3C11.251 3 10.5183 3.07228 9.80885 3.21044C9.26675 3.31601 8.91287 3.84105 9.01845 4.38315C9.12402 4.92526 9.64906 5.27913 10.1912 5.17356Z" fill="#666666" />
            </g>
            <defs>
                <clipPath id="clip0_1511_670">
                    <rect width="24" height="30" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}
export default VisibleNoneIcon
