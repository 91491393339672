import {
    Box,
    Chip,
    Typography
} from '@mui/material';
import styles from "./Policies.module.scss";
import PolicyCheckerItem from './PolicyCheckerItem';
import { permissionGroups } from './helper';

const PolicyChecker = ({ selectedPermissions, handlePermissionSelection }) => {
    return (
        <Box>
            <div className={styles.permissionsBox}>
                {selectedPermissions.length ?
                    selectedPermissions.map((permission, index) => (
                        <Chip className={styles.permissionChip} key={index} label={permission} />
                    ))
                    :
                    <Typography className={styles.placeHolder}>Select Permissions</Typography>
                }
            </div>
            <div className={styles.permissionContainer}>
                {permissionGroups.map((group, index) => (
                    <PolicyCheckerItem
                        key={index}
                        groupName={group.name}
                        permissions={group.permissions}
                        selectedPermissions={selectedPermissions}
                        onSelectionChange={handlePermissionSelection}
                    />
                ))}
            </div>
        </Box>
    );
};

export default PolicyChecker;
