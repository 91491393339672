import React from 'react'

const TemporaryIcon = ({ fill = "#FFF", width = "22", height = "22" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M12.5307 11.1068L14.2057 12.0743C14.6151 12.3115 14.7543 12.8345 14.5171 13.2432C14.2807 13.652 13.757 13.7919 13.3483 13.5547L11.6747 12.5885C11.6179 12.598 11.5598 12.6027 11.5003 12.6027C10.8916 12.6027 10.3977 12.1088 10.3977 11.5C10.3977 11.2372 10.4896 10.9966 10.6429 10.8074V7.21419C10.6429 6.74054 11.0267 6.35675 11.5003 6.35675C11.974 6.35675 12.3577 6.74054 12.3577 7.21419V10.8074C12.43 10.8966 12.4888 10.998 12.5307 11.1068ZM11.5003 19.4797C7.09371 19.4797 3.52084 15.9068 3.52084 11.5C3.52084 7.09324 7.09371 3.52027 11.5003 3.52027C13.8442 3.52027 16.0603 4.55135 17.5772 6.33176L17.7542 5.66959C17.8765 5.21284 18.3461 4.94122 18.8028 5.06352C19.2596 5.18581 19.5312 5.65473 19.4089 6.11216L18.7386 8.61554C18.6163 9.0723 18.1468 9.34392 17.69 9.22162L15.1867 8.55067C14.73 8.42838 14.4584 7.95946 14.5807 7.50202C14.7023 7.04527 15.1719 6.77432 15.6286 6.89595L15.8016 6.94257C14.6347 5.83851 13.1104 5.23514 11.5003 5.23514C8.04031 5.23514 5.23566 8.03986 5.23566 11.5C5.23566 14.9601 8.04031 17.7642 11.5003 17.7642C14.9604 17.7642 17.7643 14.9601 17.7643 11.5C17.7643 11.0263 18.1488 10.6426 18.6217 10.6426C19.0954 10.6426 19.4798 11.0263 19.4798 11.5C19.4798 15.9068 15.907 19.4797 11.5003 19.4797ZM11.5003 0C5.14917 0 0 5.14865 0 11.5C0 17.8513 5.14917 23 11.5003 23C17.8515 23 23 17.8513 23 11.5C23 5.14865 17.8515 0 11.5003 0Z" fill={fill} />
        </svg>

    )
}

export default TemporaryIcon
