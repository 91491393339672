import { Box, Grid } from '@mui/material';
import MyCard from 'components/shared/Card/Card';
import CustomDialog from 'components/shared/Dialog/CustomDialog';
import { useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import GraphTitle from './GraphTitle';
import tableStyles from '../../shared/Tables/MyTable/myTable.module.scss';
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';


const SecurityAlerts = ({ alerts }) => {
    const options = useMemo(() => {
        if (alerts?.types?.length) {
            return {
                dataLabels: {
                    enabled: false,
                },
                chart: {
                    type: 'bar',
                    stacked: true,
                    toolbar: {
                        show: false
                    },
                },
                colors: ["#0C5E9B", "#62757f", "#ffa000"],
                plotOptions: {
                    bar: {
                        borderRadius: 5,
                        columnWidth: '40px', // Changed column width to 40px
                    }
                },
                grid: {
                    show: true,
                    borderColor: '#e0e0e0',  // Color for grid lines
                    strokeDashArray: 4,  // Dotted Y-axis lines
                    position: 'back',
                    xaxis: {
                        lines: {
                            show: false  // No vertical grid lines
                        }
                    },
                    yaxis: {
                        lines: {
                            show: true,  // Show horizontal grid lines
                        }
                    }
                },
                xaxis: {
                    categories: alerts?.types,  // Set X-axis categories
                    show: true,
                    axisBorder: {
                        show: false,
                        color: '#808080',
                        height: 1,
                        offsetX: 0,
                        offsetY: 1,
                    },
                    axisTicks: {
                        show: true,
                        borderType: 'solid',
                        color: '#808080',
                        width: 6
                    },
                    title: {
                        text: 'Categories',  // Added X-axis label
                        style: {
                            color: '#808080',  // Set label color to gray
                            fontSize: '14px',
                            fontFamily: 'Poppins',
                            fontWeight: 'bold',
                            padding: 10, // Some padding
                            backgroundColor: '#fff',  // Background color to make border stand out
                            border: '1px solid #000',  // Border around label
                            borderRadius: '4px',  // Optional: rounded corners for the label
                        }
                    }
                },
                yaxis: {
                    min: 0,
                    max: 100,  // Set Y-axis maximum to 100
                    tickAmount: 5,  // Set number of ticks to ensure whole numbers
                    labels: {
                        formatter: function (val) {
                            return Math.floor(val);  // Display whole numbers
                        }
                    },
                    axisBorder: {
                        show: true,
                        strokeDashArray: 4,  // Dotted Y-axis lines
                        color: '#808080',  // Set axis to gray
                        offsetX: -1
                    },
                    axisTicks: {
                        show: true,
                        borderType: 'solid',
                        color: '#808080',
                        width: 6
                    },
                    title: {
                        text: 'No of Alerts',
                        style: {
                            color: '#808080',
                            fontSize: '14px',
                            fontFamily: 'Poppins',
                            fontWeight: 'bold',
                            padding: 10,  // Some padding
                            backgroundColor: '#fff',  // Background color to make border stand out
                            border: '1px solid #000',  // Border around label
                            borderRadius: '4px',  // Optional: rounded corners for the label
                        }
                    },
                },
                fill: {
                    opacity: 1
                },
                legend: {
                    show: false
                },
            };
        }
        return {}; // Fallback options in case alerts are undefined
    }, [alerts])

    const [isFullScreen, setIsFullScreen] = useState(false);

    const handleToggleScreen = () => {
        setIsFullScreen((prevState) => {
            return !prevState
        })
    }

    // Safeguard against undefined alerts and series
    const chartData = alerts?.chartData?.length ? alerts.chartData : [];

    return (
        <>
            <MyCard height={'350px'}>
                <GraphTitle showFullScreenBtn={true} handleClickFullScreen={handleToggleScreen} title={'Security Alerts Overview'} />
                {/* Conditionally render Chart if data is available */}
                {chartData.length ? (
                    <Chart options={options} series={chartData} type="bar" height={280} />
                ) : (
                    <Box className={tableStyles.noData}><ContentPasteOffIcon /> No Data</Box>
                )}
            </MyCard>
            <CustomDialog fullScreen={true} title={'Security Alerts Overview'} isOpen={isFullScreen} handleToggle={handleToggleScreen} maxWidth={'xl'}>
                <Grid xs={12} height={'100%'}>
                    {chartData.length ? (
                        <Chart options={options} series={chartData} type="bar" height={"100%"} />
                    ) : (
                        <Box className={tableStyles.noData}><ContentPasteOffIcon /> No Data</Box>

                    )}
                </Grid>
            </CustomDialog>
        </>
    )
}

export default SecurityAlerts;
