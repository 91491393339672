import { Button, Card } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { object } from "yup";
import EmailIcon from "static/svgs/Email";
import LockIcon from "static/svgs/Lock";
import text from "components/shared/css/text.module.scss";
import CustomTextField from "components/shared/Forms/CustomTextField";
import { YupSignUpSchema, formSignUpSchema } from "./helper";
import styles from "./signup.module.scss";
import ProfileIcon from "static/svgs/Profile";
import { useLocation, useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import { useDispatch } from "react-redux";
import { SIGN_UP } from "../../../redux/types/auth.types";

const SignUpForm = () => {
  let signUpSchema = object(YupSignUpSchema);
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm(formSignUpSchema(signUpSchema));
  const location = useLocation();
  const [email, setEmail] = useState("");
  const EMAIL_SECRET = process.env.REACT_APP_EMAIL_SECRET;

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const encryptedEmail = params.get("email");

    if (encryptedEmail) {
      const decryptedBytes = CryptoJS.AES.decrypt(encryptedEmail, EMAIL_SECRET);
      const decryptedEmail = decryptedBytes.toString(CryptoJS.enc.Utf8);

      setEmail(decryptedEmail);
    }
  }, [location?.search]);

  const handleSignupSubmit = async (payload) => {
    dispatch({ type: SIGN_UP, payload: { ...payload, email } })
  };

  return (
    <Card className={styles.formContainer}>
      <div>
        <p className={text.heading1}>Sign Up</p>
        <p className={text.info1}>
          Enter your credentials to signup to the portal
        </p>
        <br />
      </div>
      <form
        onSubmit={handleSubmit(handleSignupSubmit)}
        className={styles.fieldsContainer}
      >
        <CustomTextField
          control={control}
          errors={errors}
          name={"email"}
          type="text"
          label="Email"
          icon={EmailIcon}
          placeHolder={"Enter your email"}
          email={email}
          disabled={true}
        />
        <CustomTextField
          control={control}
          errors={errors}
          name={"username"}
          type="text"
          label="Username"
          icon={ProfileIcon}
          required={true}
          placeHolder={"Enter your username"}
        />
        <CustomTextField
          control={control}
          errors={errors}
          name={"password"}
          type="password"
          label="Password"
          icon={LockIcon}
          required={true}
          placeHolder={"*******"}
        />
        <br />
        <div className={styles.btnContainer}>
          <Button
            disabled={!isValid}
            type="submit"
            variant="contained"
            className={styles.loginBtn}
          >
            Sign Up
          </Button>
        </div>
      </form>
    </Card>
  );
};

export default SignUpForm;
