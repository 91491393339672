import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import * as React from "react";
import { memo, useEffect, useState } from "react";
import TableSkeleton from "../../Skeletons/TableSkeleton";
import { customTableStyles, multiLineCellStyles } from "../helper";
import styles from './myTable.module.scss';
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';
const MyTable = ({
  isMultiLine = false,
  checkboxSelection,
  rows,
  columns,
  handleSelectedRows = () => { },
  id,
}) => {
  const [sortModel, setSortModel] = React.useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (rows.length === 0) {
      const timer = setTimeout(() => {
        setLoading(false);
      }, 5000); // 5 seconds

      return () => clearTimeout(timer); // Clean up the timer on unmount
    } else {
      setLoading(false);
    }
  }, [rows]);

  return (
    <Box id={id} sx={{ width: { xs: "600px", sm: "800px", md: "1100px", lg: "100%" } }}>
      {loading ? (
        <TableSkeleton rows={4} cols={columns?.length} />
      ) : (
        rows?.length ? (
          <DataGrid
            rows={rows}
            getRowId={id}
            columns={columns}
            sx={{
              ...customTableStyles,
              ...(isMultiLine ? multiLineCellStyles : {}),
              height: "450px",
            }}
            checkboxSelection={checkboxSelection}
            disableRowSelectionOnClick
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
            onRowSelectionModelChange={(ids) => {
              handleSelectedRows(ids);
            }}
          />
        ) : (
          <Box className={styles.noData}><ContentPasteOffIcon /> No Data</Box>
        )
      )}
    </Box>
  );
};

export default memo(MyTable);
