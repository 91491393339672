export const generateLabel = (device, parentLabel = "") => {
    const labelPrefix = parentLabel ? `${parentLabel}C` : "P";
    const index = device.index !== undefined ? device.index + 1 : 1;
    return `${labelPrefix}${index}`;
};

export const addLabels = (devices, parentLabel = "") => {
    return devices.map((device, index) => {
        const label = generateLabel({ ...device, index }, parentLabel);
        const updatedDevice = { ...device, label };
        if (device.childDevices) {
            updatedDevice.childDevices = addLabels(device.childDevices, label);
        }
        return updatedDevice;
    });
};

export const mapHeaderLabels = [
    { label: "Network ID", style: { textAlign: 'left' } },
    { label: "IP Address", style: {} },
    { label: "No of Devices", style: {} },
    { label: "Device ID", style: { textAlign: 'left' } },
    { label: "Status", style: { textAlign: 'left' } },
    { label: "Action", style: { textAlign: 'left' } },
];

export const networkCSVColums = [
    {
        field: 'deviceId',
        headerName: 'Device ID',
    },

    {
        field: 'networkId',
        headerName: 'Network ID',
    },
    {
        field: 'status',
        headerName: 'Status',
    },
];

