import React from 'react';
import Chart from 'react-apexcharts';
import { barChartStyles } from './helper';



const VerticalBarChart = () => {
    const options = {
        series: [{
            name: '40%',
            data: [44, 55, 4, 67, 22, 43]
        }, {
            name: '20%',
            data: [13, 23, 20, 8, 13, 27]
        }, {
            name: '90%',
            data: [11, 17, 15, 15, 21, 14]
        }],
        dataLabels: {
            enabled: false, // Don't show data labels on the bars
        },
        chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: {
                show: false
            },
        },
        colors: ["#0C5E9B", "#62757f", "#ffa000"],
        plotOptions: {
            bar: {
                borderRadius: 5,
                borderRadiusApplication: 'around',
                borderRadiusWhenStacked: 'all',
                columnWidth: '11.15px',
            }
        },
        grid: {
            show: false,
        },

        responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                    position: 'bottom',
                    offsetX: -10,
                    offsetY: 0
                }
            }
        }],
        xaxis: {
            categories: ['0', '1', '2', '3', '4', '5'],
            axisBorder: {
                show: true,
                color: '#000',
                height: 1,
                offsetX: 0,
                offsetY: 1,
            },
            axisTicks: {
                show: true,
                borderType: 'solid',
                color: '#000',
                width: 6
            }
        },
        yaxis: {
            axisBorder: {
                show: true,
                color: '#000',
                offsetX: -1
            },
            axisTicks: {
                show: true,
                borderType: 'solid',
                color: '#000',
                width: 6
            }
        },
        fill: {
            opacity: 1
        },
        legend: {
            position: 'right',
            offsetX: 0,
            offsetY: 100,
            inverseOrder: false,
            fontSize: '16px',
            fontFamily: 'Poppins',
            fontWeight: 500,
            horizontalAlign: 'center',
            markers: {
                width: 9,
                height: 9,
                strokeWidth: 0,
                radius: 9,
            },
            itemMargin: {
                horizontal: 5,
                vertical: 5
            },
            labels: {
                colors: ["#0C5E9B", "#62757f", "#ffa000"], // Use series colors for labels
            },
        },
    };

    return (
        <>
            <style>{barChartStyles}</style>
            <Chart options={options} series={options.series} type="bar" height={255} />
        </>
    );
}

export default VerticalBarChart;
