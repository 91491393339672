import React from 'react'

const LeftArrowIcon = ({ fill = "black", width = "14", height = "14" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.5 12.2138C5.5 12.4147 5.58581 12.6187 5.75321 12.7711C6.08824 13.0763 6.64225 13.0763 6.97729 12.7711L13.2468 7.06071C13.4086 6.91337 13.5 6.70538 13.5 6.50336C13.5 6.30327 13.4201 6.10382 13.2468 5.94601L6.98549 0.243491C6.65538 -0.0759736 6.08824 -0.0800305 5.75321 0.225126C5.41818 0.530283 5.41818 1.03489 5.75321 1.34005L5.75344 1.34026L11.422 6.49332L5.75321 11.6566C5.58558 11.8093 5.5 12.013 5.5 12.2138Z" fill={fill} />
            <path d="M-3.43668e-08 12.2138C-2.55832e-08 12.4147 0.0858097 12.6187 0.253209 12.7711C0.588242 13.0763 1.14225 13.0763 1.47729 12.7711L7.74679 7.06071C7.90856 6.91337 8 6.70538 8 6.50336C8 6.30327 7.92005 6.10382 7.74679 5.94601L1.48549 0.243491C1.15538 -0.0759736 0.588242 -0.0800305 0.253208 0.225126C-0.0818246 0.530283 -0.0818246 1.03489 0.253209 1.34005L0.253443 1.34026L5.92204 6.49332L0.253209 11.6566C0.0855752 11.8093 -4.31411e-08 12.013 -3.43668e-08 12.2138Z" fill={fill} />
        </svg>

    )
}
export default LeftArrowIcon
